import React, {useEffect, useState} from 'react';
import {DownOutlined, LockOutlined, UserOutlined, BankOutlined} from '@ant-design/icons';
import {Button, Checkbox, Form, Input, Card, Row, Col, SelectProps, MenuProps, Dropdown, Space, Divider} from 'antd';
import  * as AuthService from "../Services/auth-service";

import {useHistory} from "react-router-dom";
import i18n from "i18next";

import {useTranslation} from "react-i18next";
import axios from "axios/index";
import authHeader from "../Services/auth-header";
import {login} from "../Services/auth-service";


const RegisterPage = () => {


    
    const history= useHistory();

    const[needCheckInvite, setneedCheckInvite]=useState(false);




    const locationString = window.location;
    const[imgwidth, setimgwidth] = useState(100)
   // const[img, setImg] = useState("https://logiex.eu/wp-content/uploads/2024/01/logiexeu_transparent-1.png") ///img/logo6.png

    useEffect(()=>{
        if(locationString.toString().indexOf("localhost")>0){
            setimgwidth(295)
         //   setImg("/img/login_logo.png")
        }
        if(locationString.toString().indexOf("inbesi")>0){
            setimgwidth(295)
          //  setImg("/img/login_logo.png")
        }

    })


    //console.log("inside register")

    const {t} = useTranslation();
    const [userslang, setuserslang] = useState("English");
    const [langIcon, setlangIcon] = useState("en");

    const [blockedButton, setblockedButton] = useState(true);



    const [formReg] = Form.useForm();
    const[agree1, setAgree1] = useState<boolean>(false);
    const[agree2, setAgree2] = useState<boolean>(false);


    const checkAgreemnet=()=>{

        console.log(agree1, ":", agree2)
        if(agree1===true && agree2===true){
            setblockedButton(false)
            console.log(agree1, ":", agree2)
        }
        else{
            setblockedButton(true)
            
        }
    }
    
    
    useEffect(()=>{
        if(agree1 && agree2){
            setblockedButton(false)
            console.log(agree1, ":", agree2)
        }
        else{
            setblockedButton(true)

        }
    },[agree1, agree2])

    
    /*
    const options: SelectProps['options'] = [
        { value: 'en', emoji: '🇬🇧', label: 'English' },
        { value: 'si', emoji: '🇸🇮', label: 'Slovenščina' },
        { value: 'fr', emoji: '🇫🇷', label: 'Français' },
        { value: 'de', emoji: '🇩🇪', label: 'Deutsch' },
        { value: 'ru', emoji: '🇷🇺', label: 'Русский' },
        { value: 'it', emoji: '🇮🇹', label: 'Italiano' },
        { value: 'ukr', emoji: '🇺🇦', label: 'Українська' },
        ]
        
     */

    const items: MenuProps['items'] = [
        { key: 'en', icon: <img src="/img/lang/en.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; English</span> },
        { key: 'si', icon: <img src="/img/lang/si.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Slovenščina</span> },
        { key: 'fr', icon: <img src="/img/lang/fr.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Français</span> },
        { key: 'de', icon: <img src="/img/lang/de.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Deutsch</span>},
        { key: 'ru', icon: <img src="/img/lang/ru.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Русский</span> },
        { key: 'it', icon: <img src="/img/lang/it.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Italiano</span> },
        { key: 'uk', icon: <img src="/img/lang/uk.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Українська</span> },
    ]
    
    const handleLanguageClick: MenuProps['onClick'] = (e) => {
        i18n.changeLanguage(e.key);
        switch(e.key) {
            case "en": {
                setuserslang("English");
                setlangIcon("en")
                break;
            }
            case "si": {
                setuserslang("Slovenščina");
                setlangIcon("si")
                break;
            }
            case "fr": {
                setuserslang("Français");
                setlangIcon("fr")
                break;
            }
            case "de": {
                setuserslang("Deutsch");
                setlangIcon("de")
                break;
            }
            case "ru": {
                setuserslang("Русский");
                setlangIcon("ru")
                break;
            }
            case "it": {
                setuserslang("Italiano");
                setlangIcon("it")
                break;
            }
            case "uk": {
                setuserslang("Українська");
                setlangIcon("uk")
                break;
            }

            default: {
                setuserslang("English");
                setlangIcon("en")
                break;
            }
        }

    };

    const menuProps = {
        items,
        onClick: handleLanguageClick,
    };

    const[name, setname] = useState<string>("");
    const[surname, setsurname] = useState<string>("");


    const[username, setusername] = useState<string>("");
    const[password, setpassword] = useState<string>("");
    const[contractor, setcontractor] = useState<string>("");

    const[isTransporter, setisTransporter] = useState<boolean>(false);
    const[isOperator, setisOperator] = useState<boolean>(false);
    const[AcceptOpenOrders, setAcceptOpenOrders] = useState<boolean>(false);
    const[AcceptOpenReplies, setAcceptOpenReplies] = useState<boolean>(false);





    const onFinish=()=>{
        
        console.log("On finish login: ",  username , password)
        AuthService.register(username, username, password, contractor, false,true, false,false, name, surname).then(
            (rrr) => {
                console.log("rrr: ", rrr)
               
                login(username, password).then(
                    () => {
                        console.log("localStorage: ", localStorage);
                        history.push("/Dashboard");
                        window.location.reload();
                    })

            })
            .catch((error)=>{
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);

            })
            
            
                //history.push("/");
                //setLoading(false);
                //setMessage(resMessage);
    }
    
    
    
    const[theCode, settheCode]=useState("");
    const[letInvite, setLetInvite]=useState("none");
    


    
    
    const checkInvite=()=>{
        console.log(theCode)
        
        //axios.get()
        if(theCode!==""){

            axios.get(AuthService.DATA_URL+'Authenticate/check-invite?theCode='+theCode, 
            )
                .then((response)=>{

                    if(response.data==="Ok"){
                        setneedCheckInvite(!needCheckInvite); 
                    }
                    else{
                        setLetInvite("block");
                    }
                })



            
        }
        
        
    }
    return (


            
        <Row style={{height: "80vh", justifyContent: "center"}}>
            <Col style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                width: "500px"

            }}>
                <div>

                    <img style={{display: "block", width: "200px", margin: "20px auto"}}
                         alt="example"
                         src="/img/logo_min.png"
                        //{img}
                    />

                    <Card style={{width: "800px", display: "block"}}
                          title={t("Register")}

                          extra={<Dropdown menu={menuProps}>
                              <Button>
                                  <Space>
                                      <img src={"/img/lang/"+langIcon+".svg"}  style={{width:"12px", height: "12px"}}/>
                                      {userslang}
                                      <DownOutlined />
                                  </Space>
                              </Button>
                          </Dropdown>
                          }
                    >



                       
                        <Form
                            form={formReg}
                            name="normal_register"
                            className="login-form"
                            autoComplete="off"


                            initialValues={{ remember: false,
                                reg_username: "",
                                password : ""
                             }}
                            onFinish={onFinish}
                        > 
                            <Row>
                                <Col span={9}>
                                    <Form.Item
                                        name="reg_name"
                                        rules={[{ required: true,   message: t('Please_input_your_Email') }]}>
                                        <Input
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder={t("Ime")}
                                            onChange={(e)=>{setname(e.target.value)}}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="reg_surname"
                                        rules={[{ required: true,  message: t('Please_input_your_Email') }]}>
                                        <Input
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder={t("Priimek")}
                                            onChange={(e)=>{setsurname(e.target.value)}}
                                        />
                                    </Form.Item>                                   
                                    
                                    
                                    
                                    <Form.Item
                                        name="reg_username"
                                        rules={[{ required: true, type:"email",  message: t('Please_input_your_Email') }]}>
                                        <Input
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder={t("E_pošta")}
                                            onChange={(e)=>{setusername(e.target.value)}}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="reg_password"
                                        rules={[{ required: true, message: t('Please_input_your_Password') }]}
                                    >
                                        <Input
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                            type="password"
                                            placeholder={t("Password")}
                                            onChange={(e)=>{setpassword(e.target.value)}}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="reg_password_conf"
                                        rules={[{ required: true, message: t('Please_input_your_Password') }]}
                                    >
                                        <Input
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                            type="password"
                                            placeholder={t("repeat_Password")}
                                            onChange={(e)=>{setpassword(e.target.value)}}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="contractor"
                                        rules={[{ required: true, message: t('Please_input_your_Companyname') }]}
                                    >
                                        <Input prefix={<BankOutlined className="site-form-item-icon" />} placeholder={t("Company_name")}
                                               onChange={(e)=>{setcontractor(e.target.value)}}
                                        />
                                    </Form.Item>

                                </Col>
                                <Col span={14} offset={1}>

                                    <Form.Item style={{marginBottom:"20px"}} name={"agree1"}>
                                        <Checkbox
                                            onChange={(e)=>
                                            {
                                                setAgree1(e.target.checked);
                                                //  checkAgreemnet();
                                            }
                                            }
                                            // onClick={()=> checkAgreemnet()}
                                        >{t('agree_to_treat1')}</Checkbox>
                                    </Form.Item>
                                    <Form.Item style={{marginBottom:"0px"}} name={"agree2"}>
                                        <Checkbox
                                            onChange={(e)=>
                                            {
                                                setAgree2(e.target.checked);
                                                //   checkAgreemnet();
                                            }
                                            }
                                            //onClick={()=> checkAgreemnet()}
                                        >{t('agree_to_treat2')} <a href="/datenschutz.pdf" target="_blank">{t('here')}</a></Checkbox>
                                    </Form.Item>
                                    
                                    
                                    {/* 
                                    <Form.Item style={{marginBottom:"0px"}}>
                                        <Checkbox
                                            onChange={(e)=>setisTransporter(e.target.checked)}
                                        >{t("Predstavljam_prevozno_prevozniško_podjetje")}</Checkbox>
                                    </Form.Item>
                                    <Form.Item style={{marginBottom:"0px"}}>
                                        <Checkbox
                                            onChange={(e)=>setAcceptOpenOrders(e.target.checked)}
                                        >{t("Želim_prejemati_javne_ponudbe_za_prevoz")}</Checkbox>
                                    </Form.Item>
                                    <Form.Item style={{marginBottom:"0px"}}>
                                        <Checkbox
                                            onChange={(e)=>setisOperator(e.target.checked)}
                                        >{t("Predstavljam_pošiljatelja_špediterja")}</Checkbox>
                                    </Form.Item>
                                    <Form.Item style={{marginBottom:"0px"}}>
                                        <Checkbox
                                            onChange={(e)=>setAcceptOpenReplies(e.target.checked)}
                                        >{t("Želim_imeti_dostop_do_razpoložljivih_prevoznikov")}</Checkbox>
                                    </Form.Item>
*/}
                                </Col>
                            </Row>
                            
                            
                            
                            


                            

                            <Divider  style={{margin: "5px 0"}}/>
                            <Form.Item style={{textAlign: "center", marginTop: "20px"}}>
                                <Button type="primary" 
                                        htmlType="submit" 
                                        className="login-form-button" 
                                        disabled={blockedButton ?  true : false}
                                        style={{width: "250px"}}>
                                    {t("Register")}
                                </Button>

                            </Form.Item>
                            <Divider style={{margin: "5px 0"}}/>

                            <Form.Item>
                                {t("Or")} <a href="/login">{t("Log_in")}</a>
                            </Form.Item>
                        </Form>

                        
                        <Divider/>
              <Row>
                  <Col span={5} style={{textAlign: "center"}}>
                      <a href="tel:+38651880413"  style={{color: "#999", fontSize: "10px"}}>+38651880413</a>
                  </Col>
                  <Col span={4} style={{textAlign: "center"}}>
                      <a href="/impressum.pdf" target="_blank"
                      style={{color: "#999", fontSize: "10px"}}
                      >Impressum</a>
                  </Col>
                  <Col span={5} style={{textAlign: "center"}}>
                      <a href="/privacy_policy.pdf" target="_blank"
                         style={{color: "#999", fontSize: "10px"}}
                      >Privacy Policy</a>
                  </Col>
                  <Col span={5} style={{textAlign: "center"}}>
                      <a href="/datenschutz.pdf" target="_blank"
                         style={{color: "#999", fontSize: "10px"}}
                      >Datenschutz</a>
                  </Col>
                  <Col span={5}  style={{textAlign: "center"}} >
                      <a href="mailto:ismagilov.t@gmail.com"  style={{color: "#999", fontSize: "10px"}}>ismagilov.t@gmail.com</a>
                  </Col>

              </Row>
          
                    </Card>
                </div>

            </Col>

        </Row>

    


    )
}

export default RegisterPage;