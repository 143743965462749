import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import {Spin, Tabs, TabsProps, List, Skeleton} from "antd";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {DATA_URL} from "../../Services/auth-service";
import authHeader from "../../Services/auth-header";
import PaymentStory from "../../types/PaymentHistory";
import {IntToDate, ServerdateToPoints, ToMoney} from "../../utils/helpers";
import {ExportOutlined} from "@ant-design/icons";
import InvoiceLine from "../../types/InvoiceLine";
import PaymentLine from "../../types/PaymentLine";



const PaymentHistory = () => {

    const {t} = useTranslation();

    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }
    
    
    const [phistory, setphistory]=useState<PaymentStory[]|null>(null)
    const[paymentStory, setPaymentStory] = useState<PaymentLine[]|null>(null)
    
    const[isPageLoading, setIsPageLoading] = useState(false);
    
    useEffect(()=>{
        if(phistory===null){
            setIsPageLoading(true)
            axios.get(DATA_URL+"settings/get-payment-history", {headers:authHeader()})
                .then((response=>{
                   
                    if(response.data.length!=0){
                        setphistory(response.data.data);
                       console.log("invoices: ", response.data.data)
                        setIsPageLoading(false)
                    }


                }))
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
            axios.get(DATA_URL+"settings/get-payment-history2", {headers:authHeader()})
                .then((response=>{

                    if(response.data.length!=0){
                        setPaymentStory(response.data)
                        console.log("history2: ",response.data)
                       
                    }


                }))
        }
        
    })
    
    return<>
        <Spin spinning={isPageLoading}>
           
            <div style={{maxHeight: "70vh", overflowY: "scroll"}}>
                {paymentStory!==null?
                    <List
                        className="demo-loadmore-list"
                        //loading={isPageLoading}
                        itemLayout="horizontal"

                        dataSource={paymentStory}
                        renderItem={(item) => {
                            return( <List.Item
                                //actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                            >
                                <div>
                                    <div style={{display: "inline-block", verticalAlign: "top"}}>
                                        {ServerdateToPoints(item.paymentDate)}    
                                        
                                    
                                    </div>
                                    <div style={{display: "inline-block", marginLeft: "40px", width: "500px" }}>

                                        {
                                            item.isSubscriptionLine?
                                                <>
                                                    {t("Subscription_fee")}
                                                    <List
                                                        dataSource={item.invoiceLines}
                                                        renderItem={(item) =>
                                                        {
                                                            return(<List.Item style={{padding: "5px 20px"}}>
                                                                <span style={{color: "#777"}}> 
                                                                    <div style={{width: "300px", display: "inline-block"}}>
                                                                        {t(item.productTitle)}

                                                                        {/*item.price.unit_amount ===2000 ? t("OnMyDisk_Subscription") :t("Additional_group_member")*/}
                                                                    </div>
                                                                    {item.quantity} х {ToMoney(item.amount/item.quantity/100)} = {ToMoney(item.amount/100)}
                                                                </span>
                                                            </List.Item>)}
                                                        }
                                                    />



                                                </>
                                                :
                                                <>
                                                    {t(item.productTitle)}: {item.totalQuantity} x {ToMoney(item.totalAmount/item.totalQuantity/100)} = {ToMoney(item.totalAmount/100)} <br/>

                                                </>
                                        }

                                    </div>
                                    <div style={{verticalAlign: "top", marginLeft: "40px", width: "80px", textAlign: "right", display: "inline-block"}}>{ToMoney(item.totalAmount/100)}</div>
                                    <div style={{verticalAlign: "top", marginLeft: "40px", display: "inline-block"}}>
                                        <a href={item.hostedInvoiceUrl} target={"_blank"} style={{color: "#0c5e9b", borderBottom: "1px solid #0c5e9b", paddingBottom: "3px"}}>
                                            {t("View_the_invoice")} <ExportOutlined/> </a></div>

                                </div>

                            </List.Item>)


                        }}
                    />


                    : <></>
                }
            </div>





            {/* 
            <div style={{maxHeight: "70vh", overflowY: "scroll"}}>
                

            {phistory!==null ? 
            <List
                className="demo-loadmore-list"
                //loading={isPageLoading}
                itemLayout="horizontal"
               
                dataSource={phistory}
                renderItem={(item) => {
                    var lqty=0;
                    var lprc=0;
                    var isSubscriptionPurchase=0
                    item.lines.data.forEach((itm:InvoiceLine)=>{
                        
                        if(itm.amount===2000 || itm.amount===6000){
                            isSubscriptionPurchase=1
                        }
                        else{
                            if(itm.amount>0){
                                lqty=lqty+itm.quantity;
                            }
                            else{
                                lqty=lqty-itm.quantity;
                            }
                            lprc=lprc+itm.amount;
                        }
                    })
                   
                   return( <List.Item
                        //actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                    >
                            <div>
                                <div style={{display: "inline-block", verticalAlign: "top"}}>{IntToDate(parseInt(item.created))}</div>
                                <div style={{display: "inline-block", marginLeft: "40px", width: "500px" }}>

                                    {
                                        isSubscriptionPurchase===0? 
                                            <>
                                                {t("Additional_group_member")}: {lqty} x {ToMoney(lprc/lqty/100)} = {ToMoney(lprc/100)} <br/>
                                            </> :
                                            
                                            <>
                                                {t("Subscription_fee")}
                                                <List
                                                    dataSource={item.lines.data}
                                                    renderItem={(item) =>
                                                    {
                                                        console.log("item: ", item)


                                                        return(<List.Item style={{padding: "5px 20px"}}>
                                                <span style={{color: "#777"}}> 
                                                    <div style={{width: "300px", display: "inline-block"}}>
                                                        {item.price.unit_amount ===2000 ? t("OnMyDisk_Subscription") :t("Additional_group_member")}
                                                    </div>
                                                    {item.quantity} х {ToMoney(item.amount/item.quantity/100)} = {ToMoney(item.amount/100)}
                                                </span>
                                                        </List.Item>)}
                                                    }
                                                />
                                            </>
                                    }
                                   
                                   
                                   


                                
                                </div>
                                <div style={{verticalAlign: "top", marginLeft: "40px", width: "80px", textAlign: "right", display: "inline-block"}}>{ToMoney(item.amount_paid/100)}</div>
                                <div style={{verticalAlign: "top", marginLeft: "40px", display: "inline-block"}}>
                                    <a href={item.hosted_invoice_url} target={"_blank"} style={{color: "#0c5e9b", borderBottom: "1px solid #0c5e9b", paddingBottom: "3px"}}>
                                        {t("View_the_invoice")} <ExportOutlined/> </a></div>
                                
                            </div>
                      
                    </List.Item>)
                }  } />
                : <></> }

            </div>
            */}
        </Spin>
    
    </>
    
    
}
export default PaymentHistory;