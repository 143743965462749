import i18n from "i18next";
import {initReactI18next} from "react-i18next";


i18n.use(initReactI18next).init({
    
    resources: {
        en:{
            translation: {
                Table_of_Contents: "Table of Contents",
                Group_management: "Group management",
                Group_creation: "Group creation",
                Inviting_participants: "Inviting participants",
                Member_statuses: "Member statuses",
                Change_log: "Change log",
                Participants2: "Participants",
                Search_function: "Search function",
                Activity_History: "Activity History",
                Usage_and_payments: "Usage and payments",
                Customising_usage: "Customising usage",
                Cost_calculation: "Cost calculation",
                Payment_history2: "Payment history",
                Settings: "Settings",
                User_settings: "User settings",
                Subscription_management: "Subscription management",
                text49: "In the ‘Settings’ section you can specify the details of your legal entity (if you want to use the service as a legal entity), as well as change the subscription parameters, configure the payment method or cancel the subscription.",
                text50: "The management console is an addition to the OnMyDisk application. As an OnMyDisk user, you automatically get access to the management console.",
                text51: "Therefore, all user settings must be changed from within the OnMyDisk application. To change your password or display username, you must go to the OnMyDisk application via the link.",
                text52: "If you want the invoices for using the service to be issued to a legal entity, specify the details of your company in the settings.",
                text53: "It is necessary to specify the company name, tax number, address and phone number.",
                text54: "After saving this information, all invoices will be issued in the name of the legal entity.",
                text55: "For reliability and security of the payment process we use Stripe platform.",
                text56: "To change the parameters of the subscription you can go to a special Stripe portal, where you can change the payment method and other parameters of the subscription.",
                text43: "We use proportional payment for our service. As OnMyDisc service works on the principle of monthly subscription, the payment for the use of the service is made on a monthly basis.",
                text44: "If you need to increase the limit in the middle of the period, the first payment will be calculated based on the number of remaining days until the end of your subscription period.",
                text45: "And from the next period onwards, the increased limit will be charged in full.",
                text46: "On the ‘Payment History’ tab you can find all the payments you have made.",
                text47: "We use the Stripe platform for a safe and secure payment process.",
                text48: "By clicking on the ‘View Invoice’ link (opens in a new window) you can see detailed information on each payment, as well as download the invoice and payment receipt.",
                text31: "By going to the detail screen, you can see the entire history of activity with the member.",
                text32: "In the Usage section, you can set limits on how much you can use the system. By default, you can create a group of up to 5 members within a subscription.",
                text33: "Also in the subscription frames you have the possibility to use the electronic signature mechanism 20 times for the documents you store in OnMyDisk application.",
                text34: "If necessary, you can change these parameters in this section",
                text35: "In order to increase the number of participants in the group, it is enough to specify how many additional participants you need in the section with the description of the current group limits.",
                text36: "The system will calculate the amount you need to pay to increase the limit.",
                text37: "To increase the limit, the system will automatically debit the necessary amount using your payment method that you specified when creating the group.",
                text38: "After successful payment the system will show a message and the group limit will be increased.",
                text39: "In a similar way you can increase the limit of signing documents.",
                text40: "If necessary, you can increase the limit in packages of 5 documents. To increase the limit, specify the number of packages and the system will calculate the necessary amount to be paid.",
                text41: "To increase the limit, the system will automatically debit the necessary amount using your payment method, which you specified when creating the group.",
                text42: "After successful payment the system will show a message and the limit on documents will be increased.",
                text26: "In the “Members” section, you can view a list of all the members you have ever invited to the group.",
                text27: "Even members who have been removed from the group are shown in the list.",
                text28: "Just like in the “My group” section, you can see each member's name, e-mail address and status in the group.",
                text29: "Detailed information is available for each member - click on the line with the member's description and the system will redirect you to the detail screen",
                text30: "If there are many participants, you can use the search function. To do this, start typing text in the search bar above the table. The system will search the text you entered for all information in the list (name, e-mail address).",
                text18: "The Change Log tab displays all actions and events that occurred with the group.",
                text19: "The following events are recorded in the log:",
                text20: "Creating a group",
                text21: "Changing the group name",
                text22: "Inviting a member",
                text23: "Joining a member to the group",
                text24: "Suspending a member",
                text25: "Removing a member from a group",
                text12: "Each member in a group can be in one of three statuses:",
                text13: "‘Waiting for invitation confirmation’",
                text14: "‘Joined the group’",
                text15: "‘Participation in the group is suspended’.",
                text16: "At any moment you can suspend participation in the group of any of the invitees. And also at any moment you can include any of the participants in the group again",
                text17: "You can remove a member from the group. In this case the information about the member will no longer be displayed in the list on the group screen. But you will be able to find him/her in the list of all users in the ‘Users’ section",
                text11: "If the user is not already in the OnMyDisk system, we will send them an email inviting them to start using OnMyDisk and join your group",
                text10: "If such a user exists, he/she will be added to your group immediately.",
                text9: "When the information is saved, we will check if there is a user with the specified email address in the OnMyDisk system.\n",
                text8: "You can also specify the name of the member, but it is not necessary.",
                text7: "After clicking on this button, a dialogue box will appear where you should specify the e-mail address of the member you want to invite.",
                text6: "To add a new member to the group, click ‘Invite member’",
                text5: "You can invite as many members to a group as your group limit allows. The default group limit is 5 members. You can increase this number either immediately when creating a group or in the ‘Usage’ section",
                text1: "Using the Management Console starts with creating a group. Each user can create only one group and invite members to the group.",
                text2: "When you log in for the first time, we will prompt you to create a group. To create a group, you will need to think of a name for the group and decide how many members it will have.",
                text3: "You can choose a tariff (with monthly or annual payment) and the system will calculate the necessary amount to be paid.",
                text4: "After clicking on the ‘Create Group’ button you will be redirected to the payment page. When the payment is received, we will create a group for you and redirect you to the group settings page where you can invite participants",
                Payment_and_subscription_settings:"Payment and subscription settings",
                We_use_the_tried: "We use the tried and trusted Stripe platform to process payments",
                If_you_would_like_to_change:"If you would like to change your payment settings or cancel your subscription, please follow the link to the settings page.",
                Go_to_Payment_Settings: "Go to Payment and Subscription Settings",
                ofdocuments: "documents",
                Current_document_limit: "Current document limit (per month)",
                Used_as_part_of_subscription: "Used as part of subscription",
                Available_until_the_end_of_the_period: "Available until the end of the period",
                Additionally_used: "Additionally used",
                Charge_for_additional_use: "Charge for additional use in the amount of",
                will_be_charged:"will be charged ",
                To_save_money: "To save money, subscribe to additional document packages. Each package contains an additional 10 documents per month",
                Postal_code: "Postal code",
                Telephone: "Telephone",
                Additional_document_package:"Additional document package",
                Checking_the_payment:"Checking the payment..",
                This_could_take_a_while: "This could take a while.",
                View_the_invoice: "View the invoice",
                Additional_group_member:"Additional group member",
                OnMyDisk_Subscription: "OnMyDisk Subscription",
                Subscription_fee: "Subscription fee",
                Current_group_limit: "Current group limit",
                Set_limit: "Upgrade package",
                No_change: "No change",
                No_need_to_pay_anything: "No additional payments",
                Additional_more: "Additional more",
                Additional_slots: "Additional slots", 
                Thank_you_for_your_purchase: "Thank you for your purchase!",
                The_purchase_is_successful: "The purchase is successful",
                A_confirmation_email_will_be_sent_to: "A confirmation email will be sent to ",
                We_will_redirect_you_to_your: "We will redirect you to your",
                group_page: "group page",
                If_you_have_any_questions_please_contact_our_Support_Team: "If you have any questions, please contact our Support Team",
                Quota_setting:"Usage settings",
                Payment_history: "Payment history",
                You_havent:"You haven\'t created a group yet.",
                Lets_create:"Let\'s create one right now!",
                Come_up:"Come up with a beautiful name for your group",
                beautiful_name:"A beautiful name for your group",
                How_many_members: "How many members will there be?",
                Group_size_up: "Group size: up to 5 participants",
                Signature_capacity_up: "Signature capacity: up to 20 documents",
                Additional_participants: "Additional participants:",
                Included_in_the_price: "Included in the price",
                Per_month: "Per month",
                Per_year: "Per year",
                Total_to_pay: "Total to pay",
                Specify_a_group_name: "Specify a group name",
                Create_a_group: "Create a group",
                Cancel: "Cancel",
                Participant_info: "Participant info",
                Invitation_sent: "Invitation is sent",
                In_the_group_since: "In the group since",
                Joined_the_group: "Joined the group",
                Waiting_for_participant_to_join: "Waiting for participant to join",
                Increase_the_quota: "Increase the quota",
                Invite_a_participant: "Invite a participant", 
                Event_log: "Event log",
                Participants: "Participants",
                Userset: "You can change the user settings in the app",
                Whatisname: "What is his name?",
                Created: "The group was created",
                UserInvited: "invited to the group",
                UserJoined: "joined the group",
                UserRejected: "declined invitation",
                UserSuspended1: "The membership of the user",
                UserSuspended2: "has been suspended ",
                UserDeleted: "removed from the group",
                GroupRenamed: "The group has been renamed",
                NewGroup : "New group", 
                Odjava: "Logout",
                Groups: "My group",
                Quotas: "Usage",
                Users: "Users",
                About: "About",
                StatusModified:"Status changed",
                Taxes: "Taxes",
                Narocila: "Orders",
                Status: "Status",
                Employee: "Employee",
                Employees: "Employees",
                Accept: "Accept",
                Date: "Date",
                Amount: "Amount",
                Account: "Account",
                Balance: "Balance",
                Show_charts: "Show charts",
                Reports: "Reports",
                January: "January",
                February: "February",
                March: "March",
                April: "April",
                May: "May",
                June: "June",
                July: "July",
                August: "August",
                September: "September",
                October: "October",
                November: "November",
                December: "December",
                from: "from",
                Obvezno_polje: "Required field",
                Delete: "Delete",
                Delete_warning: "Are you sure delete this partner?",
                Delete_text :"Once deleted, you will no longer be able to select this partner as a customer or performer for orders",
                Yes: "Yes",
                No: "No",
                Delete_error_title: "Can\'t delete",
                Done: "Done!",
                Kontaktna_oseba:"Contact Person",
                Telefon:"Phone",
                Faks:"Fax",
                BackToList : "Back to list",
                Predogled: "Preview",
                Preneseno: "Uoloaded",
                invite_message: "To register in the application, please enter the invitation code:",
                invite_mailto: "To receive an invitation code, please send a request to ",
                invite_error: "Alas, the code doesn't work",
                Davek_za_DDV: "VAT Amount",
                ID_za_DDV: "VAT ID",
                Hvala_za_zaupanje: "Thank you for your trust",
                Znesek_prosimo_plačajte_na: "Please pay the amount to",
                Ob_plačilu_navedite_referenco: "Please provide a reference when making payment",
                Skupaj : "Total",
                Dokument : 'Document',
                save_and_stay: "Save and continue editing",
                save_and_quit: "Save and go to list",
                agree_to_treat1 : "I agree to FinTim communicating its products and offers to me by email. I can withdraw my consent at any time.",
                agree_to_treat2 : "I agree that FinTim collects, stores and uses my personal data (for the full use of the various services provided by the site). I can withdraw my consent at any time. The privacy policy is available ",
                Close_this_info:"Close this info",
                Obračunavanje: "Billing",
                Seznam_narocil: "My orders",
                Ne_morem_shraniti: "Can't save",
                Status_spremembe: "Change status",
                Nazaj_na_seznam : "Back to list",
                Namigi : "Hints",
                Ni_voznikov: "No drivers",
                Register: "Register",
                Log_in : "Login",
                Password : "Password",
                repeat_Password : "Repeat password",
                Company_name: "Company name",
                Please_input_your_Username : "Please input your Username!",
                Please_input_your_Email: "Please input a valid email",
                Please_input_your_Password : "Please input your Password!",
                Please_input_your_Companyname : "Please input your company name!",
                Remember_me : "Remember me",
                Forgot_password : "Forgot password",
                Or: "Or ",
                register_now: "register now!",
                welcome : 'Welcome',
                Številka :'Number',
                Cena : 'Price',
                Plačila : 'Payements',
                Poglejte_vse : 'See all',
                Več : 'More',
                Izberite : 'Choose',
                Domov : 'Home',
                Vsa : 'All',
                Aktivno: "Active",
                Ustavljeno: "Stopped",
                Arhivirano: "Archived",
                Prenesite_v_Excel :'Download Excel',
                Kliknite_za_razvrščanje_padajoče : 'Click to sort ascending',
                Kliknite_za_razvrščanje_naraščajoče : 'Click to sort descending',
                Prekliči_razvrščanje : 'Cancel sorting',
                Od_datum :'From date',
                Do_datum: 'To date',
                Išči: 'Search..',
                Osnovni_podatki:'General info',
                Shraniti :'Save',
                Skrijte : 'Hide',
                Prenesite_dokument: 'Upload document',
                Naročilo :'Order',
                Nadaljevanje : 'Continue',
                Nazaj : 'Back',
                Koledar : 'Calendar',
                Po : 'Mon',
                To : 'Tue',
                Sr : 'Wed',
                Če : 'Thu',
                Pe : 'Fri',
                So : 'Sat',
                Ne : 'Sun',
                Ne_shranite_sprememb : 'Don\'t save',
                Ključni_kazalniki : 'Key indicators',
                Ime : 'Name',
                Priimek : 'Surname',
                Ime_datoteke : 'Filename',
                Vrsta : 'Type',
                Račun : 'Invoice',
                Predračun : 'Estimate',
                Nasprotna_stranka : 'Counterpart',
                Datum_dokumenta: 'Documents\'s date',
                Datum_plačila : 'Paymenet\'s date',
                Tiskanje : 'Print',
                Naziv_storitva_blaga : 'Product or service',
                Količina : 'Quantity',
                Davek : 'Taxe',
                Naziv : 'Title',
                Davčna_Številka : 'Tax Id',
                Matična_Številka : 'Company Id',
                Naslov : 'Address',
                Nastavitve: "Settings",
                Nastavitve_uporabnika : 'User\'s settings',
                Nastavitve_podjetja : 'Company settings',
                Prenesite_sliko : 'Upload image',
                Prenesete_lahko_slike_velikosti_do : 'Please upload image not greater than',
                E_pošta : 'Email',
                Login : 'Login',
                Staro_geslo : 'Old password',
                Novo_geslo: 'New password',
                Prenesite_datoteko_ali_povlecite : 'Upload file or drag',
                in_spusti_na_označeno_območje : 'and drop it in this area',
                ne_več_kot : 'not greater than ',
                Pogodba : 'Agreemenent'

            }
        },
        si:
            {
                translation: {
                    Table_of_Contents: "Kazalo vsebine",
                    Group_management: "Upravljanje skupine",
                    Group_creation: "Ustvarjanje skupin",
                    Inviting_participants: "Vabljenje udeležencev",
                    Member_statuses: "Stanje članov",
                    Change_log: "Dnevnik sprememb",
                    Participants2: "Udeleženci",
                    Search_function: "Funkcija iskanja",
                    Activity_History: "Zgodovina dejavnosti",
                    Usage_and_payments: "Uporaba in plačila",
                    Customising_usage: "Prilagajanje uporabe",
                    Cost_calculation: "Izračun stroškov",
                    Payment_history2: "Zgodovina plačil",
                    Settings: "Nastavitve",
                    User_settings: "Nastavitve uporabnika",
                    Subscription_management: "Upravljanje naročnine",
                    text49: "V razdelku „Nastavitve“ lahko določite podatke o pravni osebi (če želite storitev uporabljati kot pravna oseba), spremenite parametre naročnine, nastavite način plačila ali prekličete naročnino.",
                    text50: "Konzola za upravljanje je dodatek k aplikaciji OnMyDisk. Kot uporabnik aplikacije OnMyDisk samodejno pridobite dostop do konzole za upravljanje.",
                    text51: "Zato je treba vse uporabniške nastavitve spremeniti v aplikaciji OnMyDisk. Če želite spremeniti geslo ali prikazno uporabniško ime, morate v aplikacijo OnMyDisk oditi prek povezave.",
                    text52: "Če želite, da se računi za uporabo storitve izdajajo pravni osebi, v nastavitvah navedite podatke o svojem podjetju.",
                    text53: "Navesti je treba ime podjetja, davčno številko, naslov in telefonsko številko.",
                    text54: "Po shranjevanju teh podatkov bodo vsi računi izdani v imenu pravne osebe.",
                    text55: "Za zanesljivost in varnost plačilnega postopka uporabljamo platformo Stripe.",
                    text56: "Če želite spremeniti parametre naročnine, lahko obiščete poseben portal Stripe, kjer lahko spremenite način plačila in druge parametre naročnine.",
                    text43: "Za naše storitve uporabljamo sorazmerno plačilo. Ker storitev OnMyDisc deluje po načelu mesečne naročnine, se plačilo za uporabo storitve izvede mesečno.",
                    text44: "Če želite sredi obdobja povečati omejitev, se prvo plačilo izračuna na podlagi števila preostalih dni do konca naročniškega obdobja.",
                    text45: "Z naslednjim obdobjem pa se vam bo povečani limit zaračunal v celoti.",
                    text46: "V zavihku „Zgodovina plačil“ lahko najdete vsa plačila, ki ste jih opravili.",
                    text47: "Uporabljamo platformo Stripe, da je postopek plačevanja varen in zanesljiv.",
                    text48: "S klikom na povezavo „Ogled računa“ (odpre se v novem oknu) si lahko ogledate podrobne informacije o vsakem plačilu ter prenesete račun in potrdilo o plačilu.",
                    text31: "Na zaslonu s podrobnostmi si lahko ogledate celotno zgodovino dejavnosti člana.",
                    text32: "V razdelku Uporaba lahko nastavite omejitve glede količine uporabe sistema. Privzeto lahko v okviru naročnine ustvarite skupino z največ 5 člani.",
                    text33: "Prav tako imate v okvirih naročnine možnost 20-krat uporabiti mehanizem elektronskega podpisa za dokumente, ki jih hranite v aplikaciji OnMyDisk.",
                    text34: "Po potrebi lahko te parametre spremenite v tem razdelku.",
                    text35: "Če želite povečati število udeležencev v skupini, je dovolj, da v razdelku z opisom trenutnih omejitev skupine navedete, koliko dodatnih udeležencev potrebujete",
                    text36: "Sistem bo izračunal znesek, ki bo potreben za povečanje omejitve.",
                    text37: "Za povečanje limita bo sistem samodejno bremenil potreben znesek z vašim načinom plačila, ki ste ga določili ob ustvarjanju skupine.",
                    text38: "Po uspešnem vplačilu bo sistem prikazal sporočilo in limit skupine se bo povečal.",
                    text39: "Na podoben način lahko povečate limit za podpisovanje dokumentov.",
                    text40: "Po potrebi lahko omejitev povečate v paketih po 5 dokumentov. Če želite povečati omejitev, določite število paketov in sistem bo izračunal potreben znesek, ki ga je treba plačati.",
                    text41: "Če želite povečati limit, bo sistem samodejno bremenil potrebni znesek z vašim načinom plačila, ki ste ga določili ob ustvarjanju skupine.",
                    text42: "Po uspešnem plačilu bo sistem prikazal sporočilo in omejitev za dokumente se bo povečala.",
                    text26: "V razdelku Člani si lahko ogledate seznam vseh članov, ki ste jih kdaj povabili v skupino.",
                    text27: "Na seznamu so prikazani tudi člani, ki so bili odstranjeni iz skupine.",
                    text28: "Podobno kot v razdelku „Moja skupina“ si lahko ogledate ime, e-poštni naslov in status vsakega člana v skupini.",
                    text29: "Za vsakega člana so na voljo podrobne informacije - kliknite vrstico z opisom člana in sistem vas bo preusmeril na zaslon s podrobnostmi.",
                    text30: "Če je v skupini veliko udeležencev, lahko uporabite funkcijo iskanja. To storite tako, da v iskalno vrstico nad tabelo začnete vnašati besedilo. Sistem bo po besedilu, ki ste ga vnesli, poiskal vse informacije na seznamu (ime, e-poštni naslov).",
                    text18: "V zavihku Dnevnik sprememb so prikazani vsi ukrepi in dogodki, ki so se zgodili s skupino.",
                    text19: "V dnevniku so zabeleženi naslednji dogodki:",
                    text20: "Ustvarjanje skupine",
                    text21: "Spreminjanje imena skupine",
                    text22: "Povabilo člana",
                    text23: "Pridružitev člana skupini",
                    text24: "Začasna izključitev člana iz skupine",
                    text25: "Odstranitev člana iz skupine",
                    text12: "Vsak član skupine je lahko v enem od treh statusov:",
                    text13: "„Čaka na potrditev povabila“.",
                    text14: "„Pridružil se je skupini“.",
                    text15: "„Sodelovanje v skupini je začasno prekinjeno“.",
                    text16: "V vsakem trenutku lahko prekinete sodelovanje v skupini kateremu koli od povabljencev. Prav tako lahko kadar koli znova vključite katerega koli udeleženca v skupino.",
                    text17: "Udeleženca lahko odstranite iz skupine. V tem primeru informacije o članu ne bodo več prikazane na seznamu na zaslonu skupine. Lahko pa ga boste našli na seznamu vseh uporabnikov v razdelku „Uporabniki“.",
                    text11: "Če uporabnik še ni v sistemu OnMyDisk, mu bomo poslali e-poštno sporočilo, v katerem ga bomo povabili, da začne uporabljati sistem OnMyDisk in se pridruži vaši skupini.",
                    text10: "Če tak uporabnik obstaja, bo takoj dodan v vašo skupino.",
                    text9: "Ko bodo podatki shranjeni, bomo preverili, ali v sistemu OnMyDisk obstaja uporabnik z navedenim e-poštnim naslovom.",
                    text8: "Navedete lahko tudi ime člana, vendar to ni potrebno.",
                    text7: "Po kliku na ta gumb se prikaže pogovorno okno, v katerem morate navesti e-poštni naslov člana, ki ga želite povabiti.",
                    text6: "Če želite v skupino dodati novega člana, kliknite „Povabi člana“.",
                    text5: "V skupino lahko povabite toliko članov, kolikor je dovoljena omejitev skupine. Privzeta omejitev skupine je 5 članov. To število lahko povečate takoj ob ustvarjanju skupine ali v razdelku „Uporaba“.",
                    text1: "Uporaba konzole za upravljanje se začne z ustvarjanjem skupine. Vsak uporabnik lahko ustvari le eno skupino in vanjo povabi člane.",
                    text2: "Ko se prvič prijavite, vas bomo pozvali, da ustvarite skupino. Če želite ustvariti skupino, si morate izmisliti ime skupine in se odločiti, koliko članov bo imela.",
                    text3: "Izberete lahko tarifo (z mesečnim ali letnim plačilom) in sistem bo izračunal potreben znesek za plačilo.",
                    text4: "Po kliku na gumb „Ustvari skupino“ boste preusmerjeni na stran za plačilo. Ko boste prejeli plačilo, bomo za vas ustvarili skupino in vas preusmerili na stran z nastavitvami skupine, kjer lahko povabite udeležence",
                    Payment_and_subscription_settings:"Nastavitve plačila in naročnine",
                    We_use_the_tried: "Za obdelavo plačil uporabljamo preizkušeno in zaupanja vredno platformo Stripe.",
                    If_you_would_like_to_change:"Če želite spremeniti nastavitve plačila ali preklicati naročnino, sledite povezavi do strani z nastavitvami.",
                    Go_to_Payment_Settings: "Pojdite na Nastavitve plačila in naročnine",
                    ofdocuments: "dokumentov",
                    Current_document_limit: "Trenutna omejitev dokumentov (na mesec)",
                    Used_as_part_of_subscription: "Uporablja se kot del naročnine",
                    Available_until_the_end_of_the_period: "Na voljo do konca obdobja",
                    Additionally_used: "Dodatna uporaba",
                    Charge_for_additional_use: "Pristojbina za dodatno uporabo v višini",
                    will_be_charged:"se odšteje ",
                    To_save_money: "Če želite prihraniti, se naročite na dodatne pakete dokumentov. Vsak paket vsebuje dodatnih 10 dokumentov na mesec",
                    Postal_code: "Poštna številka",
                    Telephone: "Telefon",
                    Additional_document_package:"Dodatni paket dokumentov",
                    Checking_the_payment:"Preverjanje plačila..",
                    This_could_take_a_while: "To lahko traja nekaj časa.",
                    View_the_invoice: "Ogled računa",
                    Additional_group_member:"Dodatni član skupine",
                    OnMyDisk_Subscription: "Naročnina OnMyDisk",
                    Subscription_fee: "Naročnina",
                    Current_group_limit: "Trenutna omejitev skupine",
                    Set_limit: "Nastavljena omejitev",
                    No_change: "Brez spremembe",
                    No_need_to_pay_anything: "Ničesar ni treba plačati",
                    Additional_more: "Dodatno več",
                    Additional_slots: "Dodatne reže",
                    Thank_you_for_your_purchase: "Zahvaljujemo se vam za nakup!",
                    The_purchase_is_successful: "Nakup je uspešen",
                    A_confirmation_email_will_be_sent_to: "Potrditveno e-poštno sporočilo bo poslano na naslov ",
                    We_will_redirect_you_to_your: "Preusmerili vas bomo na vaš",
                    group_page: "stran skupine",
                    If_you_have_any_questions_please_contact_our_Support_Team: "Če imate kakršna koli vprašanja, se obrnite na našo ekipo za podporo",
                    Quota_setting:"Nastavitve uporabe",
                    Payment_history: "Zgodovina plačil",
                    You_havent:"Skupine še niste ustvarili.",
                    Lets_create:"Ustvarimo jo zdaj!",
                    Come_up:"Izmislite si lepo ime za svojo skupino",
                    beautiful_name:"Lepo ime za vašo skupino",
                    How_many_members: "Koliko članov bo imela skupina?",
                    Group_size_up: "Velikost skupine: do 5 udeležencev",
                    Signature_capacity_up: "Zmogljivost podpisovanja: do 20 dokumentov",
                    Additional_participants: "Dodatni udeleženci:",
                    Included_in_the_price: "Vključeno v ceno",
                    Per_month: "Na mesec",
                    Per_year: "Na leto",
                    Total_to_pay: "Skupno plačilo",
                    Specify_a_group_name: "Navedite ime skupine",
                    Create_a_group: "Ustvarite skupino",
                    Cancel: "Prekliči",
                    Participant_info: "Informacije o udeležencu",
                    Invitation_sent: "Vabilo je poslano",
                    In_the_group_since: "V skupini od",
                    Joined_the_group: "Pridružil se je skupini",
                    Waiting_for_participant_to_join: "Čaka na pridružitev udeleženca",
                    Increase_the_quota: "Povečanje kvote",
                    Invite_a_participant: "Povabite udeleženca",
                    Event_log: "Zapisnik dogodkov",
                    Participants: "Udeleženci",
                    Userset: "Uporabniške nastavitve lahko spremenite v aplikaciji",
                    Whatisname: "Kako mu je ime?",
                    Created: "Skupina je bila ustanovljena",
                    UserInvited: "povabljen v skupino",
                    UserJoined: "pridružil skupini",
                    UserRejected: "zavrnil povabilo",
                    UserSuspended1: "članstvo uporabnika",
                    UserSuspended2: "je bilo začasno prekinjeno",
                    UserDeleted: "odstranjen iz skupine",
                    GroupRenamed: "Skupina je bila preimenovana",
                    NewGroup : "Nova skupina",
                    Odjava: "Odjava",
                    Groups: "Moja skupina",
                    Quotas: "Uporaba",
                    Users: "Uporabniki",
                    About: "O servisu",
                    StatusModified:"Status changed",
                    Taxes: "Taxes",
                    Narocila: "Orders",
                    Status: "Status",
                    Employee: "Employee",
                    Employees: "Employees",
                    Accept: "Accept",
                    Date: "Date",
                    Amount: "Amount",
                    Account: "Account",
                    Balance: "Balance",
                    Show_charts: "Show charts",
                    Reports: "Reports",
                    January: "January",
                    February: "February",
                    March: "March",
                    April: "April",
                    May: "May",
                    June: "June",
                    July: "July",
                    August: "August",
                    September: "September",
                    October: "October",
                    November: "November",
                    December: "December",
                    from: "from",
                    Obvezno_polje: "Required field",
                    Delete: "Delete",
                    Delete_warning: "Are you sure delete this partner?",
                    Delete_text :"Once deleted, you will no longer be able to select this partner as a customer or performer for orders",
                    Yes: "Yes",
                    No: "No",
                    Delete_error_title: "Can\'t delete",
                    Done: "Opravljeno!",
                    Kontaktna_oseba:"Contact Person",
                    Telefon:"Phone",
                    Faks:"Fax",
                    BackToList : "Back to list",
                    Predogled: "Preview",
                    Preneseno: "Uoloaded",
                    invite_message: "To register in the application, please enter the invitation code:",
                    invite_mailto: "To receive an invitation code, please send a request to ",
                    invite_error: "Alas, the code doesn't work",
                    Davek_za_DDV: "VAT Amount",
                    ID_za_DDV: "VAT ID",
                    Hvala_za_zaupanje: "Thank you for your trust",
                    Znesek_prosimo_plačajte_na: "Please pay the amount to",
                    Ob_plačilu_navedite_referenco: "Please provide a reference when making payment",
                    Skupaj : "Total",
                    Dokument : 'Document',
                    save_and_stay: "Save and continue editing",
                    save_and_quit: "Save and go to list",
                    agree_to_treat1 : "I agree to FinTim communicating its products and offers to me by email. I can withdraw my consent at any time.",
                    agree_to_treat2 : "I agree that FinTim collects, stores and uses my personal data (for the full use of the various services provided by the site). I can withdraw my consent at any time. The privacy policy is available ",
                    Close_this_info:"Close this info",
                    Obračunavanje: "Billing",
                    Seznam_narocil: "My orders",
                    Ne_morem_shraniti: "Can't save",
                    Status_spremembe: "Change status",
                    Nazaj_na_seznam : "Back to list",
                    Namigi : "Hints",
                    Ni_voznikov: "No drivers",
                    Register: "Register",
                    Log_in : "Login",
                    Password : "Password",
                    repeat_Password : "Repeat password",
                    Company_name: "Company name",
                    Please_input_your_Username : "Please input your Username!",
                    Please_input_your_Email: "Please input a valid email",
                    Please_input_your_Password : "Please input your Password!",
                    Please_input_your_Companyname : "Please input your company name!",
                    Remember_me : "Remember me",
                    Forgot_password : "Forgot password",
                    Or: "Or ",
                    register_now: "register now!",
                    welcome : 'Welcome',
                    Številka :'Number',
                    Cena : 'Price',
                    Plačila : 'Payements',
                    Poglejte_vse : 'See all',
                    Več : 'More',
                    Izberite : 'Choose',
                    Domov : 'Domov',
                    Vsa : 'Vse skupine',
                    Aktivno: "Aktivne",
                    Ustavljeno: "Ustavljene",
                    Arhivirano: "Arhivirane",
                    Prenesite_v_Excel :'Download Excel',
                    Kliknite_za_razvrščanje_padajoče : 'Click to sort ascending',
                    Kliknite_za_razvrščanje_naraščajoče : 'Click to sort descending',
                    Prekliči_razvrščanje : 'Cancel sorting',
                    Od_datum :'From date',
                    Do_datum: 'To date',
                    Išči: 'Išči..',
                    Osnovni_podatki:'General info',
                    Shraniti :'Shraniti',
                    Skrijte : 'Hide',
                    Prenesite_dokument: 'Upload document',
                    Naročilo :'Order',
                    Nadaljevanje : 'Continue',
                    Nazaj : 'Back',
                    Koledar : 'Calendar',
                    Po : 'Mon',
                    To : 'Tue',
                    Sr : 'Wed',
                    Če : 'Thu',
                    Pe : 'Fri',
                    So : 'Sat',
                    Ne : 'Sun',
                    Ne_shranite_sprememb : 'Ne shranite sprememb',
                    Ključni_kazalniki : 'Key indicators',
                    Ime : 'Ime',
                    Priimek : 'Surname',
                    Ime_datoteke : 'Filename',
                    Vrsta : 'Type',
                    Račun : 'Invoice',
                    Predračun : 'Estimate',
                    Nasprotna_stranka : 'Counterpart',
                    Datum_dokumenta: 'Documents\'s date',
                    Datum_plačila : 'Paymenet\'s date',
                    Tiskanje : 'Print',
                    Naziv_storitva_blaga : 'Product or service',
                    Količina : 'Quantity',
                    Davek : 'Taxe',
                    Naziv : 'Naziv',
                    Davčna_Številka : 'Davčna številka',
                    Matična_Številka : 'Company Id',
                    Naslov : 'Naslov',
                    Nastavitve: "Nastavitve",
                    Nastavitve_uporabnika : 'Nastavitve uporabnika',
                    Nastavitve_podjetja : 'Nastavitve podjetja',
                    Prenesite_sliko : 'Upload image',
                    Prenesete_lahko_slike_velikosti_do : 'Please upload image not greater than',
                    E_pošta : 'Email',
                    Login : 'Login',
                    Staro_geslo : 'Old password',
                    Novo_geslo: 'New password',
                    Prenesite_datoteko_ali_povlecite : 'Upload file or drag',
                    in_spusti_na_označeno_območje : 'and drop it in this area',
                    ne_več_kot : 'not greater than ',
                    Pogodba : 'Agreemenent'

                }
        },

        fr: {
            translation: {
                Table_of_Contents: "Table des matières",
                Group_management: "Gestion des groupes",
                Group_creation: "Création d'un groupe",
                Inviting_participants: "Inviter des participants",
                Member_statuses: "Statut des membres",
                Change_log: "Journal des modifications",
                Participants2: "Participants",
                Search_function: "Fonction de recherche",
                Activity_History: "Historique des activités",
                Usage_and_payments: "Utilisation et paiements",
                Customising_usage: "Personnalisation de l'utilisation",
                Cost_calculation: "Calcul des coûts",
                Payment_history2: "Historique des paiements",
                Settings: "Paramètres",
                User_settings: "Paramètres de l'utilisateur",
                Subscription_management: "Gestion des abonnements",
                text49: "Dans la section « Paramètres », vous pouvez spécifier les détails d'une entité légale (si vous souhaitez utiliser le service en tant qu'entité légale), ainsi que modifier les paramètres de l'abonnement, configurer la méthode de paiement ou annuler l'abonnement.",
                text50: "La console de gestion est un complément à l'application OnMyDisk. En tant qu'utilisateur de OnMyDisk, vous avez automatiquement accès à la console de gestion.",
                text51: "Par conséquent, tous les paramètres de l'utilisateur doivent être modifiés à partir de l'application OnMyDisk. Pour modifier votre mot de passe ou votre nom d'utilisateur, vous devez accéder à l'application OnMyDisk via le lien.",
                text52: "Si vous souhaitez que les factures relatives à l'utilisation du service soient émises à l'intention d'une entité juridique, indiquez les coordonnées de votre entreprise dans les paramètres.",
                text53: "Il est nécessaire d'indiquer le nom de l'entreprise, le numéro fiscal, l'adresse et le numéro de téléphone.",
                text54: "Après avoir enregistré ces informations, toutes les factures seront émises au nom de l'entité juridique.",
                text55: "Pour la fiabilité et la sécurité du processus de paiement, nous utilisons la plateforme Stripe.",
                text56: "Pour modifier les paramètres de l'abonnement, vous pouvez accéder à un portail Stripe spécial, où vous pouvez modifier le mode de paiement et d'autres paramètres de l'abonnement.",
                text43: "Nous utilisons le paiement proportionnel pour notre service. Comme le service OnMyDisc fonctionne sur le principe de l'abonnement mensuel, le paiement pour l'utilisation du service est effectué sur une base mensuelle.",
                text44: "Si vous avez besoin d'augmenter la limite au milieu de la période, le premier paiement sera calculé sur la base du nombre de jours restants jusqu'à la fin de votre période d'abonnement.",
                text45: "À partir de la période suivante, vous serez facturé pour l'augmentation de la limite dans son intégralité.",
                text46: "Dans l'onglet « Historique des paiements », vous trouverez tous les paiements que vous avez effectués.",
                text47: "Nous utilisons la plateforme Stripe pour sécuriser le processus de paiement.",
                text48: "En cliquant sur le lien « Voir la facture » (s'ouvre dans une nouvelle fenêtre), vous pouvez obtenir des informations détaillées sur chaque paiement, ainsi que télécharger la facture et le reçu de paiement.",
                text31: "En accédant à l'écran de détail, vous pouvez consulter l'historique complet des activités du membre.",
                text32: "Dans la section Utilisation, vous pouvez fixer des limites à l'utilisation du système. Par défaut, vous pouvez créer un groupe de 5 membres au maximum dans le cadre d'un abonnement.",
                text33: "Dans les cadres de l'abonnement, vous avez également la possibilité d'utiliser le mécanisme de signature électronique 20 fois pour les documents que vous stockez dans l'application OnMyDisk.",
                text34: "Si nécessaire, vous pouvez modifier ces paramètres dans cette section.",
                text35: "Pour augmenter le nombre de participants au groupe, il suffit d'indiquer le nombre de participants supplémentaires dont vous avez besoin dans la section décrivant les limites actuelles du groupe",
                text36: "Le système calculera le montant nécessaire pour augmenter la limite.",
                text37: "Pour augmenter la limite, le système débitera automatiquement le montant nécessaire en utilisant le mode de paiement que vous avez spécifié lors de la création du groupe.",
                text38: "Une fois le paiement effectué, le système affichera un message et la limite du groupe sera augmentée.",
                text39: "De la même manière, vous pouvez augmenter la limite de signature des documents.",
                text40: "Si nécessaire, vous pouvez augmenter la limite par paquets de 5 documents. Pour augmenter la limite, indiquez le nombre de paquets et le système calculera le montant nécessaire à payer.",
                text41: "Pour augmenter la limite, le système débitera automatiquement le montant nécessaire en utilisant la méthode de paiement que vous avez spécifiée lors de la création du groupe.",
                text42: "Une fois le paiement effectué, le système affichera un message et la limite de documents sera augmentée.",
                text26: "Dans la section « Membres », vous pouvez afficher une liste de tous les membres que vous avez invités dans le groupe.",
                text27: "Même les membres qui ont été retirés du groupe sont affichés dans la liste.",
                text28: "Tout comme dans la section « Mon groupe », vous pouvez voir le nom, l'adresse électronique et le statut de chaque membre dans le groupe.",
                text29: "Des informations détaillées sont disponibles pour chaque membre - cliquez sur la ligne contenant la description du membre et le système vous redirigera vers l'écran détaillé",
                text30: "S'il y a beaucoup de participants, vous pouvez utiliser la fonction de recherche. Pour ce faire, commencez à taper du texte dans la barre de recherche située au-dessus du tableau. Le système recherchera dans le texte que vous avez saisi toutes les informations contenues dans la liste (nom, adresse électronique).",
                text18: "L'onglet Journal des modifications affiche toutes les actions et tous les événements qui se sont produits avec le groupe.",
                text19: "Les événements suivants sont enregistrés dans le journal :",
                text20: "Création d'un groupe",
                text21: "Modification du nom du groupe",
                text22: "Invitation d'un membre",
                text23: "Joindre un membre au groupe",
                text24: "Suspension d'un membre",
                text25: "Supprimer un membre d'un groupe",
                text12: "Chaque membre d'un groupe peut avoir l'un des trois statuts suivants :",
                text13: "« En attente de la confirmation de l'invitation »",
                text14: "« A rejoint le groupe »",
                text15: "« La participation au groupe est suspendue ».",
                text16: "Vous pouvez à tout moment suspendre la participation au groupe de n'importe quel invité. De même, vous pouvez à tout moment réintégrer n'importe quel participant dans le groupe",
                text17: "Vous pouvez retirer un membre du groupe. Dans ce cas, les informations relatives à ce membre ne seront plus affichées dans la liste de l'écran du groupe. Mais vous pourrez le retrouver dans la liste de tous les utilisateurs dans la section « Utilisateurs ».",
                text11: "Si l'utilisateur n'est pas encore dans le système OnMyDisk, nous lui enverrons un courriel l'invitant à commencer à utiliser OnMyDisk et à rejoindre votre groupe.",
                text10: "Si un tel utilisateur existe, il sera immédiatement ajouté à votre groupe.",
                text9: "Lorsque les informations sont enregistrées, nous vérifions s'il existe un utilisateur ayant l'adresse électronique spécifiée dans le système OnMyDisk.",
                text8: "Vous pouvez également indiquer le nom du membre, mais ce n'est pas nécessaire.",
                text7: "Après avoir cliqué sur ce bouton, une boîte de dialogue apparaît dans laquelle vous devez spécifier l'adresse e-mail du membre que vous souhaitez inviter.",
                text6: "Pour ajouter un nouveau membre au groupe, cliquez sur « Inviter un membre »",
                text5: "Vous pouvez inviter autant de membres à un groupe que votre limite de groupe le permet. La limite par défaut est de 5 membres. Vous pouvez augmenter ce nombre soit immédiatement lors de la création d'un groupe, soit dans la section « Utilisation ».",
                text1: "L'utilisation de la console de gestion commence par la création d'un groupe. Chaque utilisateur ne peut créer qu'un seul groupe et y inviter des membres.\n",
                text2: "Lorsque vous vous connectez pour la première fois, vous êtes invité à créer un groupe. Pour créer un groupe, vous devez lui donner un nom et décider du nombre de ses membres.",
                text3: "Vous pouvez choisir un tarif (avec paiement mensuel ou annuel) et le système calculera le montant nécessaire à payer.",
                text4: "Après avoir cliqué sur le bouton « Créer un groupe », vous serez redirigé vers la page de paiement. Une fois le paiement reçu, nous créerons un groupe pour vous et vous redirigerons vers la page des paramètres du groupe où vous pourrez inviter des participants.",
                Payment_and_subscription_settings:"Paiement et d'abonnement",
                We_use_the_tried: "Nous utilisons la plateforme Stripe, qui a fait ses preuves, pour traiter les paiements.",
                If_you_would_like_to_change:"Si vous souhaitez modifier vos paramètres de paiement ou annuler votre abonnement, veuillez suivre le lien vers la page des paramètres.",
                Go_to_Payment_Settings: "Accéder aux paramètres de paiement et d'abonnement",
                ofdocuments: "documents",
                Current_document_limit: "Limite actuelle de documents (par mois)",
                Used_as_part_of_subscription: "Utilisé dans le cadre de l'abonnement",
                Available_until_the_end_of_the_period: "Disponible jusqu'à la fin de la période",
                Additionally_used: "Utilisation supplémentaire",
                Charge_for_additional_use: "Les frais d'utilisation supplémentaire de",
                will_be_charged:"seront déduits ",
                To_save_money: "Pour économiser de l'argent, souscrivez à des paquets de documents supplémentaires. Chaque paquet contient 10 documents supplémentaires par mois",
                Postal_code: "Code postal",
                Telephone: "Téléphone",
                Additional_document_package:"Paquet de documents supplémentaires",
                Checking_the_payment:"Vérification du paiement..",
                This_could_take_a_while: "Cette opération peut prendre un certain temps.",
                View_the_invoice: "Voir la facture",
                Additional_group_member:"Membre supplémentaire du groupe",
                OnMyDisk_Subscription: "Abonnement OnMyDisk",
                Subscription_fee: "Frais d\'abonnement",
                Current_group_limit: "Limite actuelle du groupe",
                Set_limit: "Limite fixée",
                No_change: "Pas de changement",
                No_need_to_pay_anything: "Rien à payer",
                Additional_more: "Plus de supplémentaires",
                Additional_slots: "Créneaux supplémentaires",
                Thank_you_for_your_purchase: "Merci pour votre achat !",
                The_purchase_is_successful: "L'achat est réussi",
                A_confirmation_email_will_be_sent_to: "Un courriel de confirmation sera envoyé à ",
                We_will_redirect_you_to_your: "Nous vous redirigerons vers votre",
                group_page: "page du groupe",
                If_you_have_any_questions_please_contact_our_Support_Team: "Si vous avez des questions, veuillez contacter notre équipe d'assistance.",
                Quota_setting:"Paramètres d\'utilisation",
                Payment_history: "Historique des paiements",
                You_havent:"Vous n\'avez pas encore créé de groupe.",
                Lets_create:"Créons-le tout de suite !",
                Come_up:"Trouvez un beau nom pour votre groupe",
                beautiful_name:"Un beau nom pour votre groupe",
                How_many_members: "Combien de membres y aura-t-il ?",
                Group_size_up: "Taille du groupe : jusqu'à 5 participants",
                Signature_capacity_up: "Capacité de signature : jusqu'à 20 documents",
                Additional_participants: "Participants supplémentaires :",
                Included_in_the_price: "Inclus dans le prix",
                Per_month: "Par mois",
                Per_year: "Par an",
                Total_to_pay: "Total à payer",
                Specify_a_group_name: "Entrer un nom de groupe",
                Create_a_group: "Créer un groupe",
                Cancel: "Annuler",
                Participant_info: "Informations sur le participant",
                Invitation_sent: "L'\invitation est envoyée",
                In_the_group_since: "Dans le groupe depuis",
                Joined_the_group: "Rejoint le groupe",
                Waiting_for_participant_to_join: "En attente d\'un participant",
                Increase_the_quota: "Augmenter le quota",
                Invite_a_participant: "Inviter un participant",
                Event_log: "Journal des événements",
                Participants: "Les participants",
                Userset: "Vous pouvez modifier les paramètres de l\'utilisateur dans l\'application",
                Whatisname: "Quel est son nom ?",
                Created: "Le groupe a été créé",
                UserInvited: "invité au groupe",
                UserJoined: "a rejoint le groupe",
                UserRejected: "a décliné l'invitation",
                UserSuspended1: "L'adhésion de l'utilisateur",
                UserSuspended2: "a été suspendue",
                UserDeleted: "retiré du groupe",
                GroupRenamed: "Le groupe a été renommé",
                NewGroup : "Nouveau groupe",
                Odjava: "Déconnexion",
                Groups: "Mon groupe",
                Quotas: "Utilisation",
                Users: "Utilisateurs",
                About: "A propos",
                StatusModified:"Statut modifié",
                Taxes: "Taxes",
                Narocila: "Orders",
                Status: "Statut",
                Employee: "Employee",
                Employees: "Employees",
                Accept: "Accept",
                Date: "Date",
                Amount: "Amount",
                Account: "Account",
                Balance: "Balance",
                Show_charts: "Show charts",
                Reports: "Reports",
                January: "January",
                February: "February",
                March: "March",
                April: "April",
                May: "May",
                June: "June",
                July: "July",
                August: "August",
                September: "September",
                October: "October",
                November: "November",
                December: "December",
                from: "from",
                Obvezno_polje: "Required field",
                Delete: "Delete",
                Delete_warning: "Are you sure delete this partner?",
                Delete_text :"Once deleted, you will no longer be able to select this partner as a customer or performer for orders",
                Yes: "Yes",
                No: "No",
                Delete_error_title: "Can\'t delete",
                Done: "C\'est fait!",
                Kontaktna_oseba:"Contact Person",
                Telefon:"Phone",
                Faks:"Fax",
                BackToList : "Back to list",
                Predogled: "Preview",
                Preneseno: "Uoloaded",
                invite_message: "To register in the application, please enter the invitation code:",
                invite_mailto: "To receive an invitation code, please send a request to ",
                invite_error: "Alas, the code doesn't work",
                Davek_za_DDV: "VAT Amount",
                ID_za_DDV: "VAT ID",
                Hvala_za_zaupanje: "Thank you for your trust",
                Znesek_prosimo_plačajte_na: "Please pay the amount to",
                Ob_plačilu_navedite_referenco: "Please provide a reference when making payment",
                Skupaj : "Total",
                Dokument : 'Document',
                save_and_stay: "Save and continue editing",
                save_and_quit: "Save and go to list",
                agree_to_treat1 : "I agree to FinTim communicating its products and offers to me by email. I can withdraw my consent at any time.",
                agree_to_treat2 : "I agree that FinTim collects, stores and uses my personal data (for the full use of the various services provided by the site). I can withdraw my consent at any time. The privacy policy is available ",
                Close_this_info:"Close this info",
                Obračunavanje: "Billing",
                Seznam_narocil: "My orders",
                Ne_morem_shraniti: "Can't save",
                Status_spremembe: "Change status",
                Nazaj_na_seznam : "Back to list",
                Namigi : "Hints",
                Ni_voznikov: "No drivers",
                Register: "Register",
                Log_in : "Login",
                Password : "Password",
                repeat_Password : "Repeat password",
                Company_name: "Company name",
                Please_input_your_Username : "Please input your Username!",
                Please_input_your_Email: "Please input a valid email",
                Please_input_your_Password : "Please input your Password!",
                Please_input_your_Companyname : "Please input your company name!",
                Remember_me : "Remember me",
                Forgot_password : "Forgot password",
                Or: "Or ",
                register_now: "register now!",
                welcome : 'Welcome',
                Številka :'Number',
                Cena : 'Price',
                Plačila : 'Payements',
                Poglejte_vse : 'See all',
                Več : 'More',
                Izberite : 'Choose',
                Domov : 'Accueil',
                Vsa : 'Tous les groupes',
                Aktivno: "Actifs",
                Ustavljeno: "Arrêtés",
                Arhivirano: "Archivés",
                Prenesite_v_Excel :'Download Excel',
                Kliknite_za_razvrščanje_padajoče : 'Click to sort ascending',
                Kliknite_za_razvrščanje_naraščajoče : 'Click to sort descending',
                Prekliči_razvrščanje : 'Cancel sorting',
                Od_datum :'From date',
                Do_datum: 'To date',
                Išči: 'Recherche..',
                Osnovni_podatki:'General info',
                Shraniti :'Sauvegarder',
                Skrijte : 'Hide',
                Prenesite_dokument: 'Upload document',
                Naročilo :'Order',
                Nadaljevanje : 'Continue',
                Nazaj : 'Back',
                Koledar : 'Calendar',
                Po : 'Mon',
                To : 'Tue',
                Sr : 'Wed',
                Če : 'Thu',
                Pe : 'Fri',
                So : 'Sat',
                Ne : 'Sun',
                Ne_shranite_sprememb : 'Don\'t save',
                Ključni_kazalniki : 'Key indicators',
                Ime : 'Nom',
                Priimek : 'Surname',
                Ime_datoteke : 'Filename',
                Vrsta : 'Type',
                Račun : 'Invoice',
                Predračun : 'Estimate',
                Nasprotna_stranka : 'Counterpart',
                Datum_dokumenta: 'Documents\'s date',
                Datum_plačila : 'Paymenet\'s date',
                Tiskanje : 'Print',
                Naziv_storitva_blaga : 'Product or service',
                Količina : 'Quantity',
                Davek : 'Taxe',
                Naziv : 'Titre',
                Davčna_Številka : 'IF',
                Matična_Številka : 'Company Id',
                Naslov : 'Adresse',
                Nastavitve: "Paramètres",
                Nastavitve_uporabnika : 'Paramètres de l\'utilisateur',
                Nastavitve_podjetja : 'Paramètres de l\'entreprise',
                Prenesite_sliko : 'Upload image',
                Prenesete_lahko_slike_velikosti_do : 'Please upload image not greater than',
                E_pošta : 'Email',
                Login : 'Login',
                Staro_geslo : 'Old password',
                Novo_geslo: 'New password',
                Prenesite_datoteko_ali_povlecite : 'Upload file or drag',
                in_spusti_na_označeno_območje : 'and drop it in this area',
                ne_več_kot : 'not greater than ',
                Pogodba : 'Agreemenent'

            }
        },
        
        de : {
            translation: {
                Table_of_Contents: "Inhaltsübersicht",
                Group_management: "Verwaltung von Grupp",
                Group_creation: "Erstellung von Gruppen",
                Inviting_participants: "Einladen von Teilnehmern",
                Member_statuses: "Status der Mitglieder",
                Change_log: "Änderungsprotokoll",
                Participants2: "Teilnehmer",
                Search_function: "Suchfunktion",
                Activity_History: "Aktivitätsverlauf",
                Usage_and_payments: "Nutzung und Zahlungen",
                Customising_usage: "Anpassung der Nutzung",
                Cost_calculation: "Berechnung der Kosten",
                Payment_history2: "Zahlungsverlauf",
                Settings: "Einstellungen",
                User_settings: "Benutzereinstellungen",
                Subscription_management: "Verwaltung von Abonnements",
                text49: "Im Abschnitt „Einstellungen“ können Sie die Details einer juristischen Person angeben (wenn Sie den Dienst als juristische Person nutzen möchten), sowie die Abonnementparameter ändern, die Zahlungsmethode einrichten oder das Abonnement kündigen.",
                text50: "Die Verwaltungskonsole ist ein Zusatz zur OnMyDisk-Anwendung. Als OnMyDisk-Benutzer erhalten Sie automatisch Zugriff auf die Verwaltungskonsole.",
                text51: "Daher müssen alle Benutzereinstellungen über die OnMyDisk-Anwendung geändert werden. Um Ihr Passwort oder Ihren Benutzernamen zu ändern, müssen Sie über den Link zur OnMyDisk-Anwendung gehen.",
                text52: "Wenn Sie möchten, dass die Rechnungen für die Nutzung des Dienstes auf eine juristische Person ausgestellt werden, geben Sie in den Einstellungen die Angaben zu Ihrem Unternehmen an.",
                text53: "Es ist notwendig, den Firmennamen, die Steuernummer, die Adresse und die Telefonnummer anzugeben.",
                text54: "Nach dem Speichern dieser Informationen werden alle Rechnungen auf den Namen der juristischen Person ausgestellt.",
                text55: "Für die Zuverlässigkeit und Sicherheit des Zahlungsvorgangs verwenden wir die Plattform Stripe.",
                text56: "Um die Parameter des Abonnements zu ändern, können Sie auf ein spezielles Stripe-Portal gehen, wo Sie die Zahlungsmethode und andere Parameter des Abonnements ändern können.",
                text43: "Wir verwenden eine anteilige Zahlung für unseren Dienst. Da OnMyDisc nach dem Prinzip des Monatsabonnements arbeitet, erfolgt die Zahlung für die Nutzung des Dienstes auf monatlicher Basis.",
                text44: "Wenn Sie das Limit in der Mitte des Zeitraums erhöhen müssen, wird die erste Zahlung auf der Grundlage der Anzahl der verbleibenden Tage bis zum Ende Ihres Abonnementzeitraums berechnet.",
                text45: "Ab dem nächsten Zeitraum wird Ihnen das erhöhte Limit in voller Höhe in Rechnung gestellt.",
                text46: "Auf der Registerkarte „Zahlungsverlauf“ finden Sie alle Zahlungen, die Sie getätigt haben.",
                text47: "Wir verwenden die Plattform Stripe, um den Zahlungsvorgang sicher zu gestalten.",
                text48: "Wenn Sie auf den Link „Rechnung anzeigen“ klicken (wird in einem neuen Fenster geöffnet), können Sie detaillierte Informationen zu jeder Zahlung einsehen sowie die Rechnung und den Zahlungsbeleg herunterladen.",
                text31: "Auf dem Detailbildschirm können Sie den gesamten Verlauf der Aktivitäten mit dem Mitglied einsehen.",
                text32: "Im Abschnitt „Nutzung“ können Sie die Nutzung des Systems begrenzen. Standardmäßig können Sie eine Gruppe von bis zu 5 Mitgliedern innerhalb eines Abonnements erstellen.",
                text33: "In den Abonnementrahmen haben Sie außerdem die Möglichkeit, den Mechanismus der elektronischen Signatur 20 Mal für die Dokumente zu verwenden, die Sie in der OnMyDisk-Anwendung speichern.",
                text34: "Falls erforderlich, können Sie diese Parameter in diesem Abschnitt ändern.",
                text35: "Um die Anzahl der Teilnehmer in der Gruppe zu erhöhen, genügt es, in der Sektion, die die aktuellen Grenzen der Gruppe beschreibt, anzugeben, wie viele zusätzliche Teilnehmer Sie benötigen.",
                text36: "Das System berechnet den Betrag, den Sie für die Erhöhung des Limits zahlen müssen.",
                text37: "Um das Limit zu erhöhen, bucht das System automatisch den erforderlichen Betrag mit der Zahlungsmethode ab, die Sie bei der Erstellung der Gruppe angegeben haben.",
                text38: "Nach erfolgreicher Zahlung zeigt das System eine Meldung an und das Gruppenlimit wird erhöht.",
                text39: "Auf ähnliche Weise können Sie das Limit für die Unterzeichnung von Dokumenten erhöhen.",
                text40: "Falls erforderlich, können Sie das Limit in Paketen von 5 Dokumenten erhöhen. Um das Limit zu erhöhen, geben Sie die Anzahl der Pakete an, und das System errechnet den erforderlichen Betrag, der zu zahlen ist.",
                text41: "Um das Limit zu erhöhen, bucht das System automatisch den erforderlichen Betrag mit der Zahlungsmethode ab, die Sie bei der Erstellung der Gruppe angegeben haben.",
                text42: "Nach erfolgreicher Zahlung zeigt das System eine Meldung an und das Limit für Dokumente wird erhöht.",
                text26: "Im Bereich „Mitglieder“ können Sie eine Liste aller Mitglieder einsehen, die Sie jemals in die Gruppe eingeladen haben.",
                text27: "Auch Mitglieder, die aus der Gruppe entfernt wurden, werden in der Liste angezeigt.",
                text28: "Genau wie im Bereich „Meine Gruppe“ können Sie den Namen, die E-Mail-Adresse und den Status jedes Mitglieds in der Gruppe sehen.",
                text29: "Für jedes Mitglied sind detaillierte Informationen verfügbar - klicken Sie auf die Zeile mit der Beschreibung des Mitglieds und das System leitet Sie zur Detailansicht weiter",
                text30: "Wenn es viele Teilnehmer gibt, können Sie die Suchfunktion nutzen. Beginnen Sie dazu mit der Texteingabe in der Suchleiste über der Tabelle. Das System durchsucht den von Ihnen eingegebenen Text nach allen Informationen in der Liste (Name, E-Mail-Adresse).",
                text18: "Auf der Registerkarte Änderungsprotokoll werden alle Aktionen und Ereignisse angezeigt, die im Zusammenhang mit der Gruppe aufgetreten sind.",
                text19: "Die folgenden Ereignisse werden im Protokoll aufgezeichnet:",
                text20: "Erstellen einer Gruppe",
                text21: "Ändern des Gruppennamens",
                text22: "Einladen eines Mitglieds",
                text23: "Beitritt eines Mitglieds zur Gruppe",
                text24: "Suspendierung eines Mitglieds",
                text25: "Entfernen eines Mitglieds aus einer Gruppe",
                text12: "Jedes Mitglied einer Gruppe kann sich in einem von drei Status befinden:",
                text13: "„Warten auf die Bestätigung der Einladung“",
                text14: "„Mitglied der Gruppe“",
                text15: "„Teilnahme an der Gruppe ist ausgesetzt“.",
                text16: "Sie können jederzeit die Teilnahme an der Gruppe für jeden der Eingeladenen aussetzen. Sie können auch jederzeit einen der Teilnehmer wieder in die Gruppe aufnehmen.",
                text17: "Sie können ein Mitglied aus der Gruppe entfernen. In diesem Fall werden die Informationen über das Mitglied nicht mehr in der Liste auf dem Gruppenbildschirm angezeigt. Sie können es jedoch in der Liste aller Benutzer im Abschnitt „Benutzer“ wiederfinden.",
                text11: "Wenn der Benutzer noch nicht im OnMyDisk-System ist, senden wir ihm eine E-Mail mit der Einladung, OnMyDisk zu nutzen und Ihrer Gruppe beizutreten.",
                text10: "Wenn ein solcher Benutzer existiert, wird er/sie sofort zu Ihrer Gruppe hinzugefügt.",
                text9: "Wenn die Informationen gespeichert werden, wird geprüft, ob es einen Benutzer mit der angegebenen E-Mail-Adresse im OnMyDisk-System gibt.",
                text8: "Sie können auch den Namen des Mitglieds angeben, aber das ist nicht notwendig.",
                text7: "Nachdem Sie auf diese Schaltfläche geklickt haben, wird ein Dialogfeld angezeigt, in dem Sie die E-Mail-Adresse des einzuladenden Mitglieds angeben müssen.",
                text6: "Um ein neues Mitglied in die Gruppe aufzunehmen, klicken Sie auf „Mitglied einladen“.",
                text5: "Sie können so viele Mitglieder in eine Gruppe einladen, wie Ihr Gruppenlimit zulässt. Die Standardvorgabe für Gruppen ist 5 Mitglieder. Sie können diese Zahl entweder direkt bei der Erstellung einer Gruppe oder im Abschnitt „Verwendung“ erhöhen",
                text1: "Die Verwendung der Verwaltungskonsole beginnt mit der Erstellung einer Gruppe. Jeder Benutzer kann nur eine Gruppe erstellen und Mitglieder in die Gruppe einladen.",
                text2: "Wenn Sie sich zum ersten Mal anmelden, werden Sie aufgefordert, eine Gruppe zu erstellen. Um eine Gruppe zu erstellen, müssen Sie sich einen Namen für die Gruppe ausdenken und entscheiden, wie viele Mitglieder sie haben soll.",
                text3: "Sie können einen Tarif wählen (mit monatlicher oder jährlicher Zahlung), und das System berechnet den erforderlichen Betrag, der zu zahlen ist.",
                text4: "Nachdem Sie auf die Schaltfläche „Gruppe erstellen“ geklickt haben, werden Sie auf die Zahlungsseite weitergeleitet. Sobald die Zahlung eingegangen ist, erstellen wir eine Gruppe für Sie und leiten Sie auf die Seite mit den Gruppeneinstellungen weiter, wo Sie Teilnehmer einladen können.",
                Payment_and_subscription_settings:"Zahlungen und Abonnements",
                We_use_the_tried: "Wir verwenden die bewährte und vertrauenswürdige Stripe-Plattform zur Zahlungsabwicklung.",
                If_you_would_like_to_change:"Wenn Sie Ihre Zahlungseinstellungen ändern oder Ihr Abonnement kündigen möchten, folgen Sie bitte dem Link zur Einstellungsseite.",
                Go_to_Payment_Settings: "Zu den Zahlungs- und Abonnementeinstellungen",
                ofdocuments: "Dokumente",
                Current_document_limit: "Aktuelles Dokumentenlimit (pro Monat)",
                Used_as_part_of_subscription: "Innerhalb des Abonnements genutzt",
                Available_until_the_end_of_the_period: "Verfügbar bis zum Ende des Zeitraums",
                Additionally_used: "Zusätzliche Nutzung",
                Charge_for_additional_use: "Die zusätzliche Nutzungsgebühr von",
                will_be_charged:"wird abgezogen ",
                To_save_money: "Um Geld zu sparen, abonnieren Sie zusätzliche Dokumentenpakete. Jedes Paket enthält zusätzlich 10 Dokumente pro Monat",
                Postal_code: "Postleitzahl",
                Telephone: "Telefon",
                Additional_document_package:"Zusätzliches Dokumentenpaket",
                Checking_the_payment:"Überprüfung der Zahlung..",
                This_could_take_a_while: "Dies kann einige Zeit in Anspruch nehmen.",
                View_the_invoice: "Die Rechnung einsehen",
                Additional_group_member:"Zusätzliches Gruppenmitglied",
                OnMyDisk_Subscription: "OnMyDisk-Abonnement",
                Subscription_fee: "Gebühr für das Abonnement",
                Current_group_limit: "Aktuelles Gruppenlimit",
                Set_limit: "Eingestelltes Limit",
                No_change: "Keine Änderung",
                No_need_to_pay_anything: "Nichts zu zahlen",
                Additional_more: "Zusätzliche mehr",
                Additional_slots: "Zusätzliche Slots",
                Thank_you_for_your_purchase: "Vielen Dank für Ihren Kauf!",
                The_purchase_is_successful: "Der Kauf ist erfolgreich",
                A_confirmation_email_will_be_sent_to: "Sie erhalten eine Bestätigungs-E-Mail an ",
                We_will_redirect_you_to_your: "Wir leiten Sie weiter zu Ihrer",
                group_page: "Gruppenseite",
                If_you_have_any_questions_please_contact_our_Support_Team: "Wenn Sie Fragen haben, wenden Sie sich bitte an unser Support-Team",
                Quota_setting:"Einstellungen für die Verwendung",
                Payment_history: "Zahlungsverlauf",
                You_havent:"Sie haben noch keine Gruppe erstellt.",
                Lets_create:"Lassen Sie uns jetzt eine erstellen!",
                Come_up:"Denken Sie sich einen schönen Namen für Ihre Gruppe aus",
                beautiful_name:"Ein schöner Name für Ihre Gruppe",
                How_many_members: "Wie viele Mitglieder wird die Gruppe haben?",
                Group_size_up: "Gruppengröße: bis zu 5 Teilnehmer",
                Signature_capacity_up: "Unterschriftsfähigkeit - bis zu 20 Dokumente",
                Additional_participants: "Zusätzliche Teilnehmer:",
                Included_in_the_price: "Im Preis inbegriffen",
                Per_month: "Pro Monat",
                Per_year: "Pro Jahr",
                Total_to_pay: "Insgesamt zahlbar",
                Specify_a_group_name: "Geben Sie einen Gruppennamen ein",
                Create_a_group: "Erstellen Sie eine Gruppe",
                Cancel: "Abbrechen",
                Participant_info: "Informationen für Teilnehmer",
                Invitation_sent: "Einladung wird verschickt",
                In_the_group_since: "In der Gruppe seit",
                Joined_the_group: "Der Gruppe beigetreten",
                Waiting_for_participant_to_join: "Wartet auf Teilnehmer",
                Increase_the_quota: "Erhöhung der Quote",
                Invite_a_participant: "Einen Teilnehmer einladen",
                Event_log: "Ereignisprotokoll",
                Participants: "Teilnehmer",
                Userset: "Sie können die Benutzereinstellungen in der App ändern",
                Whatisname: "Wie ist sein Name?",
                Created: "Die Gruppe wurde gegründet",
                UserInvited: "in die Gruppe eingeladen",
                UserJoined: "der Gruppe beigetreten",
                UserRejected: "die Einladung abgelehnt",
                UserSuspended1: "Die Mitgliedschaft von Benutzer",
                UserSuspended2: "wurde ausgesetzt",
                UserDeleted: "aus der Gruppe entfernt",
                GroupRenamed: "Die Gruppe wurde umbenannt",
                NewGroup : "Neue Gruppe",
                Odjava: "Abmeldung",
                Groups: "Meine Gruppe",
                Quotas: "Verwendung",
                Users: "Benutzer",
                About: "Über den Dienst",
                StatusModified:"Status changed",
                Taxes: "Taxes",
                Narocila: "Orders",
                Status: "Status",
                Employee: "Employee",
                Employees: "Employees",
                Accept: "Accept",
                Date: "Date",
                Amount: "Amount",
                Account: "Account",
                Balance: "Balance",
                Show_charts: "Show charts",
                Reports: "Reports",
                January: "January",
                February: "February",
                March: "March",
                April: "April",
                May: "May",
                June: "June",
                July: "July",
                August: "August",
                September: "September",
                October: "October",
                November: "November",
                December: "December",
                from: "from",
                Obvezno_polje: "Required field",
                Delete: "Delete",
                Delete_warning: "Are you sure delete this partner?",
                Delete_text :"Once deleted, you will no longer be able to select this partner as a customer or performer for orders",
                Yes: "Yes",
                No: "No",
                Delete_error_title: "Can\'t delete",
                Done: "Erledigt!",
                Kontaktna_oseba:"Contact Person",
                Telefon:"Phone",
                Faks:"Fax",
                BackToList : "Back to list",
                Predogled: "Preview",
                Preneseno: "Uoloaded",
                invite_message: "To register in the application, please enter the invitation code:",
                invite_mailto: "To receive an invitation code, please send a request to ",
                invite_error: "Alas, the code doesn't work",
                Davek_za_DDV: "VAT Amount",
                ID_za_DDV: "VAT ID",
                Hvala_za_zaupanje: "Thank you for your trust",
                Znesek_prosimo_plačajte_na: "Please pay the amount to",
                Ob_plačilu_navedite_referenco: "Please provide a reference when making payment",
                Skupaj : "Total",
                Dokument : 'Document',
                save_and_stay: "Save and continue editing",
                save_and_quit: "Save and go to list",
                agree_to_treat1 : "I agree to FinTim communicating its products and offers to me by email. I can withdraw my consent at any time.",
                agree_to_treat2 : "I agree that FinTim collects, stores and uses my personal data (for the full use of the various services provided by the site). I can withdraw my consent at any time. The privacy policy is available ",
                Close_this_info:"Close this info",
                Obračunavanje: "Billing",
                Seznam_narocil: "My orders",
                Ne_morem_shraniti: "Can't save",
                Status_spremembe: "Change status",
                Nazaj_na_seznam : "Back to list",
                Namigi : "Hints",
                Ni_voznikov: "No drivers",
                Register: "Register",
                Log_in : "Login",
                Password : "Password",
                repeat_Password : "Repeat password",
                Company_name: "Company name",
                Please_input_your_Username : "Please input your Username!",
                Please_input_your_Email: "Please input a valid email",
                Please_input_your_Password : "Please input your Password!",
                Please_input_your_Companyname : "Please input your company name!",
                Remember_me : "Remember me",
                Forgot_password : "Forgot password",
                Or: "Or ",
                register_now: "register now!",
                welcome : 'Welcome',
                Številka :'Number',
                Cena : 'Price',
                Plačila : 'Payements',
                Poglejte_vse : 'See all',
                Več : 'More',
                Izberite : 'Choose',
                Domov : 'Startseite',
                Vsa : 'Alle Gruppen',
                Aktivno: "Aktive",
                Ustavljeno: "Gestoppte",
                Arhivirano: "Archivierte",
                Prenesite_v_Excel :'Download Excel',
                Kliknite_za_razvrščanje_padajoče : 'Click to sort ascending',
                Kliknite_za_razvrščanje_naraščajoče : 'Click to sort descending',
                Prekliči_razvrščanje : 'Cancel sorting',
                Od_datum :'From date',
                Do_datum: 'To date',
                Išči: 'Suche..',
                Osnovni_podatki:'General info',
                Shraniti :'Speichern',
                Skrijte : 'Hide',
                Prenesite_dokument: 'Upload document',
                Naročilo :'Order',
                Nadaljevanje : 'Continue',
                Nazaj : 'Back',
                Koledar : 'Calendar',
                Po : 'Mon',
                To : 'Tue',
                Sr : 'Wed',
                Če : 'Thu',
                Pe : 'Fri',
                So : 'Sat',
                Ne : 'Sun',
                Ne_shranite_sprememb : 'Don\'t save',
                Ključni_kazalniki : 'Key indicators',
                Ime : 'Name',
                Priimek : 'Surname',
                Ime_datoteke : 'Filename',
                Vrsta : 'Type',
                Račun : 'Invoice',
                Predračun : 'Estimate',
                Nasprotna_stranka : 'Counterpart',
                Datum_dokumenta: 'Documents\'s date',
                Datum_plačila : 'Paymenet\'s date',
                Tiskanje : 'Print',
                Naziv_storitva_blaga : 'Product or service',
                Količina : 'Quantity',
                Davek : 'Taxe',
                Naziv : 'Titel',
                Davčna_Številka : 'Steuernummer',
                Matična_Številka : 'Company Id',
                Naslov : 'Anschrift',
                Nastavitve: "Einstellungen",
                Nastavitve_uporabnika : 'Einstellungen des Benutzers',
                Nastavitve_podjetja : 'Einstellungen des Unternehmens',
                Prenesite_sliko : 'Upload image',
                Prenesete_lahko_slike_velikosti_do : 'Please upload image not greater than',
                E_pošta : 'Email',
                Login : 'Login',
                Staro_geslo : 'Old password',
                Novo_geslo: 'New password',
                Prenesite_datoteko_ali_povlecite : 'Upload file or drag',
                in_spusti_na_označeno_območje : 'and drop it in this area',
                ne_več_kot : 'not greater than ',
                Pogodba : 'Agreemenent'

            }
        },
        ru : {
            translation: {
                Table_of_Contents: "Содержание",
                Group_management: "Управление группой",
                Group_creation: "Создание группы",
                Inviting_participants: "Приглашение участников",
                Member_statuses: "Статусы участников",
                Change_log: "Журнал изменений",
                Participants2: "Участники",
                Search_function: "Функция поиска",
                Activity_History: "История действий",
                Usage_and_payments: "Использование и оплаты",
                Customising_usage: "Настройка использования",
                Cost_calculation: "Расчет стоимости",
                Payment_history2: "История оплат",
                Settings: "Настройки",
                User_settings: "Настройки пользователя",
                Subscription_management: "Управление подпиской",
                text49: "В разделе \"Настройки\" Вы можете указать реквизиты юридического лица (если хотите использовать сервис как юридическое лицо), а также изменить параметры подписки, настроить способ оплаты или отменить подписку.\n",
                text50: "Консоль управления является дополнением к приложению OnMyDisk. Являясь пользователем OnMyDisk, Вы автоматически получаете доступ к консоли управления.",
                text51: "Поэтому все настройки пользователя необходимо изменять из приложения OnMyDisk. Чтобы изменить пароль или отображаемое имя пользователя, необходимо перейти в приложение OnMyDisk по ссылке.",
                text52: "Если Вы хотите, чтобы счета за использование сервиса выставлялись на юридическое лицо, укажите в настройках реквизиты Вашей компании.",
                text53: "Необходимо указать название компании, налоговый номер, адрес и телефон.",
                text54: "После сохранения этой информации все счета будут выставляться на имя юридического лица.",
                text55: "Для надежности и безопасности процесса оплат мы используем платформу Stripe.",
                text56: "Для изменения параметров пописки Вы можете перейти на специальный портал Stripe, где Вы можете изменить способ оплаты и другие параметры подписки.",
                text43: "Мы используем пропорциональную оплату нашего сервиса. Поскольку сервис OnMyDisc работает по принципу ежемесячной подписки, то оплата использования происходит ежемесячно.",
                text44: "Если Вам необходимо увеличить лимит в середине периода, то первая оплата будет рассчитана изходя из количества оставшихся дней до истечения Вашего периода подписки.",
                text45: "А уже со следующего периода оплата за увеличенный лимит будет взиматься в полном объеме",
                text46: "На вкладке \"История оплат\" можно найти все оплаты, которые Вы произвели.",
                text47: "Для надежности и безопасности процесса оплат мы используем платформу Stripe.",
                text48: "Нажав на ссылку \"Посмотреть счет\" (откроется в новом окне) Вы можете увидеть детальную информацию по каждой оплате, а так же скачать счет и квитанцию об оплате.",
                text31: "Перейдя на экран детализаци, Вы можете увидеть всю историю действий с участником.",
                text32: "В разделе \"Использование\" можно настроить лимиты по использованию системы. По умолчанию в рамках подписки у Вас есть возможность создать группу из не более чем 5 участников.",
                text33: "Так же в рамаках подписки у Вас есть возможность 20 раз использовать механизм электронной подписи для документов, которые Вы храните в приложении OnMyDisk.",
                text34: "При необходимости эти параметры можно изменить в данном разделе.",
                text35: "Чтобы увеличить количество участников в группе достаточно в секции с описанием текущих лимитов группы указать, сколько дополнительных участников Вам необходимо",
                text36: "Система посчитает сумму, которую необходимо будет внести для увеличения лимита.",
                text37: "Для увеличения лимита система автоматически спишет необходимую сумму используя Ваш метод оплаты, который Вы указали при создании группы.",
                text38: "После успешной оплаты система покажет сообщение и лимит группы будет увеличен.",
                text39: "Похожим образом можно увеличить и лимит подписания документов.",
                text40: "При необходимости, Вы можете увеличивать лимит пакетами по 5 документов. Чтобы увеличить лимит, укажите количество пакетов и система рассчитает необходимую сумму к оплате.",
                text41: "Для увеличения лимита система автоматически спишет необходимую сумму используя Ваш метод оплаты, который Вы указали при создании группы.",
                text42: "После успешной оплаты система покажет сообщение и лимит по документам будет увеличен.",
                text26: "В разделе \"Участники\" можно посмотреть список всех участников, которые когда либо были приглашены Вами в группу.",
                text27: "В списке отображаются даже те участники, которые которые были удвлены из группы.",
                text28: "Так же как и в разделе \"Моя группа\" про каждого пользователя в списке можно увидеть его Имя, адрес электронной почты и статус в группе.",
                text29: "По каждому участнику доступна детльная ифнормация - для этого необходимо нажать на строку с описанием учстника и система перенаправит Вас на экран детализации",
                text30: "Если участников много, то можно воспользоваться функцией поиска. Для этого начните вводить текст в строке поиска над таблицей. Система будет искать ввденный Вами текст по всей информации в списке (имя, электронный адрес)",
                text18: "Во вкладке \"Журнал изменений\" отображаются все действия и события, которые происходили с группой.",
                text19: "В журнале учитываются следующие события:",
                text20: "Создание группы",
                text21: "Изменение названия группы",
                text22: "Приглашение участника",
                text23: "Присоединение участника к группе",
                text24: "Приостановка членства участника",
                text25: "Удаление участника из группы",
                text12: "Каждый участник в группе может находиться в одном из трех статусов:\n",
                text13: "\"Ожидание подтверждения приглашения\"",
                text14: "\"Присоединился к группе\"",
                text15: "\"Участие в группе приостановлено\"",
                text16: "В любой момент Вы можете приостановить участие в группе любого из прглашенных. И так же в любой момент Вы можете вновь включить в группу любого из участников",
                text17: "Вы можете удалить участника из группы. В этом случае информация об участнике больше не юудет отображаться в списке на экране группы. Но Вы сможете найти его в списке всех пользователей в разделе \"Пользователи\"",
                text11: "Если такого пользователя еще нет в системе OnMyDisk, то мы отправим ему электронное письмо с приглашением начать использовать OnMyDisk и присоединиться к Вашей группе",
                text10: "Если такой пользователь существует, то он будет сразу добавлен в Вашу группу.",
                text9: "Когда информация будет сохранена, мы проверим, есть ли в системе OnMyDisk пользователь с указанным адресом email.",
                text8: "Вы можете так же указать имя участника, но это не обязательно",
                text7: "После нажатия, появится диалоговое окно, где необходимо указать адрес электронной почты участника, которого вы хотите пригласить\n",
                text6: "Чтобы добавить в группу нового участника, нажмите \"Пригласить участника\"\n",
                text5: "Вы можете приглашать в группу столько участников, сколько позволяет Ваш лимит. По умолчанию лимит группы составляет 5 участников. Это количество можно увеличить либо сразу при создании группы, либо в разделе \"Использование\"",
                text1: "Использоваение консоли управления начинается с создания группы. Каждый пользователь может создать только одну группу и пригласить в группу участников.",
                text2: "При первом входе в систему мы сразу предложим Вам создать группу. Для создания группы вам понадобиться придумать название группы и решить, сколько в ней будет участников.",
                text3: "Вы можете выбрать тариф (с ежемесечной или с годовой оплатой) и система рассчитает необходимую сумму к оплате.",
                text4: "После нажатия на кнопку \"Создать группу\" Вы будете перенаправлены на страницу оплаты. Когда оплата поступит, мы создадим для Вас группу и перенаправим Вас на страницу настроек группы, где Вы сможете пригласить участников",
                Payment_and_subscription_settings:"Настройки оплат и подписки",
                We_use_the_tried: "Мы используем проверенную и надежную платформу Stripe для обработки платежей.",
                If_you_would_like_to_change:"Если Вы хотите изменить настройки оплаты или отменить подписку - перейдите по ссылке на страницу настроек",
                Go_to_Payment_Settings: "Перейти к настройкам оплат и подписки",
                ofdocuments: "документов",
                Current_document_limit: "Текущий лимит документов (в месяц)",
                Used_as_part_of_subscription: "Использовано в рамках подписки",
                Available_until_the_end_of_the_period: "Доступно до конца периода",
                Additionally_used: "Дополнительно использовано",
                Charge_for_additional_use: "Плата за дополнительное использование в размере",
                will_be_charged:"будет списана ",
                To_save_money: "Чтобы сэкономить, оформите подписку на дополнительные пакеты документов. Каждый пакет содержит дополнительно 10 документов в месяц",
                Postal_code: "Почтовый индекс",
                Telephone: "Телефон",
                Additional_document_package:"Дополнительный пакет документов",
                Checking_the_payment:"Проверяем оплату..",
                This_could_take_a_while: "Это может занять некоторое время.",
                View_the_invoice: "Посмотреть счет",
                Additional_group_member:"Дополнительный участник группы",
                OnMyDisk_Subscription: "Подписка OnMyDisk",
                Subscription_fee: "Абонентская плата",
                Current_group_limit: "Текущий лимит группы",
                Set_limit: "Установить лимит",
                No_change: "Без изменений",
                No_need_to_pay_anything: "Ничего платить не надо",
                Additional_more: "Дополнительно еще",
                Additional_slots: "Дополнительные слоты",
                Thank_you_for_your_purchase: "Спасибо за покупку!",
                The_purchase_is_successful: "Покупка совершена успешно",
                A_confirmation_email_will_be_sent_to: "Подтверждение будет отправлено по электронной почте на адрес ",
                We_will_redirect_you_to_your: "Мы перенаправим Вас на страницу",
                group_page: "Вашей группы",
                If_you_have_any_questions_please_contact_our_Support_Team: "Если у вас возникли вопросы, пожалуйста, свяжитесь с нашей службой поддержки",
                Quota_setting:"Настройки использования",
                Payment_history: "История оплат",
                You_havent:"Вы еще не создали группу.",
                Lets_create:"Давайте создадим ее прямо сейчас!",
                Come_up:"Придумайте красивое название для Вашей группы",
                beautiful_name:"Красивое название группы",
                How_many_members: "Сколько будет участников?",
                Group_size_up: "Размер группы: до 5 участников",
                Signature_capacity_up: "Возможность подписи - до 20 документов",
                Additional_participants: "Дополнительные участники:",
                Included_in_the_price: "Включено в стоимость",
                Per_month: "За месяц",
                Per_year: "За год",
                Total_to_pay: "Итого к оплатe",
                Specify_a_group_name: "Укажите название группы",
                Create_a_group: "Создать группу",
                Cancel: "Отменить",
                Participant_info: "Информация об участнике",
                Invitation_sent: "Приглашение отправлено",
                In_the_group_since: "В группе с",
                Joined_the_group: "Присоединился к группе",
                Waiting_for_participant_to_join: "Ожидаем присоединения участника",
                Increase_the_quota: "Увеличить квоту",
                Invite_a_participant: "Пригласить участника",
                Event_log: "Журнал событий",
                Participants: "Участники",
                Userset: "Изменить настройки пользователя Вы можете в приложении",
                Whatisname: "Как его зовут?",
                Created: "Группа была создана",
                UserInvited: "приглашен в группу",
                UserJoined: "присоединился к группе",
                UserRejected: "отказался от приглашения",
                UserSuspended1: "Членство пользователя",
                UserSuspended2: "приостановлено",
                UserDeleted: "удален из группы",
                GroupRenamed: "Группа был переименована",
                NewGroup : "Новая группа",
                Odjava: "Выход",
                Groups: "Моя группа",
                Quotas: "Использование",
                Users: "Пользователи",
                About: "О сервисе",
                StatusModified:"Status changed",
                Taxes: "Taxes",
                Narocila: "Orders",
                Status: "Статус",
                Employee: "Employee",
                Employees: "Employees",
                Accept: "Accept",
                Date: "Date",
                Amount: "Amount",
                Account: "Account",
                Balance: "Balance",
                Show_charts: "Show charts",
                Reports: "Reports",
                January: "January",
                February: "February",
                March: "March",
                April: "April",
                May: "May",
                June: "June",
                July: "July",
                August: "August",
                September: "September",
                October: "October",
                November: "November",
                December: "December",
                from: "from",
                Obvezno_polje: "Required field",
                Delete: "Delete",
                Delete_warning: "Are you sure delete this partner?",
                Delete_text :"Once deleted, you will no longer be able to select this partner as a customer or performer for orders",
                Yes: "Yes",
                No: "No",
                Delete_error_title: "Can\'t delete",
                Done: "Готово!",
                Kontaktna_oseba:"Contact Person",
                Telefon:"Phone",
                Faks:"Fax",
                BackToList : "Back to list",
                Predogled: "Preview",
                Preneseno: "Uoloaded",
                invite_message: "To register in the application, please enter the invitation code:",
                invite_mailto: "To receive an invitation code, please send a request to ",
                invite_error: "Alas, the code doesn't work",
                Davek_za_DDV: "VAT Amount",
                ID_za_DDV: "VAT ID",
                Hvala_za_zaupanje: "Thank you for your trust",
                Znesek_prosimo_plačajte_na: "Please pay the amount to",
                Ob_plačilu_navedite_referenco: "Please provide a reference when making payment",
                Skupaj : "Total",
                Dokument : 'Document',
                save_and_stay: "Save and continue editing",
                save_and_quit: "Save and go to list",
                agree_to_treat1 : "I agree to FinTim communicating its products and offers to me by email. I can withdraw my consent at any time.",
                agree_to_treat2 : "I agree that FinTim collects, stores and uses my personal data (for the full use of the various services provided by the site). I can withdraw my consent at any time. The privacy policy is available ",
                Close_this_info:"Close this info",
                Obračunavanje: "Billing",
                Seznam_narocil: "My orders",
                Ne_morem_shraniti: "Can't save",
                Status_spremembe: "Change status",
                Nazaj_na_seznam : "Back to list",
                Namigi : "Hints",
                Ni_voznikov: "No drivers",
                Register: "Register",
                Log_in : "Login",
                Password : "Password",
                repeat_Password : "Repeat password",
                Company_name: "Company name",
                Please_input_your_Username : "Please input your Username!",
                Please_input_your_Email: "Please input a valid email",
                Please_input_your_Password : "Please input your Password!",
                Please_input_your_Companyname : "Please input your company name!",
                Remember_me : "Remember me",
                Forgot_password : "Forgot password",
                Or: "Or ",
                register_now: "register now!",
                welcome : 'Welcome',
                Številka :'Number',
                Cena : 'Price',
                Plačila : 'Payements',
                Poglejte_vse : 'See all',
                Več : 'More',
                Izberite : 'Choose',
                Domov : 'Главная',
                Vsa : 'Все группы',
                Aktivno: "Активные",
                Ustavljeno: "Остановленные",
                Arhivirano: "Архивные",
                Prenesite_v_Excel :'Download Excel',
                Kliknite_za_razvrščanje_padajoče : 'Click to sort ascending',
                Kliknite_za_razvrščanje_naraščajoče : 'Click to sort descending',
                Prekliči_razvrščanje : 'Cancel sorting',
                Od_datum :'From date',
                Do_datum: 'To date',
                Išči: 'Поиск..',
                Osnovni_podatki:'General info',
                Shraniti :'Сохранить',
                Skrijte : 'Hide',
                Prenesite_dokument: 'Upload document',
                Naročilo :'Order',
                Nadaljevanje : 'Continue',
                Nazaj : 'Back',
                Koledar : 'Calendar',
                Po : 'Mon',
                To : 'Tue',
                Sr : 'Wed',
                Če : 'Thu',
                Pe : 'Fri',
                So : 'Sat',
                Ne : 'Sun',
                Ne_shranite_sprememb : 'Don\'t save',
                Ključni_kazalniki : 'Key indicators',
                Ime : 'Имя',
                Priimek : 'Surname',
                Ime_datoteke : 'Filename',
                Vrsta : 'Type',
                Račun : 'Invoice',
                Predračun : 'Estimate',
                Nasprotna_stranka : 'Counterpart',
                Datum_dokumenta: 'Documents\'s date',
                Datum_plačila : 'Paymenet\'s date',
                Tiskanje : 'Print',
                Naziv_storitva_blaga : 'Product or service',
                Količina : 'Quantity',
                Davek : 'Taxe',
                Naziv : 'Название',
                Davčna_Številka : 'ИНН',
                Matična_Številka : 'Company Id',
                Naslov : 'Адрес',
                Nastavitve: "Настройки",
                Nastavitve_uporabnika : 'Настройки пользователя',
                Nastavitve_podjetja : 'Параметры компании',
                Prenesite_sliko : 'Upload image',
                Prenesete_lahko_slike_velikosti_do : 'Please upload image not greater than',
                E_pošta : 'Email',
                Login : 'Login',
                Staro_geslo : 'Old password',
                Novo_geslo: 'New password',
                Prenesite_datoteko_ali_povlecite : 'Upload file or drag',
                in_spusti_na_označeno_območje : 'and drop it in this area',
                ne_več_kot : 'not greater than ',
                Pogodba : 'Agreemenent'

            }
        },
        it:{
            translation: {
                Table_of_Contents: "Indice dei contenuti",
                Group_management: "Gestione dei gruppi",
                Group_creation: "Creazione del gruppo",
                Inviting_participants: "Invitare i partecipanti",
                Member_statuses: "Stati dei membri",
                Change_log: "Registro delle modifiche",
                Participants2: "Partecipanti",
                Search_function: "Funzione di ricerca",
                Activity_History: "Cronologia delle attività",
                Usage_and_payments: "Utilizzo e pagamenti",
                Customising_usage: "Personalizzazione dell'utilizzo",
                Cost_calculation: "Calcolo dei costi",
                Payment_history2: "Cronologia dei pagamenti",
                Settings: "Impostazioni",
                User_settings: "Impostazioni utente",
                Subscription_management: "Gestione degli abbonamenti",
                text49: "Nella sezione “Impostazioni” è possibile specificare i dettagli di una persona giuridica (se si desidera utilizzare il servizio come persona giuridica), nonché modificare i parametri dell'abbonamento, impostare il metodo di pagamento o annullare l'abbonamento.",
                text50: "La console di gestione è un'aggiunta all'applicazione OnMyDisk. Come utente di OnMyDisk, si ottiene automaticamente l'accesso alla console di gestione.",
                text51: "Pertanto, tutte le impostazioni dell'utente devono essere modificate dall'applicazione OnMyDisk. Per modificare la password o il nome utente, è necessario accedere all'applicazione OnMyDisk tramite il link.",
                text52: "Se si desidera che le fatture per l'utilizzo del servizio vengano emesse a una persona giuridica, specificare i dettagli della propria azienda nelle impostazioni.",
                text53: "È necessario specificare la ragione sociale, il codice fiscale, l'indirizzo e il numero di telefono.",
                text54: "Dopo aver salvato queste informazioni, tutte le fatture saranno emesse a nome della persona giuridica.",
                text55: "Per l'affidabilità e la sicurezza del processo di pagamento utilizziamo la piattaforma Stripe.",
                text56: "Per modificare i parametri dell'abbonamento è possibile accedere a un portale speciale di Stripe, dove è possibile modificare il metodo di pagamento e altri parametri dell'abbonamento.",
                text43: "Per il nostro servizio utilizziamo un pagamento proporzionale. Poiché il servizio OnMyDisc funziona secondo il principio dell'abbonamento mensile, il pagamento per l'utilizzo del servizio viene effettuato su base mensile.",
                text44: "Se è necessario aumentare il limite a metà del periodo, il primo pagamento sarà calcolato in base al numero di giorni rimanenti fino alla fine del periodo di abbonamento.",
                text45: "A partire dal periodo successivo, l'aumento del limite verrà addebitato per intero.",
                text46: "Nella scheda “Cronologia dei pagamenti” potete trovare tutti i pagamenti che avete effettuato.",
                text47: "Utilizziamo la piattaforma Stripe per rendere il processo di pagamento sicuro e protetto.",
                text48: "Facendo clic sul link “Visualizza fattura” (si apre una nuova finestra) è possibile visualizzare informazioni dettagliate su ciascun pagamento, nonché scaricare la fattura e la ricevuta di pagamento.",
                text31: "Accedendo alla schermata dei dettagli, è possibile vedere l'intera cronologia delle attività con il membro.",
                text32: "Nella sezione Utilizzo, è possibile impostare i limiti di utilizzo del sistema. Per impostazione predefinita, è possibile creare un gruppo di massimo 5 membri all'interno di un abbonamento.",
                text33: "noltre, nei riquadri dell'abbonamento si ha la possibilità di utilizzare il meccanismo di firma elettronica 20 volte per i documenti archiviati nell'applicazione OnMyDisk.",
                text34: "Se necessario, è possibile modificare questi parametri in questa sezione.",
                text35: "Per aumentare il numero di partecipanti al gruppo, è sufficiente specificare quanti partecipanti aggiuntivi sono necessari nella sezione che descrive i limiti attuali del gruppo.",
                text36: "Il sistema calcolerà l'importo necessario per aumentare il limite.",
                text37: "Per aumentare il limite, il sistema addebiterà automaticamente l'importo necessario utilizzando il metodo di pagamento specificato al momento della creazione del gruppo.",
                text38: "Dopo l'avvenuto pagamento, il sistema mostrerà un messaggio e il limite del gruppo sarà aumentato.",
                text39: "In modo analogo è possibile aumentare il limite di firma dei documenti.",
                text40: "Se necessario, è possibile aumentare il limite in pacchetti di 5 documenti. Per aumentare il limite, specificare il numero di pacchetti e il sistema calcolerà l'importo necessario da pagare.",
                text41: "Per aumentare il limite, il sistema addebiterà automaticamente l'importo necessario utilizzando il metodo di pagamento specificato al momento della creazione del gruppo.",
                text42: "Dopo l'avvenuto pagamento, il sistema mostrerà un messaggio e il limite di documenti sarà aumentato.",
                text26: "Nella sezione “Membri” è possibile visualizzare un elenco di tutti i membri che sono stati invitati nel gruppo.",
                text27: "Anche i membri che sono stati rimossi dal gruppo sono visualizzati nell'elenco.",
                text28: "Come nella sezione “Il mio gruppo”, è possibile vedere il nome, l'indirizzo e-mail e lo stato di ciascun membro del gruppo.",
                text29: "Per ogni membro sono disponibili informazioni dettagliate: fate clic sulla riga con la descrizione del membro e il sistema vi reindirizzerà alla schermata dei dettagli.",
                text30: "Se ci sono molti partecipanti, è possibile utilizzare la funzione di ricerca. A tal fine, iniziare a digitare il testo nella barra di ricerca sopra la tabella. Il sistema cercherà il testo inserito per tutte le informazioni dell'elenco (nome, indirizzo e-mail).",
                text18: "La scheda Registro modifiche visualizza tutte le azioni e gli eventi verificatisi con il gruppo.",
                text19: "Nel registro vengono registrati i seguenti eventi:",
                text20: "Creazione di un gruppo",
                text21: "Modifica del nome del gruppo",
                text22: "Invito di un membro",
                text23: "Aggiunta di un membro al gruppo",
                text24: "Sospensione di un membro",
                text25: "Rimozione di un membro dal gruppo",
                text12: "Ogni membro di un gruppo può trovarsi in uno dei tre stati:",
                text13: "“In attesa di conferma dell'invito”",
                text14: "“Iscritto al gruppo”",
                text15: "“La partecipazione al gruppo è sospesa”.",
                text16: "In qualsiasi momento è possibile sospendere la partecipazione al gruppo di uno qualsiasi degli invitati. Inoltre, in qualsiasi momento è possibile includere nuovamente nel gruppo uno qualsiasi dei partecipanti.",
                text17: "È possibile rimuovere un membro dal gruppo. In questo caso, le informazioni sul membro non saranno più visualizzate nell'elenco della schermata del gruppo. Ma sarà possibile trovarlo nell'elenco di tutti gli utenti nella sezione “Utenti”.",
                text11: "Se l'utente non è ancora presente nel sistema OnMyDisk, gli invieremo un'e-mail per invitarlo a iniziare a utilizzare OnMyDisk e a unirsi al vostro gruppo.",
                text10: "Se l'utente esiste, verrà immediatamente aggiunto al gruppo.",
                text9: "Una volta salvate le informazioni, controlleremo se nel sistema OnMyDisk esiste un utente con l'indirizzo e-mail specificato.\n",
                text8: "È possibile specificare anche il nome del membro, ma non è necessario.",
                text7: "Dopo aver fatto clic su questo pulsante, apparirà una finestra di dialogo in cui si dovrà specificare l'indirizzo e-mail del membro che si desidera invitare.",
                text6: "Per aggiungere un nuovo membro al gruppo, fare clic su “Invita membro”.",
                text5: "È possibile invitare a un gruppo il numero di membri consentito dal limite del gruppo. Il limite di gruppo predefinito è di 5 membri. È possibile aumentare questo numero immediatamente al momento della creazione del gruppo o nella sezione “Utilizzo”.",
                text1: "L'uso della Console di gestione inizia con la creazione di un gruppo. Ogni utente può creare un solo gruppo e invitare membri al gruppo.",
                text2: "Quando si accede per la prima volta, viene richiesto di creare un gruppo. Per creare un gruppo, è necessario assegnargli un nome e decidere il numero di membri che lo compongono.",
                text3: "È possibile scegliere una tariffa (con pagamento mensile o annuale) e il sistema calcolerà l'importo necessario da pagare.",
                text4: "Dopo aver fatto clic sul pulsante “Crea gruppo”, sarete reindirizzati alla pagina di pagamento. Una volta ricevuto il pagamento, creeremo un gruppo per voi e vi reindirizzeremo alla pagina delle impostazioni del gruppo, dove potrete invitare i partecipanti.",
                Payment_and_subscription_settings:"Pagamento e abbonamento",
                We_use_the_tried: "Per l'elaborazione dei pagamenti utilizziamo la collaudata e affidabile piattaforma Stripe.",
                If_you_would_like_to_change:"Se desiderate modificare le impostazioni di pagamento o annullare l'abbonamento, seguite il link alla pagina delle impostazioni.",
                Go_to_Payment_Settings: "Vai alle impostazioni di pagamento e abbonamento",
                ofdocuments: "documenti",
                Current_document_limit: "Limite di documenti attuali (al mese)",
                Used_as_part_of_subscription: "Utilizzato come parte dell'abbonamento",
                Available_until_the_end_of_the_period: "Disponibile fino alla fine del periodo",
                Additionally_used: "Utilizzo aggiuntivo",
                Charge_for_additional_use: "La tariffa per l'uso aggiuntivo di",
                will_be_charged:"verrà detratto ",
                To_save_money: "Per risparmiare, abbonatevi a pacchetti di documenti aggiuntivi. Ogni pacchetto contiene 10 documenti aggiuntivi al mese",
                Postal_code: "Codice postale",
                Telephone: "Telefono",
                Additional_document_package:"Pacchetto di documenti aggiuntivi",
                Checking_the_payment:"Verifica del pagamento..",
                This_could_take_a_while: "Questa operazione può richiedere un po' di tempo.",
                View_the_invoice: "Visualizza la fattura",
                Additional_group_member:"Membro aggiuntivo del gruppo",
                OnMyDisk_Subscription: "Abbonamento OnMyDisk",
                Subscription_fee: "Costo dell\'abbonamento",
                Current_group_limit: "Limite attuale del gruppo",
                Set_limit: "Limite impostato",
                No_change: "Nessuna modifica",
                No_need_to_pay_anything: "Niente da pagare",
                Additional_more: "Ulteriori slot",
                Additional_slots: "Slot aggiuntivi",
                Thank_you_for_your_purchase: "Grazie per l'acquisto!",
                The_purchase_is_successful: "L'acquisto è andato a buon fine",
                A_confirmation_email_will_be_sent_to: "Verrà inviata un'e-mail di conferma a ",
                We_will_redirect_you_to_your: "Verrete reindirizzati alla vostra",
                group_page: "pagina del gruppo",
                If_you_have_any_questions_please_contact_our_Support_Team: "Se avete domande, contattate il nostro team di assistenza.",
                Quota_setting:"Impostazioni d\'uso",
                Payment_history: "Cronologia dei pagamenti",
                You_havent:"Non avete ancora creato un gruppo.",
                Lets_create:"Creiamolo subito!",
                Come_up:"Trovate un bel nome per il vostro gruppo",
                beautiful_name:"Un bel nome per il vostro gruppo",
                How_many_members: "Quanti membri ci saranno?",
                Group_size_up: "Dimensione del gruppo: fino a 5 partecipanti",
                Signature_capacity_up: "Possibilità di firmare fino a 20 documenti",
                Additional_participants: "Partecipanti aggiuntivi:",
                Included_in_the_price: "Incluso nel prezzo",
                Per_month: "Al mese",
                Per_year: "All\'anno",
                Total_to_pay: "Totale da pagare",
                Specify_a_group_name: "Inserire il nome di un gruppo",
                Create_a_group: "Creare un gruppo",
                Cancel: "Annullamento",
                Participant_info: "Info sui partecipanti",
                Invitation_sent: "L\'invito viene inviato",
                In_the_group_since: "Nel gruppo dal",
                Joined_the_group: "Si è unito al gruppo",
                Waiting_for_participant_to_join: "In attesa dell'adesione di un partecipante",
                Increase_the_quota: "Aumentare la quota",
                Invite_a_participant: "Invitare un partecipante",
                Event_log: "Registro eventi",
                Participants: "Partecipanti",
                Userset: "È possibile modificare le impostazioni dell\'utente nell\'app",
                Whatisname: "Come si chiama?",
                Created: "Il gruppo è stato creato",
                UserInvited: "invitato al gruppo",
                UserJoined: "si è unito al gruppo",
                UserRejected: "ha rifiutato l'invito",
                UserSuspended1: "L\'iscrizione dell\'utente",
                UserSuspended2: "è stata sospesa",
                UserDeleted: "rimosso dal gruppo",
                GroupRenamed: "Il gruppo è stato rinominato",
                NewGroup : "Nuovo Gruppo",
                Odjava: "Disconnessione",
                Groups: "Il mio gruppo",
                Quotas: "Utilizzo",
                Users: "Utenti",
                About: "Informazioni",
                StatusModified:"Status changed",
                Taxes: "Taxes",
                Narocila: "Orders",
                Status: "Stato",
                Employee: "Employee",
                Employees: "Employees",
                Accept: "Accept",
                Date: "Date",
                Amount: "Amount",
                Account: "Account",
                Balance: "Balance",
                Show_charts: "Show charts",
                Reports: "Reports",
                January: "January",
                February: "February",
                March: "March",
                April: "April",
                May: "May",
                June: "June",
                July: "July",
                August: "August",
                September: "September",
                October: "October",
                November: "November",
                December: "December",
                from: "from",
                Obvezno_polje: "Required field",
                Delete: "Delete",
                Delete_warning: "Are you sure delete this partner?",
                Delete_text :"Once deleted, you will no longer be able to select this partner as a customer or performer for orders",
                Yes: "Yes",
                No: "No",
                Delete_error_title: "Can\'t delete",
                Done: "Fatto!",
                Kontaktna_oseba:"Contact Person",
                Telefon:"Phone",
                Faks:"Fax",
                BackToList : "Back to list",
                Predogled: "Preview",
                Preneseno: "Uoloaded",
                invite_message: "To register in the application, please enter the invitation code:",
                invite_mailto: "To receive an invitation code, please send a request to ",
                invite_error: "Alas, the code doesn't work",
                Davek_za_DDV: "VAT Amount",
                ID_za_DDV: "VAT ID",
                Hvala_za_zaupanje: "Thank you for your trust",
                Znesek_prosimo_plačajte_na: "Please pay the amount to",
                Ob_plačilu_navedite_referenco: "Please provide a reference when making payment",
                Skupaj : "Total",
                Dokument : 'Document',
                save_and_stay: "Save and continue editing",
                save_and_quit: "Save and go to list",
                agree_to_treat1 : "I agree to FinTim communicating its products and offers to me by email. I can withdraw my consent at any time.",
                agree_to_treat2 : "I agree that FinTim collects, stores and uses my personal data (for the full use of the various services provided by the site). I can withdraw my consent at any time. The privacy policy is available ",
                Close_this_info:"Close this info",
                Obračunavanje: "Billing",
                Seznam_narocil: "My orders",
                Ne_morem_shraniti: "Can't save",
                Status_spremembe: "Change status",
                Nazaj_na_seznam : "Back to list",
                Namigi : "Hints",
                Ni_voznikov: "No drivers",
                Register: "Register",
                Log_in : "Login",
                Password : "Password",
                repeat_Password : "Repeat password",
                Company_name: "Company name",
                Please_input_your_Username : "Please input your Username!",
                Please_input_your_Email: "Please input a valid email",
                Please_input_your_Password : "Please input your Password!",
                Please_input_your_Companyname : "Please input your company name!",
                Remember_me : "Remember me",
                Forgot_password : "Forgot password",
                Or: "Or ",
                register_now: "register now!",
                welcome : 'Welcome',
                Številka :'Number',
                Cena : 'Price',
                Plačila : 'Payements',
                Poglejte_vse : 'See all',
                Več : 'More',
                Izberite : 'Choose',
                Domov : 'Casa',
                Vsa : 'Tutti i gruppi',
                Aktivno: "Attivi",
                Ustavljeno: "Interrotti",
                Arhivirano: "Archiviati",
                Prenesite_v_Excel :'Download Excel',
                Kliknite_za_razvrščanje_padajoče : 'Click to sort ascending',
                Kliknite_za_razvrščanje_naraščajoče : 'Click to sort descending',
                Prekliči_razvrščanje : 'Cancel sorting',
                Od_datum :'From date',
                Do_datum: 'To date',
                Išči: 'Ricerca..',
                Osnovni_podatki:'General info',
                Shraniti :'Risparmiare ',
                Skrijte : 'Hide',
                Prenesite_dokument: 'Upload document',
                Naročilo :'Order',
                Nadaljevanje : 'Continue',
                Nazaj : 'Back',
                Koledar : 'Calendar',
                Po : 'Mon',
                To : 'Tue',
                Sr : 'Wed',
                Če : 'Thu',
                Pe : 'Fri',
                So : 'Sat',
                Ne : 'Sun',
                Ne_shranite_sprememb : 'Don\'t save',
                Ključni_kazalniki : 'Key indicators',
                Ime : 'Nome',
                Priimek : 'Surname',
                Ime_datoteke : 'Filename',
                Vrsta : 'Type',
                Račun : 'Invoice',
                Predračun : 'Estimate',
                Nasprotna_stranka : 'Counterpart',
                Datum_dokumenta: 'Documents\'s date',
                Datum_plačila : 'Paymenet\'s date',
                Tiskanje : 'Print',
                Naziv_storitva_blaga : 'Product or service',
                Količina : 'Quantity',
                Davek : 'Taxe',
                Naziv : 'Titolo',
                Davčna_Številka : 'Codice fiscale',
                Matična_Številka : 'Company Id',
                Naslov : 'Indirizzo',
                Nastavitve: "Impostazioni",
                Nastavitve_uporabnika : 'Impostazioni dell\'utente',
                Nastavitve_podjetja : 'Impostazioni aziendali',
                Prenesite_sliko : 'Upload image',
                Prenesete_lahko_slike_velikosti_do : 'Please upload image not greater than',
                E_pošta : 'Email',
                Login : 'Login',
                Staro_geslo : 'Old password',
                Novo_geslo: 'New password',
                Prenesite_datoteko_ali_povlecite : 'Upload file or drag',
                in_spusti_na_označeno_območje : 'and drop it in this area',
                ne_več_kot : 'not greater than ',
                Pogodba : 'Agreemenent'

            }
        },
        uk:{
            translation: {
                Table_of_Contents: "Зміст",
                Group_management: "Керування групою",
                Group_creation: "Створення групи",
                Inviting_participants: "Запрошення учасників",
                Member_statuses: "Статуси учасників",
                Change_log: "Журнал змін",
                Participants2: "Учасники",
                Search_function: "Функція пошуку",
                Activity_History: "Історія дій",
                Usage_and_payments: "Використання та оплати",
                Customising_usage: "Налаштування використання",
                Cost_calculation: "Розрахунок вартості",
                Payment_history2: "Історія оплат",
                Settings: "Налаштування",
                User_settings: "Налаштування користувача",
                Subscription_management: "Керування підпискою",
                text49: "У розділі «Налаштування» Ви можете вказати реквізити юридичної особи (якщо хочете використовувати сервіс як юридичну особу), а також змінити параметри підписки, налаштувати спосіб оплати або скасувати підписку.",
                text50: "Консоль управління є доповненням до додатка OnMyDisk. Будучи користувачем OnMyDisk, Ви автоматично отримуєте доступ до консолі управління.",
                text51: "Тому всі налаштування користувача необхідно змінювати з програми OnMyDisk. Щоб змінити пароль або відображуване ім'я користувача, необхідно перейти в додаток OnMyDisk за посиланням.",
                text52: "Якщо Ви хочете, щоб рахунки за використання сервісу виставлялися на юридичну особу, вкажіть у налаштуваннях реквізити Вашої компанії.",
                text53: "Необхідно вказати назву компанії, податковий номер, адресу та телефон.",
                text54: "Після збереження цієї інформації всі рахунки будуть виставлятися на ім'я юридичної особи.",
                text55: "Для надійності та безпеки процесу оплат ми використовуємо платформу Stripe.",
                text56: "Для зміни параметрів підписки Ви можете перейти на спеціальний портал Stripe, де Ви можете змінити спосіб оплати та інші параметри підписки.",
                text43: "Ми використовуємо пропорційну оплату нашого сервісу. Оскільки сервіс OnMyDisc працює за принципом щомісячної підписки, то оплата використання відбувається щомісяця.",
                text44: "Якщо Вам необхідно збільшити ліміт в середині періоду, то перша оплата буде розрахована виходячи з кількості днів, що залишилися до закінчення Вашого періоду підписки.",
                text45: "А вже з наступного періоду оплата за збільшений ліміт буде стягуватися в повному обсязі",
                text46: "На вкладці «Історія оплат» можна знайти всі оплати, які Ви здійснили.",
                text47: "Для надійності та безпеки процесу оплат ми використовуємо платформу Stripe.",
                text48: "Натиснувши на посилання «Подивитися рахунок» (відкриється в новому вікні) Ви можете побачити детальну інформацію по кожній оплаті, а також завантажити рахунок і квитанцію про оплату.",
                text31: "Перейшовши на екран деталізації, Ви можете побачити всю історію дій з учасником.",
                text32: "У розділі «Використання» можна налаштувати ліміти по використанню системи. За замовчуванням у рамках підписки у Вас є можливість створити групу з не більше ніж 5 учасників.",
                text33: "Так само в рамках підписки у Вас є можливість 20 разів використовувати механізм електронного підпису для документів, які Ви зберігаєте в додатку OnMyDisk.",
                text34: "За необхідності ці параметри можна змінити в цьому розділі.",
                text35: "Щоб збільшити кількість учасників у групі, достатньо в секції з описом поточних лімітів групи вказати, скільки додаткових учасників Вам необхідно.",
                text36: "Система порахує суму, яку необхідно буде внести для збільшення ліміту.",
                text37: "Для збільшення ліміту система автоматично спише необхідну суму використовуючи Ваш метод оплати, який Ви вказали при створенні групи.",
                text38: "Після успішної оплати система покаже повідомлення і ліміт групи буде збільшено.",
                text39: "Схожим чином можна збільшити і ліміт підписання документів.",
                text40: "За необхідності, Ви можете збільшувати ліміт пакетами по 5 документів. Щоб збільшити ліміт, вкажіть кількість пакетів і система розрахує необхідну суму до оплати.",
                text41: "Для збільшення ліміту система автоматично спише необхідну суму, використовуючи Ваш метод оплати, який Ви вказали при створенні групи.",
                text42: "Після успішної оплати система покаже повідомлення і ліміт за документами буде збільшено.",
                text26: "У розділі «Учасники» можна подивитися список усіх учасників, які коли-небудь були запрошені Вами в групу.",
                text27: "У списку відображаються навіть ті учасники, які були видалені з групи.",
                text28: "Так само, як і в розділі «Моя група», про кожного користувача в списку можна побачити його Ім'я, адресу електронної пошти та статус у групі.",
                text29: "По кожному учаснику доступна детальна інформація - для цього необхідно натиснути на рядок з описом учасника і система перенаправить Вас на екран деталізації.",
                text30: "Якщо учасників багато, то можна скористатися функцією пошуку. Для цього почніть вводити текст у рядку пошуку над таблицею. Система буде шукати ввденний Вами текст за всією інформацією в списку (ім'я, електронна адреса)",
                text18: "У вкладці «Журнал змін» відображаються всі дії та події, які відбувалися з групою.",
                text19: "У журналі враховуються такі події:",
                text20: "Створення групи",
                text21: "Зміна назви групи",
                text22: "Запрошення учасника",
                text23: "Приєднання учасника до групи",
                text24: "Призупинення членства учасника",
                text25: "Видалення учасника з групи",
                text12: "Кожен учасник у групі може перебувати в одному з трьох статусів:",
                text13: "«Очікування підтвердження запрошення»",
                text14: "«Приєднався до групи»",
                text15: "«Участь у групі призупинено»",
                text16: "У будь-який момент Ви можете призупинити участь у групі будь-якого із запрошених. І так само в будь-який момент Ви можете знову включити в групу будь-якого з учасників",
                text17: "Ви можете видалити учасника з групи. У цьому разі інформація про учасника більше не відображатиметься у списку на екрані групи. Але Ви зможете знайти його в списку всіх користувачів у розділі «Користувачі»",
                text11: "Якщо такого користувача ще немає в системі OnMyDisk, то ми надішлемо йому електронного листа із запрошенням почати використовувати OnMyDisk і приєднатися до Вашої групи",
                text10: "Якщо такий користувач існує, то його буде одразу додано до Вашої групи.",
                text9: "Коли інформація буде збережена, ми перевіримо, чи є в системі OnMyDisk користувач із зазначеною адресою email.",
                text8: "Ви можете так само вказати ім'я учасника, але це не обов'язково",
                text7: "Після натискання з'явиться діалогове вікно, де необхідно вказати адресу електронної пошти учасника, якого ви хочете запросити",
                text6: "Щоб додати в групу нового учасника, натисніть «Запросити учасника»",
                text5: "Ви можете запрошувати в групу стільки учасників, скільки дозволяє Ваш ліміт. За замовчуванням ліміт групи становить 5 учасників. Цю кількість можна збільшити або відразу під час створення групи, або в розділі «Використання»",
                text1: "Використання консолі керування починається зі створення групи. Кожен користувач може створити тільки одну групу і запросити в групу учасників.",
                text2: "Під час першого входу в систему ми відразу запропонуємо вам створити групу. Для створення групи вам знадобитися придумати назву групи і вирішити, скільки в ній буде учасників.",
                text3: "Ви можете вибрати тариф (з щомісячною або з річною оплатою) і система розрахує необхідну суму до оплати.",
                text4: "Після натискання на кнопку «Створити групу» Ви будете перенаправлені на сторінку оплати. Коли оплата надійде, ми створимо для Вас групу і перенаправимо Вас на сторінку налаштувань групи, де Ви зможете запросити учасників",
                Payment_and_subscription_settings:"Налаштування оплат і підписки",
                We_use_the_tried: "Ми використовуємо перевірену та надійну платформу Stripe для обробки платежів.",
                If_you_would_like_to_change:"Якщо Ви хочете змінити налаштування оплати або скасувати підписку - перейдіть за посиланням на сторінку налаштувань",
                Go_to_Payment_Settings: "Перейти до налаштувань оплат і підписки",
                ofdocuments: "документів",
                Current_document_limit: "Поточний ліміт документів (на місяць)",
                Used_as_part_of_subscription: "Використано в рамках підписки",
                Available_until_the_end_of_the_period: "Доступно до кінця періоду",
                Additionally_used: "Додатково використано",
                Charge_for_additional_use: "Плата за додаткове використання в розмірі",
                will_be_charged:"буде списано ",
                To_save_money: "Щоб заощадити, оформіть передплату на додаткові пакети документів. Кожен пакет містить додатково 10 документів на місяць",
                Postal_code: "Поштовий індекс",
                Telephone: "Телефон",
                Additional_document_package:"Додатковий пакет документів",
                Checking_the_payment:"Перевіряємо оплату..",
                This_could_take_a_while: "Це може зайняти деякий час.",
                View_the_invoice: "Подивитися рахунок",
                Additional_group_member:"Додатковий учасник групи",
                OnMyDisk_Subscription: "Підписка OnMyDisk",
                Subscription_fee: "Абонентська плата",
                Current_group_limit: "Поточний ліміт групи",
                Set_limit: "Встановити ліміт",
                No_change: "Без змін",
                No_need_to_pay_anything: "Нічого платити не треба",
                Additional_more: "Додатково ще",
                Additional_slots: "Додаткові слоти",
                Thank_you_for_your_purchase: "Дякуємо за покупку!",
                The_purchase_is_successful: "Покупка успішна",
                A_confirmation_email_will_be_sent_to: "Підтвердження буде надіслано на електронну пошту ",
                We_will_redirect_you_to_your: "Ми перенаправимо вас на вашу",
                group_page: "сторінку групи",
                If_you_have_any_questions_please_contact_our_Support_Team: "Якщо у вас виникли запитання, будь ласка, зверніться до нашої служби підтримки",
                Quota_setting:"Налаштування використання",
                Payment_history: "Історія оплат",
                You_havent:"Ви ще не створили групу.",
                Lets_create:"Давайте створимо її просто зараз!",
                Come_up:"Придумайте гарну назву для Вашої групи",
                beautiful_name:"Красива назва групи",
                How_many_members: "Скільки буде учасників?",
                Group_size_up: "Розмір групи: до 5 учасників",
                Signature_capacity_up: "Можливість підпису - до 20 документів",
                Additional_participants: "Додаткові учасники:",
                Included_in_the_price: "Включено у вартість",
                Per_month: "За місяць",
                Per_year: "За рік",
                Total_to_pay: "Разом до оплати",
                Specify_a_group_name: "Вкажіть назву групи",
                Create_a_group: "Створити групу",
                Cancel: "Скасувати",
                Participant_info: "Інформація про учасника",
                Invitation_sent: "Запрошення відправлено",
                In_the_group_since: "У групі з моменту",
                Joined_the_group: "Приєднався до групи",
                Waiting_for_participant_to_join: "Очікуємо на приєднання учасника",
                Increase_the_quota: "Збільшити квоту",
                Invite_a_participant: "Запросити учасника",
                Event_log: "Журнал подій",
                Participants: "Учасники",
                Userset: "Змінити налаштування користувача Ви можете в додатку",
                Whatisname: "Як його звуть?",
                Created: "Групу було створено",
                UserInvited: "запрошений до групи",
                UserJoined: "приєднався до групи",
                UserRejected: "відмовився від запрошення",
                UserSuspended1: "Членство користувача",
                UserSuspended2: "призупинено",
                UserDeleted: "видалено з групи",
                GroupRenamed: "Групу було перейменовано",
                NewGroup : "Нова група",
                Odjava: "Вийти з системи",
                Groups: "Моя група",
                Quotas: "Використання",
                Users: "Користувачі",
                About: "Про сервіс",
                StatusModified:"Status changed",
                Taxes: "Taxes",
                Narocila: "Orders",
                Status: "Статус",
                Employee: "Employee",
                Employees: "Employees",
                Accept: "Accept",
                Date: "Date",
                Amount: "Amount",
                Account: "Account",
                Balance: "Balance",
                Show_charts: "Show charts",
                Reports: "Reports",
                January: "January",
                February: "February",
                March: "March",
                April: "April",
                May: "May",
                June: "June",
                July: "July",
                August: "August",
                September: "September",
                October: "October",
                November: "November",
                December: "December",
                from: "from",
                Obvezno_polje: "Required field",
                Delete: "Delete",
                Delete_warning: "Are you sure delete this partner?",
                Delete_text :"Once deleted, you will no longer be able to select this partner as a customer or performer for orders",
                Yes: "Yes",
                No: "No",
                Delete_error_title: "Can\'t delete",
                Done: "Зроблено!",
                Kontaktna_oseba:"Contact Person",
                Telefon:"Phone",
                Faks:"Fax",
                BackToList : "Back to list",
                Predogled: "Preview",
                Preneseno: "Uoloaded",
                invite_message: "To register in the application, please enter the invitation code:",
                invite_mailto: "To receive an invitation code, please send a request to ",
                invite_error: "Alas, the code doesn't work",
                Davek_za_DDV: "VAT Amount",
                ID_za_DDV: "VAT ID",
                Hvala_za_zaupanje: "Thank you for your trust",
                Znesek_prosimo_plačajte_na: "Please pay the amount to",
                Ob_plačilu_navedite_referenco: "Please provide a reference when making payment",
                Skupaj : "Total",
                Dokument : 'Document',
                save_and_stay: "Save and continue editing",
                save_and_quit: "Save and go to list",
                agree_to_treat1 : "I agree to FinTim communicating its products and offers to me by email. I can withdraw my consent at any time.",
                agree_to_treat2 : "I agree that FinTim collects, stores and uses my personal data (for the full use of the various services provided by the site). I can withdraw my consent at any time. The privacy policy is available ",
                Close_this_info:"Close this info",
                Obračunavanje: "Billing",
                Seznam_narocil: "My orders",
                Ne_morem_shraniti: "Can't save",
                Status_spremembe: "Change status",
                Nazaj_na_seznam : "Back to list",
                Namigi : "Hints",
                Ni_voznikov: "No drivers",
                Register: "Register",
                Log_in : "Login",
                Password : "Password",
                repeat_Password : "Repeat password",
                Company_name: "Company name",
                Please_input_your_Username : "Please input your Username!",
                Please_input_your_Email: "Please input a valid email",
                Please_input_your_Password : "Please input your Password!",
                Please_input_your_Companyname : "Please input your company name!",
                Remember_me : "Remember me",
                Forgot_password : "Forgot password",
                Or: "Or ",
                register_now: "register now!",
                welcome : 'Welcome',
                Številka :'Number',
                Cena : 'Price',
                Plačila : 'Payements',
                Poglejte_vse : 'See all',
                Več : 'More',
                Izberite : 'Choose',
                Domov : 'Головна',
                Vsa : 'Усі групи',
                Aktivno: "Активні",
                Ustavljeno: "Зупинені",
                Arhivirano: "Архівні",
                Prenesite_v_Excel :'Download Excel',
                Kliknite_za_razvrščanje_padajoče : 'Click to sort ascending',
                Kliknite_za_razvrščanje_naraščajoče : 'Click to sort descending',
                Prekliči_razvrščanje : 'Cancel sorting',
                Od_datum :'From date',
                Do_datum: 'To date',
                Išči: 'Пошук..',
                Osnovni_podatki:'General info',
                Shraniti :'Сохранить',
                Skrijte : 'Hide',
                Prenesite_dokument: 'Upload document',
                Naročilo :'Order',
                Nadaljevanje : 'Continue',
                Nazaj : 'Back',
                Koledar : 'Calendar',
                Po : 'Mon',
                To : 'Tue',
                Sr : 'Wed',
                Če : 'Thu',
                Pe : 'Fri',
                So : 'Sat',
                Ne : 'Sun',
                Ne_shranite_sprememb : 'Don\'t save',
                Ključni_kazalniki : 'Key indicators',
                Ime : 'Ім\'я',
                Priimek : 'Surname',
                Ime_datoteke : 'Filename',
                Vrsta : 'Type',
                Račun : 'Invoice',
                Predračun : 'Estimate',
                Nasprotna_stranka : 'Counterpart',
                Datum_dokumenta: 'Documents\'s date',
                Datum_plačila : 'Paymenet\'s date',
                Tiskanje : 'Print',
                Naziv_storitva_blaga : 'Product or service',
                Količina : 'Quantity',
                Davek : 'Taxe',
                Naziv : 'Назва',
                Davčna_Številka : 'ПИК',
                Matična_Številka : 'Company Id',
                Naslov : 'Адреса',
                Nastavitve: "Налаштування",
                Nastavitve_uporabnika : 'Налаштування користувача',
                Nastavitve_podjetja : 'Налаштування компанії',
                Prenesite_sliko : 'Upload image',
                Prenesete_lahko_slike_velikosti_do : 'Please upload image not greater than',
                E_pošta : 'Email',
                Login : 'Login',
                Staro_geslo : 'Old password',
                Novo_geslo: 'New password',
                Prenesite_datoteko_ali_povlecite : 'Upload file or drag',
                in_spusti_na_označeno_območje : 'and drop it in this area',
                ne_več_kot : 'not greater than ',
                Pogodba : 'Agreemenent'

            }
        },

    },
    lng: 'en',
    debug: false,
})

export default i18n;