import {Avatar, Button, Col, ConfigProvider, Divider, Input, Modal, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table";
import GroupsUsers from "../../../types/GroupsUsers";
import {ServerdateToPoints} from "../../../utils/helpers";
import {CheckCircleOutlined, EditOutlined, PauseCircleOutlined,
    PauseOutlined, CloseOutlined, DeleteOutlined, ExclamationCircleFilled,
    CloseCircleOutlined, PlayCircleOutlined,  HourglassOutlined, MailOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import axios from "axios/index";
import {DATA_URL} from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import InviteForm from "./InviteForm";
import {unsubscribe, subscribe, publish} from "../../../Components/Notifications/NotificationContext";
import OmdGroup from "../../../types/Group";




export default function MembersTable(){

    const {t} = useTranslation();

    const [theGroup, setTheGroup] = useState<OmdGroup|null>(null)

    const[members, setMembers] = useState<GroupsUsers[]|null>(null)
    const[isPageLoading, setisPageLoading]=useState(false);
    const [filter, setFilter]=useState("")

    const [open, setOpen] = useState(false);
    
    const[selectedMember, setSelectedMember]=useState<GroupsUsers|null>(null)
    
    const[maxExceeded, setmaxExceeded] = useState(false)



    const history = useHistory();

    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }


    const reloadMembers=()=>{
        setisPageLoading(true)
        //console.log("reloading members")
        setMembers([]);
        axios.get(DATA_URL+"GroupsUsers", {headers:authHeader()})
            .then((response=>{
                setisPageLoading(false)
                setMembers(response.data);
                console.log(response.data)

            }))
            .catch(function (error) {
                if(error.response.status===401){
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();

                }
            })

    }
    
    if(members==null){
        reloadMembers()
    }

    useEffect(()=>{

        if(theGroup===null){
            setisPageLoading(true)
            axios.get(DATA_URL+"Groups/get-my-group", {headers:authHeader()})
                .then((response=>{
                    if(response.data.length!=0){
                        setisPageLoading(false)
                        setTheGroup(response.data);
                        if(members?.length===response.data.maxSize)
                        setmaxExceeded(true)
                    }

                }))
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
        }


        subscribe("userJoined", (data: any) => reloadMembers());
        
        return () => {
            unsubscribe("userJoined", (data: any) => reloadMembers());
        }
    })


    const { confirm } = Modal;
    const columns: ColumnsType<GroupsUsers> = [
            {
                title: t('Date'),
                dataIndex: 'dealDate',
                
                filteredValue :[filter? filter : ""],
                onFilter: (value, record) => {
                    //console.log("value: ", value)
                    return (
                        String(record.invitationEmail).toLowerCase().includes(value.toString().toLowerCase())||
                        String(record.memberName).toLowerCase().includes(value.toString().toLowerCase())
                    )},



                    render: (text, record) => {
                    var color= "#0c5e9b"//'#'+(0x1000000+Math.random()*0xffffff).toString(16).substring(0,6)
                    return (

                        <Row gutter={10} align={"middle"}>


                            <Col>
                                <Avatar
                                    size="large"
                                    style={{
                                        backgroundColor: "#fff",
                                        borderWidth:"3px",
                                        color: color,
                                        borderColor: color
                                        //'#'+((0x1000000+Math.random()*0xffffff).toString(16).substring(1,6))
                                        //'#'+(0x1000000+Math.random()*0xffffff).toString(16).substring(1,6)
                                    }}
                                ><b>{record.invitationEmail?.substring(0,1).toUpperCase() }</b></Avatar>
                            </Col>

                            <Col span={18}>
                                <Row>
                                    <Col>
                                        <b>{record.memberName? <span>{record.memberName+" ("+record.invitationEmail+")"}</span> : record.invitationEmail }</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{color: "#777"}}>
                                        {record.memeberStatus===1 ? <span>{t("Invitation_sent")} {ServerdateToPoints(record.invitedAt)}</span> : ""}
                                        {record.memeberStatus===2 ? <span>  {t("In_the_group_since")}   {ServerdateToPoints(record.joinedAt)}</span> : ""}
                                        
                                    </Col>
                                </Row>
                            </Col>
                            {/* 
                            <Col>
                                {record.memeberStatus==2 ?
                                    <span style={{fontSize: '14px', color: "#4caf50"}}><CheckCircleOutlined /> Joined the group</span>:
                                    <HourglassOutlined style={{fontSize: '14px', color: "#ffc107"}} /> }

                            </Col>
                            */}
                        </Row>


                    )}
            },
            {
                title: '',
                dataIndex: 'id',
                render: (text, record)=>{
                    return(
                        <>
                            {record.memeberStatus===2 ?
                                <span style={{fontSize: '14px', color: "#4caf50"}}><CheckCircleOutlined /> {t("Joined_the_group")}</span>: ""}
                            {record.memeberStatus===1 ? 
                                <span style={{fontSize: '14px', color: "#faad14"}}><HourglassOutlined /> {t("Waiting_for_participant_to_join")}</span>: ""  }

                            {record.memeberStatus===3 ?
                                <span style={{fontSize: '14px', color: "#777"}}> {record.invitationEmail+" "+t("UserRejected")}</span>: ""}

                            {record.memeberStatus===4 ?
                                <span style={{fontSize: '14px', color: "#faad14"}}> <PauseCircleOutlined /> {t("UserSuspended1")+" "+t("UserSuspended2")}</span>: ""}


                        </>
                    )}

            },

            {
                title: '',
                dataIndex: 'id',
                width: 130,
                render: (text, record)=>{
                    return(<span 
                        style={{color: "#0c5e9b", borderBottom: "1px dashed #0c5e9b", 
                            cursor: "pointer", paddingBottom: "4px"}}
                        onClick={()=>{setSelectedMember(record); setOpen(true)}}>
                        <EditOutlined/> Edit   
                    </span>)}

            },
        {
            title: '',
            dataIndex: 'id',
            width: 130,
            render: (text, record)=>{
                return(
                    record.memeberStatus===4 ?
                            <span
                                style={{color: "#000", borderBottom: "1px dashed #000",
                                    cursor: "pointer", paddingBottom: "4px"}}
                                onClick={()=>{ showRenewConfirm(record)}}>
                                <PlayCircleOutlined/> Renew
                            </span>
                            :
                            <span
                                style={{color: "#faad14", borderBottom: "1px dashed #faad14",
                                    cursor: "pointer", paddingBottom: "4px"}}
                                onClick={()=>{ showSuspendConfirm(record)}}>
                                    <PauseOutlined/> Suspend
                                </span>)}

        },
        {
            title: '',
            dataIndex: 'id',
            width: 130,
            render: (text, record)=>{
                return(<span
                    style={{color: "#E3601D", borderBottom: "1px dashed #E3601D",
                        cursor: "pointer", paddingBottom: "4px"}}
                    onClick={()=>{ showDeleteConfirm(record)}}>
                        <DeleteOutlined/> Delete 
                    </span>)}

        }
        ]




    const showRenewConfirm = (member:GroupsUsers) => {
        confirm({

            title: 'Восстановить доступ?',
            icon: <PauseCircleOutlined />,
            content: 
                (member?.memberName ? member?.memberName+" ("+member?.invitationEmail+")" : member?.invitationEmail)
                +t("UserInvited"),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                axios.put(DATA_URL+"GroupsUsers/change-member-status?id="+member.id+"&newStatus=0",
                    null,{headers:authHeader()})
                    .then((result)=>{
                        console.log("result", result);
                        publish("groupChanges","");
                        reloadMembers()
                    });
            },
            onCancel() {
                setSelectedMember(null);
                console.log('Cancel');
            },
        });
    };
    
    
    const showSuspendConfirm = (member:GroupsUsers) => {
        confirm({
            
            title: 'Приостановить доступ?',
            icon: <PauseCircleOutlined />,
            content: "Для пользователя "
                +(member?.memberName ? member?.memberName+" ("+member?.invitationEmail+")" : member?.invitationEmail)
                +" участие в группе будет приостановлено",
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                axios.put(DATA_URL+"GroupsUsers/change-member-status?id="+member.id+"&newStatus=4",
                    null,{headers:authHeader()})
                    .then((result)=>{
                        console.log("result", result);
                        publish("groupChanges","");
                        reloadMembers()
                    });                
            },
            onCancel() {
                setSelectedMember(null);
                console.log('Cancel');
            },
        });
    };


    const showDeleteConfirm = (member:GroupsUsers) => {
        confirm({
            title: 'Удалить пользователя из группы?',
            icon: <DeleteOutlined style={{color: "#E3601D"}} />,
            content: (member?.memberName ? member?.memberName+" ("+member?.invitationEmail+")" : member?.invitationEmail)+" будет удален из группы",
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                axios.put(DATA_URL+"GroupsUsers/change-member-status?id="+member.id+"&newStatus=5",
                    null,{headers:authHeader()})
                    .then((result)=>{
                        console.log("result", result);
                        publish("groupChanges","");
                        reloadMembers()
                    });
                console.log('OK');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    
    

        return(
            <>
                <Row gutter={20}>
                    <Col span={24}>
                       

                        <Row style={{verticalAlign: "middle", marginBottom: "20px"}}  align="middle">
                            <Col span={8}>
                                
                                {    
                                    <h3 style={{color: "#0c5e9b"}}>
                                        <span>
                                            {t("Participants")}: { members?.length+"/"+theGroup?.maxSize}</span>
                                        


                                        { (members? members?.length : 0) - (theGroup ? theGroup?.maxSize ? theGroup?.maxSize : 5: 5) < 0 ?
                                            <Button style={{marginLeft: "20px"}} className={"orangeButton"}
                                                    onClick={()=>{setSelectedMember(null);setOpen(true)}}>
                                                {t("Invite_a_participant")}
                                            </Button>
                                            :
                                            <a style={{marginLeft: "20px", 
                                                fontSize: "14px", 
                                                paddingBottom: "3px",
                                                borderBottom: "1px solid #0c5e9b"}} onClick={()=>history.push("/quotas")}
                                                href={""} >{t("Increase_the_quota")}</a>
                                        }
        
                                    </h3> }
                            </Col>
                            <Col span={10}>
                                <Input.Search className={"emptyB"}
                                              style={{display: "inline-block"}}
                                              placeholder={t("Išči")}
                                              onSearch={(value)=>{
                                                  setFilter(value)
                                              }}
                                              onChange={(e)=>{
                                                  setFilter(e.target.value)
                                              }}
                                />
                            </Col>
                            <Col  span={6}>

                            </Col>
                        </Row>
                        <ConfigProvider
                            theme={{
                                token: {
                                    //colorBgContainer: "transparent"
                                },
                                components: {
                                    Table: {
                                        headerBg : "transparent"/* here is your component tokens */
                                    },
                                },
                            }}
                        >

                            <Table
                                locale={{
                                    triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                                    triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                                    cancelSort: t('Prekliči_razvrščanje')
                                }}
                                showHeader={false}
                                columns={columns}
                                dataSource={members? members : undefined}
                                loading={isPageLoading}
                                pagination={false}
                                scroll={{ y: 'calc(65vh)' }}
                                onRow={(record, rowIndex) => {
                                    return {
                                      //  onClick: (event) => {history.push("/users/"+record.id)}, // click row
                                    };
                                }}
                            />
                        </ConfigProvider>
                    </Col>

                </Row>

                <InviteForm visible={open} setVisible={setOpen}
                    //onCreate={handleOk} 
                            initialValues={selectedMember}
                            onCreate={(item:any) => { setOpen(false); reloadMembers();
                                /*console.log(item);*/
                            }} />
                
                
                
                
                
                
                
                
                
                
                

            </>
     
        )

}