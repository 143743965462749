import React, {ReactNode, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import GroupsUsers from "../../../types/GroupsUsers";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import EventLog from "../../../types/EventLog";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {MapLogEvent, ServerdateToPoints, ServerdateToTimeString} from "../../../utils/helpers";
import {Timeline} from "antd";
import {DeleteOutlined, EditOutlined, MailOutlined, PauseCircleOutlined} from "@ant-design/icons";
import {subscribe, unsubscribe} from "../../../Components/Notifications/NotificationContext";


export default function EventLogPage() {

    const {t} = useTranslation();
    const [isPageLoading, setisPageLoading] = useState(false);

    const [open, setOpen] = useState(false);


    const history = useHistory();

    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }



    const [logs, setLogs] = useState<EventLog[]|null>(null);
    const [titems, setTitems] = useState<{ color: string, children: ReactNode}[] >([])

    const reloadTimeLine =()=>{
        axios.get(AuthService.DATA_URL+'EventLog/', {headers: authHeader()})
            .then((response)=>{
                setLogs(response.data);
                console.log("logs: " ,response.data)

                setTitems(response.data.map((log: EventLog)=>({
                    //label : ServerdateToPoints(log.eventDateTime),
                    dot: log.groupEvent===2 ? <MailOutlined /> :
                        log.groupEvent===5 ? <PauseCircleOutlined /> :
                            log.groupEvent===7? <EditOutlined />:
                                log.groupEvent===6? <DeleteOutlined />:
                                "",

                    color: log.groupEvent===3? "green" :
                        log.groupEvent===5? "#faad14" :
                            log.groupEvent===6? "#E3601D":
                            "#1677ff",
                    children: <span>{ServerdateToPoints(log.eventDate)} <span style={{color: "#777", fontSize: "12px"}}>
                                {ServerdateToTimeString(log.eventDate)}
                            </span>  <br/>
                        {log.groupEvent==1 ? t(MapLogEvent(log.groupEvent)): ""}
                        {log.groupEvent==2 ?
                            <>
                                <b>{(log.groupsUsers?.memberName ? log.groupsUsers?.memberName : "") + " (" +
                                    log.groupsUsers?.invitationEmail + ") "} </b>
                                {t(MapLogEvent(log.groupEvent))}
                            </>: ""}
                        {log.groupEvent==3 ?
                            <>
                                <b>{(log.groupsUsers?.memberName ? log.groupsUsers?.memberName : "") + " (" +
                                    log.groupsUsers?.invitationEmail + ") "} </b>
                                {t(MapLogEvent(log.groupEvent))}
                            </>: ""}
                        {log.groupEvent==4 ?
                            <>
                                <b>{(log.groupsUsers?.memberName ? log.groupsUsers?.memberName : "") + " (" +
                                    log.groupsUsers?.invitationEmail + ") "} </b>
                                {t(MapLogEvent(log.groupEvent))}
                            </>: ""}
                        {log.groupEvent==5 ?
                            <>
                                {t("UserSuspended1")} <b>{(log.groupsUsers?.memberName ? log.groupsUsers?.memberName : "") + " (" +
                                log.groupsUsers?.invitationEmail + ") "} </b>
                                {t("UserSuspended2")}
                            </>: ""}
                        {log.groupEvent==6 ?
                            <>
                                <b>{(log.groupsUsers?.memberName ? log.groupsUsers?.memberName : "") + " (" +
                                    log.groupsUsers?.invitationEmail + ") "} </b>
                                {t(MapLogEvent(log.groupEvent))}
                            </>: ""}
                        
                        
                        {log.groupEvent==7 ?
                            <>
                                {t(MapLogEvent(log.groupEvent))}
                            </>: ""}
                                
                                    
                                
                           
                                
                                
                            </span>
                })))
            })
            .catch(function (error) {
                if(error.response.status===401){
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();

                }
            })
    }

    useEffect(()=>{

        subscribe("groupChanges", (data: any) => reloadTimeLine());


        
        if(logs==null){

            reloadTimeLine()


        }
        return () => {
            unsubscribe("groupChanges", (data: any) => reloadTimeLine());
        }
    })




    return (
            <div style={{height: "calc(65vh)", overflow: "scroll", paddingTop: "10px"}}>
                <Timeline
                    mode={"alternate"}
                    items={titems}/>
            </div>
    )
}