import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import axios from "axios";
import {DATA_URL} from "../../Services/auth-service";
import authHeader from "../../Services/auth-header";
import GroupsUsers from "../../types/GroupsUsers";
import {ColumnsType} from "antd/es/table";
import {Avatar, Button, Col, ConfigProvider, Input, Row, Table} from "antd";
import {ServerdateToPoints} from "../../utils/helpers";
import {
    CheckCircleOutlined, DeleteOutlined,
    EditOutlined,
    HourglassOutlined,
    PauseCircleOutlined, PauseOutlined,
    PlayCircleOutlined
} from "@ant-design/icons";

const UserListPage = () => {
    const XLSX = require('xlsx');
    const {t} = useTranslation();
    const history = useHistory();

    ///

    const user = AuthService.getCurrentUser();
    if (!user) {
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }
    const[members, setMembers] = useState<GroupsUsers[]|null>(null)
    const[isPageLoading, setisPageLoading]=useState(false);
    const [filter, setFilter]=useState("")


    useEffect(()=>{
        if(members===null){
            axios.get(DATA_URL+"GroupsUsers/all-members", {headers:authHeader()})
                .then((response=>{
                    setisPageLoading(false)
                    setMembers(response.data);
                    console.log(response.data)

                }))
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                });

        }
    })

    const columns: ColumnsType<GroupsUsers> = [
        {
            title: t('Date'),
            dataIndex: 'dealDate',

            filteredValue :[filter? filter : ""],
            onFilter: (value, record) => {
                //console.log("value: ", value)
                return (
                    String(record.invitationEmail).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.memberName).toLowerCase().includes(value.toString().toLowerCase())
                )},



            render: (text, record) => {
                var color= "#0c5e9b"//'#'+(0x1000000+Math.random()*0xffffff).toString(16).substring(0,6)
                return (

                    <Row gutter={10} align={"middle"}>


                        <Col>
                            <Avatar
                                size="large"
                                style={{
                                    backgroundColor: "#fff",
                                    borderWidth:"3px",
                                    color: color,
                                    borderColor: color
                                    //'#'+((0x1000000+Math.random()*0xffffff).toString(16).substring(1,6))
                                    //'#'+(0x1000000+Math.random()*0xffffff).toString(16).substring(1,6)
                                }}
                            ><b>{record.invitationEmail?.substring(0,1).toUpperCase() }</b></Avatar>
                        </Col>

                        <Col span={18}>
                            <Row>
                                <Col>
                                    {record.invitationEmail}
                                </Col>
                            </Row>
                        </Col>
                        {/* 
                            <Col> invitedAt
                                {record.memeberStatus==2 ?
                                    <span style={{fontSize: '14px', color: "#4caf50"}}><CheckCircleOutlined /> Joined the group</span>:
                                    <HourglassOutlined style={{fontSize: '14px', color: "#ffc107"}} /> }

                            </Col>
                            */}
                    </Row>


                )}
        },
        {
            title: t('Date'),
            dataIndex: 'invitedAt',
            render:  (text, record) => {
                return (
                    <span>{t("UserInvited")} {ServerdateToPoints(record.invitedAt)}</span>
                )}
            
        },
        {
            title: t('Date'),
            dataIndex: 'dealDate',

            filteredValue :[filter? filter : ""],
            onFilter: (value, record) => {
                //console.log("value: ", value)
                return (
                    String(record.invitationEmail).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.memberName).toLowerCase().includes(value.toString().toLowerCase())
                )},



            render: (text, record) => {
                var color= "#0c5e9b"//'#'+(0x1000000+Math.random()*0xffffff).toString(16).substring(0,6)
                return (
                    <span>{record.memberName}</span> 


                )}
        },

        {
            title: '',
            dataIndex: 'id',
            render: (text, record)=>{
                return(
                    <>
                        {record.memeberStatus===2 ?
                            <span style={{fontSize: '14px', color: "#4caf50"}}><CheckCircleOutlined /> Joined the group</span>: ""}
                        {record.memeberStatus===1 ?
                            <span style={{fontSize: '14px', color: "#faad14"}}><HourglassOutlined /> Waiting user to join</span>: ""  }

                        {record.memeberStatus===3 ?
                            <span style={{fontSize: '14px', color: "#777"}}> {record.invitationEmail+" "+t("UserRejected")}</span>: ""}

                        {record.memeberStatus===4 ?
                            <span style={{fontSize: '14px', color: "#faad14"}}> <PauseCircleOutlined /> {t("UserSuspended1")+" "+t("UserSuspended2")}</span>: ""}

                        {record.memeberStatus===5 ?
                            <span style={{fontSize: '14px', color: "#E3601D"}}> <DeleteOutlined /> {t("UserDeleted")}</span>: ""}


                    </>
                )}

        },
        


    ]

    return <>
        <h1>{t("Users")}</h1>
        <Row gutter={20}>
            <Col span={24}>


                <Row style={{verticalAlign: "middle", marginBottom: "20px"}}  align="middle">

                    <Col span={12} offset={12}>
                        <Input.Search className={"emptyB"}
                                      style={{display: "inline-block", float: "right"}}
                                      placeholder={t("Išči")}
                                      onSearch={(value)=>{
                                          setFilter(value)
                                      }}
                                      onChange={(e)=>{
                                          setFilter(e.target.value)
                                      }}
                        />
                    </Col>

                </Row>
                <ConfigProvider
                    theme={{
                        token: {
                            //colorBgContainer: "transparent"
                        },
                        components: {
                            Table: {
                                headerBg : "transparent"/* here is your component tokens */
                            },
                        },
                    }}
                >

                    <Table
                        locale={{
                            triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                            triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                            cancelSort: t('Prekliči_razvrščanje')
                        }}
                        size={"small"}
                        //showHeader={false}
                        columns={columns}
                        dataSource={members? members : undefined}
                        loading={isPageLoading}
                        pagination={false}
                        scroll={{ y: 'calc(65vh)' }}
                        onRow={(record, rowIndex) => {
                            return {
                                  onClick: (event) => {history.push("/users/"+record.id)}, // click row
                            };
                        }}
                    />
                </ConfigProvider>
            </Col>

        </Row>
    </>
}

export default UserListPage;
