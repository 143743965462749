export default function fetchauthHeader() {
    const userStr = localStorage.getItem("user");
    let user = null;
    if (userStr)
        user = JSON.parse(userStr);

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token, 'Accept': 'application/json',
            'Content-Type': 'application/json' }; // for Spring Boot back-end
        // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
    } else {
        return { Authorization: '', 'Accept': 'application/json',
            'Content-Type': 'application/json'  }; // for Spring Boot back-end
        // return { 'x-access-token': null }; // for Node Express back-end
    }
}

