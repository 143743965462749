import React from 'react';
import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import SettingsPage from "./settings";
import DealsPage from "./Deals/ListPage";
import DealsDetailPage from "./Deals/Components/DealsDetailPage";
import GroupListPage from "./Groups/GroupListPage";
import UserListPage from "./Users/UserListPage";
import QuotasListPage from "./Quotas/QuotasList";
import GroupDetailPage from "./Groups/Components/GroupDetailsPage";
import CheckoutPage from "./ServicePayments/CheckoutPage";
import CheckoutForm from "./ServicePayments/CheckoutPage";
import Return from "./ServicePayments/ReturnPage";
import QuotasPayment from "./Quotas/QuotasPayment";
import UserDetailsPage from "./Users/UserDetails";
import About from "./About/about";




const DashboardPage = lazy(() => import("./Dashboard"));


interface Props {
    component?: React.ComponentType | null
    path?: string | null
}






export const Routing = () => {
    
    return (
        <Suspense fallback={<div>Loading...</div>}>
            
        <Switch>
            <Route key={1} exact path="/" component={GroupListPage} />
            <Route key={2} exact path="/Dashboard" component={DashboardPage} />


            
            <Route key={30} exact path="/groups" component={GroupListPage} />
            
            <Route key={31} path="/groups/:id" component={GroupDetailPage} />

            <Route key={4} exact path="/users" component={UserListPage} />

            <Route key={41} path="/users/:id" component={UserDetailsPage} />

            <Route key={5} exact path="/quotas" component={QuotasListPage} />

            <Route key={51} exact path="/quotaspayment" component={QuotasPayment} />
            
            
            
            
            
            <Route key={51} path="/quotas/:id" component={DealsDetailPage} />



            <Route  key={62} exact  path="/return" component={Return} />
            <Route  key={61} exact path="/checkout" component={CheckoutForm} />

            <Route key={99} exact path="/about" component={About} />


            <Route key={19} exact path="/settings" component={SettingsPage} />



        </Switch>
        </Suspense>
    );
};