import React, {createContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import ListFilter from "../../types/ListFilter";
import GroupListFilter from "./Components/GroupListFilter";
import {Button, Card, Col, Divider, Form, Input, InputNumber, Radio, Row, Space, Spin} from "antd";
import OmdGroup from "../../types/Group";
import axios from "axios";
import {DATA_URL} from "../../Services/auth-service";
import authHeader from "../../Services/auth-header";
import GroupDetailPage from "./Components/GroupDetailsPage";
import {CheckCircleOutlined, CheckOutlined} from "@ant-design/icons";
import Calculation from "../../types/Calculation";


const GroupListPage = () => {
    const XLSX = require('xlsx');
    const {t} = useTranslation();
    const history = useHistory();

    ///

    const user = AuthService.getCurrentUser();
    if (!user) {
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }
    const [pageFilter, setPageFilter]=useState<ListFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        hidefinished:false,
        selectedDates: "",
        groupStatus : 100


    });
    
    const [groups, setGroups] = useState<OmdGroup[]|null>(null);
    const[isPageLoading, setisPageLoading]=useState(false);

    useEffect(()=>{
        if(groups===null){
            setisPageLoading(true)
            axios.get(DATA_URL+"Groups", {headers:authHeader()})
                .then((response=>{
                    console.log(response.data.length)
                    //if(response.data.length!=0)
                       // history.push("/groups/0")
                        setisPageLoading(false)
                    setGroups(response.data);
                    //if(response.data)
                }))
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
        }
    })
    
    const setCBFilterFunc=(filter: any)=>{
        setPageFilter(filter);
        //console.log("page comp:", filter)
    }
    


    const [form] = Form.useForm();
    
    const [buttonDisabled, setbuttonDisabled] = useState(true)
    const [buttonText, setButtonText]=useState(t("Specify_a_group_name"))
    const[groupTitle, setgroupTitle]=useState("");

    const[membersQuantity, setmembersQuantity]=useState(5);
    const[bookedTarif, setbookedTarif]=useState(20);
    
    const[calculation, setCalculation]=useState<Calculation>({groupTitle: "",tarif:20, additionalMembers:0, additionalDocs:0})

    

    const checkTitle=(e:any)=>{
        //setgroupTitle(e);
        setCalculation(prev => ({
            ...prev,
            groupTitle: e
        }));
        
        if(e.length===0){
            setbuttonDisabled(true)
            setButtonText(t("Specify_a_group_name"))
        }
        else{
            setbuttonDisabled(false);
            setButtonText(t("Create_a_group"))
        }
    }
    
    const checkout =()=>{
        console.log(calculation);
        localStorage.setItem("calculation", JSON.stringify(calculation));
        
        console.log(localStorage.getItem("calculation"));
        console.log(JSON.stringify(localStorage.getItem("calculation")));
        
        const Calc:Calculation= JSON.parse(JSON.stringify(localStorage.getItem("calculation")))
        console.log("Calc: ", Calc)
        
        
        
        
        history.push("/checkout")
        
        /*
        axios.post(DATA_URL+"Groups/create-checkout-session",
           null ,
            {headers:authHeader()})
            .then((result)=>{
                console.log(result.data);
                //history.push("/groups/0");
            })
            
         */
    }
    
    const changeTarif =(value:number)=>{
        setCalculation(prev => ({
            ...prev,
            tarif: value
        }));
    }

    const changeMembersQty =(value:number)=>{
        setCalculation(prev => ({
            ...prev,
            additionalMembers: value-5
        }));
    }






    
    

    
    
        if(groups?.length===0 ){
                return(
                   
                    <Spin spinning={isPageLoading}>
                        <Row style={{height: "80vh", justifyContent: "center"}}>
                            
                            
                            
                            
                            <Col style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flex: 1,
                                width: "400px"

                            }}>

                                <Form
                                    onFinish={checkout}
                                    initialValues={
                                        {
                                            ["quantity"]: membersQuantity===null? 5: membersQuantity,
                                        }
                                    }
                                >

                                <div style={{height: "80vh", width: "900px", verticalAlign: "middle", paddingTop: "0px", justifyContent: "center", display: "block"}}>

                                    <Row>
                                        <Col span={24}>
                                            <div style={{
                                                display:"block",
                                                margin: "0 auto",
                                                width: "640px"}}>
                                                <h1 style={{marginTop: "0px", marginBottom: "10px" }}>{t("You_havent")} <br/> {t("Lets_create")}</h1></div>

                                            <div style={{
                                                display:"block",
                                                margin: "0 auto",
                                                width: "640px",
                                                //height: "225px",
                                                paddingTop: "200px",
                                                position: "relative",
                                                backgroundImage: "url(/img/arr2bg.png)",
                                                backgroundSize: "auto", backgroundPosition: "center center", backgroundRepeat: "no-repeat"}}>


                                                <div style={{
                                                    fontSize: "16px",
                                                    // border: "1px solid #000",
                                                    position: "absolute", top: 20, width: "180px", left: 20}}>
                                                    <b style={{color: "#0c5e9b"}}>1.</b> {t("Come_up")}</div>

                                                <div style={{
                                                    fontSize: "16px",
                                                    //border: "1px solid #000",
                                                    position: "absolute", top: 60, left: 450,
                                                    width: "170px"}}><b style={{color: "#0c5e9b"}}>2.</b> {t("How_many_members")}</div>

                                                <div style={{width: "390px", margin: "0 auto"}}>

                                                    <Form.Item name="title"
                                                               rules={[{ required: true, message: 'Please input group name!' }]}>
                                                        <Space.Compact style={{ width: '100%' }}>
                                                            <Input size="large" placeholder={t("beautiful_name")}
                                                                   onChange={(e)=> {
                                                                       checkTitle(e.target.value)
                                                                   }}
                                                            />
                                                            <Form.Item name={"quantity"} noStyle>
                                                                <InputNumber min={5} size="large"
                                                                             onChange={(e)=> {changeMembersQty(e===null? 5: e)}}
                                                                /></Form.Item>

                                                        </Space.Compact>



                                                    </Form.Item>

                                                </div>


                                            </div>
                                        </Col>
                                    </Row>


                                            
                                                <Row gutter={10}>
                                                    <Col span={10}>
                                                        <Card className={"nobg nopadding"} bordered={false}>
                                                       
                                                            <p>&nbsp;</p>
                                                            <p style={{borderBottom: "1px solid #e8e9ed", paddingBottom:"5px"}}>{t("Group_size_up")}</p>
                                                            <p style={{borderBottom: "1px solid #e8e9ed", paddingBottom:"5px"}}>{t("Signature_capacity_up")}</p>
                                                            <p>{t("Additional_participants")} {calculation.additionalMembers}</p>
                                                            <Divider style={{marginBottom: "0px", marginTop: "0px"}}/>
                                                   
                                                        </Card>
                                                    </Col>
                                                    <Col span={7}>
                                                        <Card className={"nopadding"}>
                                                            <p>
                                                                <Radio.Group name="radiogroup" 
                                                                             onChange={(e)=>changeTarif(e.target.value)} value={calculation.tarif}>
                                                                <Radio value={20}>{t("Per_month")}</Radio>
                                                                </Radio.Group>
                                                            </p>
                                                            <p style={{borderBottom: "1px solid #e8e9ed", paddingBottom:"5px"}}><CheckCircleOutlined style={{color: "green"}} /> {t("Included_in_the_price")}</p>
                                                            <p style={{borderBottom: "1px solid #e8e9ed", paddingBottom:"5px"}}><CheckCircleOutlined style={{color: "green"}} /> {t("Included_in_the_price")}</p>
                                                            <p style={{textAlign: "right"}}>{calculation.additionalMembers} x 5.00€={(calculation.additionalMembers*5).toFixed(2)}€</p>
                                                            <Divider style={{marginBottom: "0px", marginTop: "0px"}}/>
                                                            <h3 style={{textAlign: "right", marginTop: "10px"}}>
                                                                {(20 +calculation.additionalMembers*5).toFixed(2)}€</h3>
                                                        </Card>
                                                    </Col>
                                                    <Col span={7}>
                                                        <Card className={"nopadding"}>
                                                            <p>
                                                                <Radio.Group name="radiogroup"
                                                                             onChange={(e)=>changeTarif(e.target.value)} value={calculation.tarif}
                                                                >
                                                                <Radio value={200}>{t("Per_year")}</Radio>
                                                                </Radio.Group>
                                                            </p>
                                                            <p style={{borderBottom: "1px solid #e8e9ed", paddingBottom:"5px"}}><CheckCircleOutlined style={{color: "green"}} /> {t("Included_in_the_price")}</p>
                                                            <p style={{borderBottom: "1px solid #e8e9ed", paddingBottom:"5px"}}><CheckCircleOutlined style={{color: "green"}} /> {t("Included_in_the_price")}</p>
                                                            <p style={{textAlign: "right"}}>{calculation.additionalMembers} x 12 x 5.00€={(calculation.additionalMembers*5*12).toFixed(2)}€</p>
                                                            <Divider style={{marginBottom: "0px", marginTop: "0px"}}/>
                                                            <h3 style={{textAlign: "right", marginTop: "10px"}}>
                                                                {(200 +calculation.additionalMembers*5*12).toFixed(2)}€
                                                            </h3>
                                                        </Card>
                                                    </Col>
                                                </Row>
                

                          
                                    {/* 
                                    <Row gutter={20}>
                                        <Col span={10} >
                                            Купить подписку на месяц <br/>
                                            <ul>
                                                <li>Размер группы:  до 5 учстников</li>
                                                <li>Возможность подписи - до 20 документов</li>
                                            </ul>
                                            <Divider style={{marginBottom: "0px"}}/>
                                            <h3 style={{float: "right", marginTop: "10px"}}>20.00€</h3>

                                        </Col>
                                        <Col span={2}  style={{textAlign: "center"}}>
                                            <div style={{height: 100}}>
                                                <Divider type="vertical" />
                                            </div>
                                        </Col>

                                        <Col span={10}>Оплатить за год</Col>
                                    </Row>
                                    */}
                                    <Row>
                                        <Col span={24} style={{textAlign: "center", paddingTop: "20px"}}>
                                            <h3>{t("Total_to_pay")}: {calculation.tarif===20?
                                                (20 +calculation.additionalMembers*5).toFixed(2) :
                                                (200 +calculation.additionalMembers*5*12).toFixed(2)
                                            }€
                                                <Button
                                                    disabled={buttonDisabled} style={{marginLeft:"20px"}}   
                                                    htmlType="submit" size={"large"}  className={"orangeButton"}
                                                    onClick={()=>{}}>
                                                    {buttonText}</Button>
                                            </h3>

                                        </Col>
                                    </Row>
                                    {/* 
                                    <Row>
                                        <Col span={24}>
                                            <div style={{
                                                fontSize: "16px",
                                                lineHeight: "25px",
                                                display:"block",
                                                margin: "80px auto",
                                                width: "640px"}}>
                                                <b style={{color: "#0c5e9b"}}>3.</b> Когда группа будет создана, Вы сможете пригласить в нее других участников
                                                и вопсользоваться всеми приемуществами совместной работы в OnMyDisk.



                                            </div>
                                        </Col>
                                    </Row>
*/}


                                </div>
                                </Form>
                            </Col>
                        </Row>
                        
                    </Spin>
       
                    )
        }
        else{
            if(groups!=null)
              
            history.push("/groups/0")
        } 
    
    

    return <></>
    

}

export default GroupListPage;
