import React, {useContext, useEffect, useRef, useState} from 'react';
import { publish } from "./NotificationContext";
import {useConnection} from "./useConnection";
import {Card, Dropdown, MenuProps, message, notification, Typography} from "antd";
import NotificationsButton from "./NotificationsButton";
import {CheckCircleTwoTone} from "@ant-design/icons";
import {ItemType} from "antd/es/menu/hooks/useItems";
import { useHistory } from "react-router-dom";
import {refreshUser} from "../../Services/auth-service";
import * as AuthService from "../../Services/auth-service";


interface NotificationMessage {
    messageId : string,
    message: string,
    objectType : string,
    objectId : string,
}
const messages:NotificationMessage[] =[];
export const NotificationCentre = () => {
    const connection=useConnection();
    const history = useHistory();
    
    const[messCount, setmessCount] = useState(0);

    const [items, setItems] = useState<any|null>(null);//: MenuProps['items'] = [{ key: '1',  label: <span> &nbsp; Українська</span> }]
    

    
    connection?.on("ReceiveNotifiction", (msg) => {
        if (!messages.find((t) => t.messageId === msg.messageId)) {
            if (msg.objectType === "hello") {
                console.log("msg:", msg);
            } else {
                if(msg.objectType === "userJoined"){
                    messages.push(msg);
                    const mmap= <div>{messages.map((t) => {
                        return <Card key={t.messageId}>{t.message}
                            <br/>
                            <Typography.Link
                                onClick={()=> {
                                    history.push(`/routes/view/${t.objectId}`)
                                }}>Посмотреть отклик</Typography.Link>
                        </Card>
                    })}</div>
                    setItems(mmap);
                    setmessCount(messages.length)
                    openNotification(msg);
                    publish("userJoined", msg);
                    publish("groupChanges", "")
                    //console.log("msg.messageId: ", msg.messageId)
                }
                if (msg.objectType === "ping") {
                    console.log("msg:", msg);
                    openNotification(msg);
                }
                
                //groupCreated
                if (msg.objectType === "groupCreated") {
                    publish("groupCreated", "")
                }
                if(msg.objectType === "subscriptionCanceled"){
                    AuthService.logout();
                    //refreshUser();
                    history.push("/login");
                    window.location.reload();
                }
                if(msg.objectType === "paymentReceived"){
                    messages.push(msg);
                    const mmap= <div>{messages.map((t) => {
                        return <Card key={t.messageId}>{t.message}
                            <br/>
                            {/* 
                            <Typography.Link
                                onClick={()=> {
                                    history.push(`/routes/view/${t.objectId}`)
                                }}>Посмотреть отклик</Typography.Link>*/}
                        </Card>
                    })}</div>
                    setItems(mmap);
                    setmessCount(messages.length)
                    openNotification(msg);
                    publish("paymentReceived", msg);
                    publish("groupChanges", "")
                    //console.log("msg.messageId: ", msg.messageId)
                }
                
                
                if(msg.objectType === "paymentFailed"){
                    messages.push(msg);
                    const mmap= <div>{messages.map((t) => {
                        return <Card key={t.messageId}>{t.message}
                            <br/>
                            <Typography.Link
                                onClick={()=> {
                                    history.push(`/routes/view/${t.objectId}`)
                                }}>Посмотреть отклик</Typography.Link>
                        </Card>
                    })}</div>
                    setItems(mmap);
                    setmessCount(messages.length)
                    openNotification(msg);
                    publish("paymentFailed", msg);
                    publish("groupChanges", "")
                    //console.log("msg.messageId: ", msg.messageId)
                }

            }
        }
    })
    if(connection?.state==="Disconnected"){
        connection?.start().then(() => {
            //console.log("started")
            const username = "";
            const msg = "test";
            connection?.invoke("JoinChat", {username, msg});
        });
    }


    const openNotification=(mess: NotificationMessage)=>{
        
        let notificationTitle = "";
        if(mess.objectType==="GroupRenamed"){
            notificationTitle="Группа переименована"
        }
        if(mess.objectType==="userJoined"){
            notificationTitle="Пользователь присоединился"
        }
        if(mess.objectType==="UserRejected"){
            notificationTitle="Предложение отклонено"
        }
        if(mess.objectType==="paymentReceived") {
            notificationTitle = "Оплата"
        }
        if(mess.objectType==="paymentFailed") {
            notificationTitle = "Неудачная попытка оплаты"
        }
        if(mess.objectType==="ping"){
            notificationTitle="Ping!!!!!"
        }


        

        
        
        notification.open({
            type: "info",
            message: (
                <span>{notificationTitle}</span>
            ),
            icon: <CheckCircleTwoTone style={{ color: '#1449ae' }} />,
            description: <span>
                {mess.message
                    //Получен новый отклик на заказ <br/>X-2403-000016!
                }
                <br/><br/>
                {/*mess.objectId!=="" ?
                   
                    <Typography.Link
                        onClick={()=> {
                            history.push(`/routes/view/${mess.objectId}`)
                        }}>Посмотреть отклик
                    </Typography.Link>
                    :
                    ""
                */}
</span>,
            placement: "bottomLeft",

        })
    }
    
    
    return (
        <NotificationsButton
            notificationsCount={messCount}
            content={items}
                
                
            />)
    
}