import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Avatar, Button, Card, Col, Divider, Form, Input, message, Row, Dropdown} from "antd";
import type {  MenuProps } from 'antd';

import type { SelectProps } from 'antd';
import { Select, Space } from 'antd';

import {UserOutlined, DownOutlined, ExportOutlined} from "@ant-design/icons";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import IUser from "../../../types/user-type";



interface DataType {
    "id": string,
    "userName": string,
    "email": string,
    "phoneNumber": string,
    "firstNme": string,
    "lastName": string
}


const UserSettingsPage = () => {
    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    
    const [userslang, setuserslang] = useState("en");
    const [langIcon, setlangIcon] = useState("🇬🇧");
    
    const [profileImage, setProfileImage]=useState("");
   
    //console.log(user);
    if(!user) {
        history.push("/login");
    }

    const [form] = Form.useForm();
    const [Langform] = Form.useForm()
    
    const onFinishFailed = () => {
        message.error('Submit failed!');
    };


   // const  [ownInfo, setownInfo] = useState<any>();
    const  [ownId, setownId] = useState<any>();

    const onFinish = (values: any) => {

        axios.put(AuthService.DATA_URL + 'Settings/update-company-info?id=' + ownId,
            {
                "email": values.email,
                "firstName": values.firstName,
                "lastName": values.lastName,
                "companyName": values.companyName,
                "davcnaStevilka": values.davcnaStevilka,
                "naslov1": values.naslov1,
                "naslov2": values.naslov2,
                "postalCode": values.postalCode,
                "telephone": values.telephone

            }, {headers: authHeader()})
            .then((res) => {
                if(res.status===200){
                    message.success('Podatki so bili uspešno shranjeni');   
                }
                else{
                    console.log(res.data)
                }
                 
                }
            )
            
            .finally(() => {

            });
    }

    const [APIData, setAPIData] = useState<IUser>();
    
    const [portal_url, setPortal_url] = useState("");

        useEffect(()=>{
           // setuserslang(user.language);
            if(!APIData){
                axios.get(AuthService.DATA_URL+'settings/get-ownInfo', {headers: authHeader()})
                    .then()
                    .then((response) => {
                        form.setFieldsValue({
                            firstName: response.data ? response.data.firstName : "rr",
                            lastName: response.data ? response.data.lastName : "",
                            email: response.data ? response.data.email : "",
                            login: response.data ? response.data.omdUserName : "",
                            companyName: response.data ? response.data.companyName : "",
                            davcnaStevilka: response.data ? response.data.davcnaStevilka : "",
                            naslov1: response.data ? response.data.naslov1 : "",
                            naslov2: response.data ? response.data.naslov2 : "",
                            postalCode: response.data ? response.data.postalCode : "",
                            telephone: response.data ? response.data.telephone : "",
                        });
                        Langform.setFieldsValue({
                            lang:response.data ? response.data.language : "en"
                        });

//                        console.log("Request response.data: ", response.data)
                        setAPIData(response.data);
                        //console.log(response.data)
                        setPortal_url(response.data.portalUrl)

                        switch(response.data.language) {
                            case "en": {
                                setuserslang("English");
                                setlangIcon("en")
                                break;
                            }
                            case "si": {
                                setuserslang("Slovenščina");
                                setlangIcon("si")
                                break;
                            }
                            case "fr": {
                                setuserslang("Français");
                                setlangIcon("fr")
                                break;
                            }
                            case "de": {
                                setuserslang("Deutsch");
                                setlangIcon("de")
                                break;
                            }
                            case "ru": {
                                setuserslang("Русский");
                                setlangIcon("ru")
                                break;
                            }
                            case "it": {
                                setuserslang("Italiano");
                                setlangIcon("it")
                                break;
                            }
                            case "uk": {
                                setuserslang("Українська");
                                setlangIcon("uk")
                                break;
                            }


                            default: {
                                setuserslang("English");
                                setlangIcon("en")
                                break;
                            }
                        }

                        //setuserslang(response.data.language);


//                        console.log("setownInfo: ", response.data)


                        setownId(response.data.id);
//                        setlogoId(response.data.logoId);
                    })
                setProfileImage(user.profileImage)
            }


        });


    
    const ChangeLanguage = (value: string)=>{
        console.log("value: ", value);
        var muser : any = {};
        const tt: any = localStorage.getItem("user")===null ? "" : localStorage.getItem("user");
        muser = JSON.parse(tt===null ? "-" : tt);
        muser.language = value;
        localStorage.setItem("user", JSON.stringify(muser));
        i18n.changeLanguage(value);

        axios.put(AuthService.DATA_URL+'Settings/change-language?lng='+value,
            {
                "lng": value
            },{headers: authHeader()})
            .then((response) => {
                i18n.changeLanguage(value);
                console.log(value)
            })


    }
    
    
    const items: MenuProps['items'] = [
        { key: 'en', icon: <img src="/img/lang/en.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; English</span> },
        { key: 'si', icon: <img src="/img/lang/si.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Slovenščina</span> },
        { key: 'fr', icon: <img src="/img/lang/fr.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Français</span> },
        { key: 'de', icon: <img src="/img/lang/de.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Deutsch</span>},
        { key: 'ru', icon: <img src="/img/lang/ru.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Русский</span> },
        { key: 'it', icon: <img src="/img/lang/it.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Italiano</span> },
        { key: 'uk', icon: <img src="/img/lang/uk.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Українська</span> },
    ]

    const handleLanguageClick: MenuProps['onClick'] = (e) => {
        var muser : any = {};
        const tt: any = localStorage.getItem("user")===null ? "" : localStorage.getItem("user");
        muser = JSON.parse(tt===null ? "-" : tt);
        muser.language = e.key;
        localStorage.setItem("user", JSON.stringify(muser));
        i18n.changeLanguage(e.key);

        axios.put(AuthService.DATA_URL+'Settings/change-language?lng='+e.key,
            {
                "lng": e.key
            },{headers: authHeader()})
            .then((response) => {
                i18n.changeLanguage(e.key);
//                console.log(e.key)
            })

    };

    const menuProps = {
        items,
        onClick: handleLanguageClick,
    };
    
    
    return(
        <div>

            <Row style={{paddingTop: "10px"}}>
                <Col span={3} style={{textAlign: "center"}}>
                    {
                        profileImage===""?
                            <Avatar size={96}  icon={<UserOutlined/>}></Avatar>
                            :
                            <img
                                //src={{uri: '${user.profileImage}'}}
                                //src={{uri: `data:image/gif;base64,${this.state.base64File}`}}
                                src={`${profileImage}`}
                                style={{width: "96px" ,
                                border: "1px solid #ddd",
                                borderRadius: "50%",
                                verticalAlign: "middle"}}/>
                    }
                    
                    
                    
                   
                </Col>
                <Col span={6}>
                    <p>{t("Userset")}&nbsp;
                        <a href={"https://onmydisk.net/profile"}
                           style={{color: "#0c5e9b", borderBottom: "1px solid #0c5e9b", paddingBottom: "2px"}}
                           target={"_blank"}>OnMyDisk <ExportOutlined /> </a>
                    </p>


                </Col>
                <Col span={7}>
                    <Form
                        style={{float: "right"}}
                        form={Langform}
                        name="Langform"
                    >

                        <Form.Item
                            name="lang2"
                            label={""}
                        >
                            <Space wrap>
                                {/* 
                        <Dropdown menu={menuProps}>
                            <Button style={{marginTop: "10px"}}>
                                <Space>
                                    <img src={"/img/lang/"+langIcon+".svg"}  style={{width:"12px", height: "12px"}}/>
                                    {userslang}
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>*/}
                            </Space>
                        </Form.Item>

                    </Form>

                </Col>
            </Row>
            
            <Row gutter={20}>

                <Col span={16}>
                   
                    
                    <Form
                        form={form}
                        name="wrap"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="horizontal"
                        labelCol={{ flex: '120px' }}
                        labelAlign="right"
                        labelWrap
                        wrapperCol={{ flex: 1 }}
                        colon={false}
                        //style={{ maxWidth: 600 }}
                    >
                        <Row>
                            <Col span={24}>
                            <Card style={{marginTop: "20px"}}>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            name="email"
                                            label={t("E_pošta")}
                                        >
                                            <Input  bordered={false} disabled={true} placeholder="Ime"  />
                                        </Form.Item>
                                        
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="login"
                                            label={t("Login")}
                                        >
                                            <Input placeholder="Ime" bordered={false} disabled={true} />
                                        </Form.Item>

                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        </Row>
                        <Row gutter={20} style={{verticalAlign: "top"}}>
                            <Col span={24}>
                                <Card style={{marginTop: "20px"}} title={<span style={{color: "#0c5e9b"}}>{t("Nastavitve_podjetja")}</span>}>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item name="companyName" label={t("Naziv")}>
                                                <Input placeholder={t("Naziv")}  />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="davcnaStevilka" label={t("Davčna_Številka")}>
                                                <Input   placeholder={t("Davčna_Številka")}  />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item name="naslov1" label={t("Naslov")+" 1"}>
                                                <Input   placeholder={t("Naslov")+" 1"}  />
                                            </Form.Item>

                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="naslov2" label={t("Naslov")+ " 2"}>
                                                <Input   placeholder={t("Naslov")+" 2"}  />
                                            </Form.Item>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item name="postalCode" label={t("Postal_code")}>
                                                <Input   placeholder={t("Postal_code")}  />
                                            </Form.Item>

                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="country" label={t("Country")}>
                                                <Input bordered={false} disabled={true}  placeholder={"Slovenia"}  />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item name="telephone" label={t("Telephone")}>
                                                <Input   placeholder={t("Telephone")}  />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    
                                    
                                    <Row>
                                        <Col span={24}>
                                            <Divider />
                                            <Button className={"orangeButton"} htmlType="submit" style={{float:"right"}}>
                                                {t("Shraniti")}
                                            </Button>
                                        </Col>
                                    </Row>


                                </Card>
                            </Col>
                            
                        </Row>
                    </Form>
                    
                    
                    
                </Col>
                
                <Col span={8}>
                    <Card style={{marginTop: "20px", height: "490px"}} title={<span style={{color: "#0c5e9b"}}>
                        {t("Payment_and_subscription_settings")}
                    </span>}>
                       
                        <div style={{textAlign: "center", marginBottom: "20px"}}>
                            <span >Powered by </span> 
                            <img src={"img/stripe_icon2.png"} style={{width: "50px", margin: "0 auto", verticalAlign: "bottom"}}/>
                        </div>

                        {user.groupStatus===2? 
                            <p style={{color:"red"}}>
                                We have problem with your Subscription. Please update your payment settings <br/>
                            </p> : <></>}


                        {t("We_use_the_tried")}<br/><br/>
                        {t("If_you_would_like_to_change")}
                        <br/><br/>
                       
                       
                        <a href={portal_url} target={"_blank"} 
                           style={{color: "#0c5e9b", borderBottom: "1px solid #0c5e9b", paddingBottom: "2px"}}>
                            {t("Go_to_Payment_Settings")} <ExportOutlined /> </a>
                    </Card>
                </Col>
                
                

            </Row>
                









        </div>
    )};
export default UserSettingsPage;