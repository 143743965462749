import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Layout, Space, message, Button, MenuProps, Dropdown} from 'antd'
import {DownOutlined, UserOutlined} from "@ant-design/icons";
import BreadCrumbsComp from "./BreadCrumbsComp";

import * as AuthService from "../Services/auth-service";

import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
//import {NOTIFICATION_URL} from "../Services/auth-service";
import authHeader from "../Services/auth-header";
import {NotificationContext} from "./Notifications/NotificationContext";
import {NotificationCentre} from "./Notifications/NotificationCenter";
import i18n from "i18next/";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {DATA_URL} from "../Services/auth-service";






const { Header } = Layout;
const messages:string[] =[];




const AppHeader = () => {
    const user1 = AuthService.getCurrentUser();
    //console.log("user1", user1);


    const history= useHistory();
    
    const [userslang, setuserslang] = useState("en");
    const [langIcon, setlangIcon] = useState("en");
    const [langchecked, setlangchecked] = useState(false)

    const [headerClass, setheaderClass] = useState("normalHeader");//#f4f6f9

    //console.log("user1: ", user1);

    //console.log("new Date(user1.paidTill): ", new Date(user1.paidTill));
    //console.log("new Date(): ", new Date());


    //const[ReloadSuggested, setReloadSuggested]=useState("-")


    

    useEffect(()=>{
        //console.log("AuthService.getCurrentUser().language: ", AuthService.getCurrentUser().language)
        
        switch(AuthService.getCurrentUser().language) {
            case "en": {
                setuserslang("English");
                setlangIcon("en")
                break;
            }
            case "si": {
                setuserslang("Slovenščina");
                setlangIcon("si")
                break;
            }
            case "fr": {
                setuserslang("Français");
                setlangIcon("fr")
                break;
            }
            case "de": {
                setuserslang("Deutsch");
                setlangIcon("de")
                break;
            }
            case "ru": {
                setuserslang("Русский");
                setlangIcon("ru")
                break;
            }
            case "it": {
                setuserslang("Italiano");
                setlangIcon("it")
                break;
            }
            case "uk": {
                setuserslang("Українська");
                setlangIcon("uk")
                break;
            }


            default: {
                setuserslang("English");
                setlangIcon("en")
                break;
            }
        }

        if(new Date(user1.paidTill)< new Date()){
            //console.log("dsfsdfsfsfsfdfsf");
            setheaderClass("redHeader")

        }
    })
    
    


    const handleLanguageClick: MenuProps['onClick'] = (e) => {
        var muser : any = {};
        const tt: any = localStorage.getItem("user")===null ? "" : localStorage.getItem("user");
        muser = JSON.parse(tt===null ? "-" : tt);
        muser.language = e.key;
        localStorage.setItem("user", JSON.stringify(muser));
        i18n.changeLanguage(e.key);

        axios.put(AuthService.DATA_URL+'Settings/change-language?lng='+e.key,
            {
                "lng": e.key
            },{headers: authHeader()})
            .then((response) => {
                i18n.changeLanguage(e.key);
//                console.log(e.key)
            })

    };

    const items: MenuProps['items'] = [
        { key: 'en', icon: <img src="/img/lang/en.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; English</span> },
        { key: 'si', icon: <img src="/img/lang/si.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Slovenščina</span> },
        { key: 'fr', icon: <img src="/img/lang/fr.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Français</span> },
        { key: 'de', icon: <img src="/img/lang/de.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Deutsch</span>},
        { key: 'ru', icon: <img src="/img/lang/ru.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Русский</span> },
        { key: 'it', icon: <img src="/img/lang/it.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Italiano</span> },
        { key: 'uk', icon: <img src="/img/lang/uk.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Українська</span> },
    ]
    const menuProps = {
        items,
        onClick: handleLanguageClick,
    };






    return (
        
          
                <Header
                    className={headerClass}
                    style={{
                        backgroundColor: "#fff !important"

                       // margin: '6px 8px 0px 0px',

                    }}
                >
                    {/*  <NotificationCenter/>*/}
                    <Space style={{float: 'left', height: '64px'}} align="center">
                        <BreadCrumbsComp/>

                    </Space>
                    

                        
                    <Space style={{float: 'right', height: '64px'}} align="center">
                        {user1.displayName?  user1.displayName : user1.omdUserName}
                        {
                            user1.profileImage===""?
                                <Avatar icon={<UserOutlined/>}></Avatar>
                                :
                                <img src={`${user1.profileImage}`} style={{width: "33px" ,
                                    border: "1px solid #ddd",
                                    borderRadius: "50%", 
                                    verticalAlign: "middle"}}/>
                        }

                    </Space>
     
                    <Space style={{float: 'right', marginRight: "20px"}}>
                        <NotificationCentre/>
                       
                        <Dropdown menu={menuProps}>
                            <Button style={{marginTop: "10px"}}>
                                <Space>
                                    <img src={"/img/lang/"+langIcon+".svg"}  style={{width:"12px", height: "12px"}}/>
                                    {userslang}
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </Space>
                    
                    {headerClass === "redHeader" ?
                        <div style={{marginRight: "100px", color: "#fff", display: "inline-block", float: "right"}}>
                            Оплаченный период закончился. Пожалуйста,
                            <a className="redHeadera" href="/settings?tab=3">продлите подписку</a>
                        </div> : ""}


                </Header>
            
       

        );
    };
export default AppHeader;