import React, {useCallback, useState, useEffect, useContext} from "react";
import {DATA_URL} from "../../Services/auth-service";
import axios from "axios";
import authHeader from "../../Services/auth-header";
import Calculation from "../../types/Calculation";
import {subscribe, unsubscribe} from "../../Components/Notifications/NotificationContext";
import {message} from "antd";
import CheckoutForm from "../ServicePayments/CheckoutPage";
import fetchauthHeader from "../../Services/fetch-auth-header";

interface CheckOutProps{
    calculation: Calculation
}

const QuotasPayment = ({calculation}: CheckOutProps) => {
    
    const[pay, setPay] = useState<string|null>(null);
    const l_calculation = localStorage.getItem("calculation");

    const fetchClientSecret = useCallback(() => {
        // Create a Checkout Session
        // @ts-ignore

        return fetch(DATA_URL+"ServicePayment/create-checkout-session", {
            method: "POST",
            headers: fetchauthHeader(),
            body: JSON.parse(JSON.stringify(localStorage.getItem("calculation")))//JSON.stringify(l_calculation)
        })
            .then((res) => res.json())
            .then((data) => data.clientSecret);
    }, []);

    const options = {fetchClientSecret};
    
    
    
    
    useEffect(()=>{
        
        
        subscribe("paymentReceived", (data: any) => reloadSuccess());

        if(pay===null){
            console.log("calculation: ", JSON.parse(JSON.stringify(localStorage.getItem("calculation"))))


            setPay("--");
            /*
            axios.post(DATA_URL+"ServicePayment/create-checkout-session",
                JSON.parse(JSON.stringify(localStorage.getItem("calculation"))), 
                {headers:authHeader()})
                .then((res: any)=>{
                    //setSecret(res);
                    setPay("--");
                    console.log(res)
                })
                
             */
        }
        
        
        
        return () => {
            unsubscribe("paymentReceived", (data: any) => reloadSuccess());
        }
    })
    
    const reloadSuccess=()=>{
        message.success("payed!")
    }
    
    return(<>
    waiting for payment
        <br/>
        {options}
    </>)
    
}

export default QuotasPayment;