import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import {Button, Card, Col, Divider, Form, InputNumber, message, Row, Space, Spin} from "antd";
import Calculation from "../../types/Calculation";
import axios from "axios";
import {DATA_URL} from "../../Services/auth-service";
import authHeader from "../../Services/auth-header";
import OmdGroup from "../../types/Group";
import {subscribe, unsubscribe} from "../../Components/Notifications/NotificationContext";
import {ToMoney} from "../../utils/helpers";

const QuotasPage = () => {
    const XLSX = require('xlsx');
    const {t} = useTranslation();
    const history = useHistory();

    ///

    const user = AuthService.getCurrentUser();
    if (!user) {
        AuthService.logout();
        history.push("/login");
        window.location.reload();

    }


    const[calculation, setCalculation]=useState<Calculation>({groupTitle: " ",tarif:20, additionalMembers:0, additionalDocs:0})
    const [buttonText, setButtonText]=useState(t("Set_limit"))
    const [isWaitingPayment, setisWaitingPayment] = useState(false)
    const[membersQuantity, setmembersQuantity]=useState(5);
    const [theGroup, setTheGroup] = useState<OmdGroup|null>(null)
    const[isPageLoading, setisPageLoading]=useState(false);
    
   


    
    
    const regetGroup=()=>{
        setisPageLoading(true)
        axios.get(DATA_URL+"Groups/get-my-group", {headers:authHeader()})
            .then((response=>{
                console.log(response.data)
                if(response.data.length!=0){
                    setTheGroup(response.data);
                    setmembersQuantity(response.data.maxSize);
                    setCalculation({groupTitle: " ", tarif: 20, additionalMembers: 0, additionalDocs: 0})
                    setisPageLoading(false)
                }


            }))
            .catch(function (error) {
                if(error.response.status===401){
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();

                }
            })
    }

    
    const payOk=()=>{
        setisWaitingPayment(false);
        setCalculation({groupTitle: " ", tarif: 20, additionalMembers: 0, additionalDocs: 0})
        regetGroup()
        
    }

    const payNo=()=>{
        setisWaitingPayment(false);
        setCalculation({groupTitle: " ", tarif: 20, additionalMembers: 0, additionalDocs: 0})
        regetGroup()

    }

    useEffect(()=>{
        subscribe("paymentReceived", (data: any) => {payOk()});
        subscribe("paymentFailed", (data: any) => {payNo()});
        if(theGroup===null){
            regetGroup()

        }

        return () => {
            unsubscribe("paymentReceived", (data: any) => {payOk()});
            unsubscribe("paymentFailed", (data: any) => {payNo()});
        }
    })

    const changeDocsQty=(value: number)=>{
        setCalculation(prev => ({
            ...prev,
            additionalDocs: value  }));
        
    }

    const changeMembersQty =(value:number)=>{
        setCalculation(prev => ({
            ...prev,
            additionalMembers: value-(theGroup===null? 5 : theGroup.maxSize===null? 5 :theGroup.maxSize===undefined? 5: theGroup.maxSize)
        }));
    }

    const checkout =()=>{

        console.log(calculation);
        setisWaitingPayment(true);
        axios.post(DATA_URL+"ServicePayment/create-checkout-session",
            calculation,
            {headers:authHeader()})
            .then((res: any)=>{
                //setSecret(res);
                setisWaitingPayment(true);
                console.log(res)
                if(res.data.length!==""){
                    console.log(res.data.httpStatusCode)
                    console.log("res.data.message: ", res.data.message)
                    setisWaitingPayment(false);
                    message.error(res.data.message);
                }
            })

        /*
       localStorage.removeItem("calculation");
       localStorage.setItem("calculation", JSON.stringify(calculation));
       history.push("/checkout");
       
         */
    }

    if(isPageLoading){
        return(
            <Spin style={{verticalAlign: "middle"}}>
                <div style={{height: "100vh"}}> </div>
            </Spin>


        )
    }

    if(isWaitingPayment){
        return <div style={{paddingTop: "100px"}}>
        
        <Card style={{width: "300px", margin: "auto auto"}}>
            <Spin/><br/><br/>
            {t("Checking_the_payment")} <br/>
            {t("This_could_take_a_while")}

        </Card>    
        
        
        </div>
    }

    return <>





      
        <Row gutter={20}>
            <Col span={12}>
                <Card  style={{height: "400px"}}>
                    <h3 style={{color: "#0c5e9b"}}>{t("Current_group_limit")}: {theGroup?.maxSize}</h3>

                    <Row>
                        <Col span={24}>
                            <Form
                                onFinish={checkout}
                                initialValues={
                                    {
                                        ["quantity"]: membersQuantity===null? 5 : membersQuantity
                                    }
                                }
                            >



                                <Space>
                                    <span>{t("Set_limit")}:</span>
                                    <Form.Item  name={"quantity"} noStyle>
                                        <InputNumber min={(theGroup===null? 5 : theGroup.maxSize? theGroup.maxSize : 5)} size="large"
                                                     onChange={(e)=> {changeMembersQty(e===null? 5: e)}}/>
                                    </Form.Item>
                                    <span  style={{textAlign: "left", color: "#777"}}>
                                        {calculation.additionalMembers>0?
                                            <span style={{marginLeft: "10px"}}>{t("Additional_more")} {calculation.additionalMembers}</span>
                                            :""
                                        }
                                    </span>

                                </Space>



                            </Form>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Divider/>
                            {calculation.additionalMembers >0 ?
                                <span>
                                            {t("Additional_slots")}: {calculation.additionalMembers} x 5.00€ = {calculation.additionalMembers*5}.00€
                                        </span>
                                :
                                <span>{t("No_change")}</span>

                            }
                            <Divider/>

                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{textAlign: "center", paddingTop: "20px"}}>
                            {calculation.additionalMembers >0 ?
                                <>
                                    <h3>{t("Total_to_pay")}: {calculation.tarif===20?
                                        (calculation.additionalMembers*5).toFixed(2) :
                                        (calculation.additionalMembers*5*12).toFixed(2)
                                    }€
                                        <Button
                                            style={{marginLeft:"20px"}}
                                            htmlType="submit" size={"large"}  className={"orangeButton"}
                                            onClick={()=>{checkout()}}>
                                            {buttonText}</Button>
                                    </h3>
                                </>
                                :
                                <h3>{t("No_need_to_pay_anything")}</h3>}


                        </Col>
                    </Row>



                </Card>



            </Col>
            <Col span={12}>
                <Card style={{height: "400px"}}>
                    <h3 style={{color: "#0c5e9b"}}>{t("Current_document_limit")}: {theGroup?.maxDocs}</h3>
                    {t("Used_as_part_of_subscription")}:&nbsp;
                    {(theGroup?.currentDocsUsage? theGroup?.currentDocsUsage : 0) > (theGroup?.maxDocs ? theGroup?.maxDocs : 0) ? 
                    <span style={{color: "#E3601D", fontWeight: "600"}}>{theGroup?.currentDocsUsage? theGroup?.currentDocsUsage : 0}</span>
                        :
                        <span>{theGroup?.currentDocsUsage? theGroup?.currentDocsUsage : 0}</span>}
                    
                    /{theGroup?.maxDocs}
                    <Row>
                        <Col span={24}>
                            {(theGroup?.currentDocsUsage? theGroup?.currentDocsUsage : 0) > (theGroup?.maxDocs ? theGroup?.maxDocs : 0) ?
                                <span>
                                    {t("Additionally_used")}: {(theGroup?.currentDocsUsage? theGroup?.currentDocsUsage : 0) - (theGroup?.maxDocs ? theGroup?.maxDocs : 0)}
                                    <br/>
                                    {t("Charge_for_additional_use")}<br/>
                            12 x {ToMoney(0.5)} = {ToMoney(6)} <br/>
                                    {t("will_be_charged")} 12.05.2024

                                
                                
                                </span>
                                :
                                <span>
                                    {t("Available_until_the_end_of_the_period")}: {(theGroup?.maxDocs? theGroup?.maxDocs : 20) - (theGroup?.currentDocsUsage ? theGroup?.currentDocsUsage : 0)}
                                    <br/><br/><br/><br/>
                                </span>
                            }
                                
                            
                            <Divider/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {t("To_save_money")}

                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        
                        <Col span={24}>
                            <Space>
                                <Form.Item  noStyle>
                                    <InputNumber min={1} size="large"
                                                 onChange={(e)=> {changeDocsQty(e===null? 0: e)}}/>
                                </Form.Item>
                                <span>
                                     +{calculation.additionalDocs*10} {t("ofdocuments")} х {ToMoney(0.5)} <br/> = {ToMoney(calculation.additionalDocs*5)}/{t("Per_month")}
                                </span>
                               
                                    <Button className={"orangeButton"} style={{marginLeft: "20px"}} onClick={()=>{checkout()}}>
                                        {t("Set_limit")}
                                    </Button>
                            </Space>
                        </Col>
                    </Row>

                    
                
                
                
                </Card>
            </Col>
        </Row>

    </>
}

export default QuotasPage;
