import React, {useEffect, useState} from 'react';
import {
    Button,
    Card, Checkbox,
    Col, DatePicker, Divider, Drawer,
    Form,
    Image,
    Input,
    InputNumber,
    List,
    message,
    Row,
    Select,
    Space,
    Spin,
    Steps,
    Tabs, Tooltip, ConfigProvider, StepsProps, Popover, SelectProps, Dropdown, MenuProps, 
} from 'antd';
import {DownOutlined, LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined} from '@ant-design/icons';

import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import {useTranslation} from "react-i18next";
import {
    dateForServer,
    dateForServerFromDate,
    dateToPoints,
    mapRequestStatusToStep,
    ServerdateToPoints
} from "../../../utils/helpers";
import dayjs from "dayjs";
import {json} from "stream/consumers";
import DealLogsTimeLine from "./DealLogsTimeLine";



interface Params {
    id: string;
}


export default function DealsDetailPage() {
    return <>dfsf</>
}
