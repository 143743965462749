import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";
import type { ColumnsType } from 'antd/es/table';

import {EditOutlined, UserOutlined, HourglassOutlined,  EnterOutlined,
    CheckCircleOutlined, SyncOutlined, MailOutlined} from '@ant-design/icons';

import type { InputRef } from 'antd';
import {
    Button, Card, Checkbox, Col, DatePicker, Divider, Drawer,
    Form, Image, Input, InputNumber, List, message, Row, Select, Space, Spin, Steps,
    Tabs, Tooltip, ConfigProvider, StepsProps, Popover, SelectProps, Dropdown, MenuProps, Modal, Avatar, Table,
} from 'antd';
import OmdGroup from "../../../types/Group";
import InviteForm from "./InviteForm";
import {DATA_URL} from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import GroupsUsers from "../../../types/GroupsUsers";
import {ServerdateToPoints} from "../../../utils/helpers";
import MembersTable from "./MembersTable";
import EventLogPage from "./EventLog";
import {publish} from "../../../Components/Notifications/NotificationContext";


interface Params {
    id: string;
}
export default function GroupDetailPage() {
    const {t} = useTranslation();
    const {id} = useParams<Params>();

    const history = useHistory();

    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }

    const[isPageLoading, setisPageLoading]=useState(false);
    const [theGroup, setTheGroup] = useState<OmdGroup|null>(null)
    const[members, setMembers] = useState<GroupsUsers[]|null>(null)
    const[reload, setReload] = useState(false)
    
    const [filter, setFilter]=useState("")

/*
    const [theGroup, setTheGroup] = useState<OmdGroup>(
        {
        id: id==="0" ? "00000000-0000-0000-0000-000000000000" : id,
        groupStatus:1,
    });
    
 */
    

    
    
    useEffect(()=>{
        if(theGroup===null){
            setisPageLoading(true)
            axios.get(DATA_URL+"Groups/get-my-group", {headers:authHeader()})
                .then((response=>{
                    if(response.data.length!=0)
                        setisPageLoading(false)
                        setTheGroup(response.data);
                    
                }))
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
        }


    })
    




    const titleInput = useRef(null);
    const inputRef = useRef<InputRef>(null);

    const [form] = Form.useForm();
    
    const [editMode, setEditMode]=useState(false);
    
    
    
    
    

    const [isIncomeOpen, setisIncomeOpen]=useState(false)

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleOk = () => {
        //setModalText('The modal will be closed after two seconds');
        
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
        
         
       
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };
    
    const CloseModals=()=>{
        setisIncomeOpen(false)
    }


    const checkTitle=(e:any)=>{
        setTheGroup(prev => ({
            ...prev,
            title : e
        }));
    }
    const onFinish=()=>{

        console.log("finish");
        axios.put(DATA_URL+"Groups/change-title?title="+theGroup?.title,
            null ,
            {headers:authHeader()})
            .then((result)=>{
                console.log("result", result)
                message.success(t("Done"));
            });
        publish("groupChanges","");
        
    }

    if(isPageLoading){
        return(
            <Spin style={{verticalAlign: "middle"}}>
                <div style={{height: "100vh"}}> </div>
            </Spin>


        )
    }






    
    return <>
        
        

        

    <Form
        form={form}
        name="wrap"
        onFinish={onFinish}
        autoComplete="off"
        layout="horizontal"
        labelCol={{ flex: '150px' }}
        labelAlign="right"
        labelWrap
        //onChange={(e)=>OnChange(e)}
        wrapperCol={{ flex: 1 }}
        colon={false}
        initialValues={
            {["title"]: theGroup?.title ? theGroup?.title : ""}
        }
    >

        <Row gutter={20} style={{marginBottom: "40px"}}>
            <Col span={12}>
                <h1 style={{marginBottom: 0}}>
                   
                    <Space.Compact style={{borderBottom: "1px solid #0c5e9b"}}>
                        <Space>
                        <Input variant={"borderless"}  ref={inputRef}
                               size={"large"}
                               onChange={(e)=>{checkTitle(e.target.value)}}
                               style={{fontSize: "24px", color: "#0c5e9b", fontWeight: "600"}}
                               value={theGroup?.title ? theGroup?.title : ""} />
                   
                        </Space>
                        <Space>
                            {
                                editMode ?
                                    <Button htmlType={"submit"}
                                            style={{fontSize: "16px", lineHeight: "26px",
                                                border: "none", backgroundColor: "transparent",
                                                verticalAlign: "middle", marginLeft: "10px", cursor: "pointer"}}
                                            onClick={()=>{
                                                setEditMode(false)
                                            }}
                                    >
                                        <EnterOutlined style={{fontSize: "16px",color: "#0c5e9b"}}
                                        />
                                    </Button>

                                    :
                                    <EditOutlined style={{fontSize: "16px", lineHeight: "26px",
                                        verticalAlign: "middle", marginLeft: "10px", cursor: "pointer"}}
                                                  onClick={()=>{
                                                      setEditMode(true)
                                                      inputRef.current!.focus({
                                                          cursor: 'end',
                                                      });

                                                  }}
                                    />
                            }
                            

                        </Space>
                    </Space.Compact>
                </h1>
               
            </Col>
            
        </Row>

    </Form>


        <Row>
            <Col span={24}>
                <Tabs
                    //defaultActiveKey={tabActiveKey}
                    //activeKey={tabActiveKey}
                    //tabBarStyle={{display: "none"}}
                    items={[
                        {
                            label: t("Participants"),
                            key: '1',
                            children:  <MembersTable/>,
                        },
                        {
                            label: t("Event_log"),
                            key: '2',
                            children:  <EventLogPage/>,

                        },

                    ]}
                />
                
            </Col>
            
        </Row>


        





    
    </>
}

    
    
    