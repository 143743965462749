import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import "./Services/multiLang";


import {BarChartOutlined, TeamOutlined,InfoCircleOutlined, DashboardOutlined, HomeOutlined, FileTextOutlined, IdcardOutlined, SettingOutlined, LogoutOutlined} from '@ant-design/icons';
import {Button, ConfigProvider, Layout, Menu, message, theme} from 'antd';
import ApiHeader from "./Components/AppHeader"
import './App.css';

import {Routing} from "./Pages";


import * as AuthService from "./Services/auth-service";
//import {joinChatRoom, NOTIFICATION_URL} from "./Services/auth-service";
import Login from "./Pages/Login";
import RacunPage from "./Pages/Racun";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import RegisterPage from "./Pages/Register";
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
//import {NOTIFICATION_URL} from "./Services/auth-service";
import authHeader from "./Services/auth-header";
import {NotificationContext, subscribe, unsubscribe} from './Components/Notifications/NotificationContext';
import OmdGroup from "./types/Group";
import {refreshUser} from "./Services/auth-service";


const {  Sider, Content } = Layout;



const App = () => {
    
    const {t} = useTranslation();

    const history= useHistory();
    const locationString = window.location;
    const [current, setCurrent] = useState(locationString.pathname);
    //console.log("locationString.pathname:", locationString.pathname )
    function handleClick(e: any) {
        setCurrent(e.key);
    }

    

    const [disabledMenu, setdisabledMenu]=useState<boolean|null>(null)

    const user = AuthService.getCurrentUser();
    //console.log("current user: ", user)

    if(user){
        if(user.groupStatus){
            if(user.groupStatus===2){
                history.push("/settings");
            }
        }
    }

    const [reloadSuggested, setreloadSuggested] = useState('');
    const value = useMemo(
        () => ({ reloadSuggested, setreloadSuggested }),
        [reloadSuggested]
    );
    
    function ContentDiv() {
        return <div><Routing/></div>;
    }

    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [show, setShow] = useState(false);
    

    
    var imgsrc: string;
    var imgsrc2: string;
    imgsrc="/img/collapse_btn.png";
    imgsrc2="/img/collapse_btn2.png";
    
    const logOut = () => {
        AuthService.logout();
        history.push("/login");
        window.location.reload();
    };


    const refresh=()=>{
        setdisabledMenu(false)
    }



    const unsubscribeout=()=>{
        logOut();
        history.push("/login");
        window.location.reload();
    }

    const [groups, setGroups] = useState<OmdGroup[]|null>(null);

    useEffect(()=>{ 
        subscribe("subscriptionCanceled", ()=>{logOut()})
        subscribe("paymentReceived", ()=>{refreshUser()})
        subscribe("groupCreated", ()=>{refresh()})
        if(user){
            console.log("App tsx: ", user)
            if(disabledMenu===null){
                if(user.groupId===null || user.groupId===""){
                    setdisabledMenu(true)
                }
                else{
                    setdisabledMenu(false)
                }
                
            }
        }
        
        if (!user) {
            if (window.location.toString().indexOf("register") > 0) {
                history.push("/register")
                //return (<RegisterPage/>);
            } else {
                if(window.location.toString().indexOf("checkout")>0){
                    //history.push("/checkout");
                    //return(<CheckOutPage/>);
                }
                else{
                    if(window.location.toString().indexOf("login")>0){
                        history.push("/login");
                        //window.location.reload();
                    }
                    else{
                        history.push("/");
                    }
                     //return(<Login/>);
                }
               // return(<Login/>);
            }
            //return(<Login/>);
            //window.location.reload();
        }

        return () => {
            unsubscribe("groupCreated", () => refresh());
            unsubscribe("paymentReceived", ()=>{refreshUser()})
            unsubscribe("subscriptionCanceled", ()=>{logOut()})

        }
    
    })



        //console.log("useruseruser", user.language);
        if(user) {
            if (user.language) {
                if (i18n.language !== user.language) {
                    i18n.changeLanguage(user.language);
                }
            }
        }

    

    const queryString = window.location.search;
    
    //console.log(window.location.toString().indexOf("register"))
    if(!user){
        if(window.location.toString().indexOf("register")>0){
            //console.log("Redirect to Register")
            //history.push("/register")
            return(<RegisterPage/>);

        }else{
                if(window.location.toString().indexOf("login")>0){
                    return(<Login/>);
                }
                else{
                    history.push("/login")
                }
            }
            //history.push("/login")
            
        }
        
    


    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get('racun');
    const regValue = urlParams.get('register');

    const requestValue = urlParams.get('request');

    const saufValue = urlParams.get('sauf');


    if(paramValue){
        return(<RacunPage/>);
    }
    if(regValue){
        return(<RegisterPage/>);
    }




    return (
                    <div className="App">
                        <ConfigProvider
                            theme={{
                                token: {
                                    // Seed Token
                                    colorPrimary: '#0e2640',
                                    colorLink: '#0d2640',
                                    lineWidth: 1,
                                },
                                components: {
                                    Input: {
                                       // activeBorderColor: "#000",
                                    },
                                    Button: {
                                        colorPrimary: '#0e2640',
                                    }
                                },
                            }}>

                          
                       <NotificationContext.Provider value={{reloadSuggested: ""}}>
                          
                      
                        <Layout style={{height: "100vh"}}>
                            {/*
                            */}
                            
                            <Sider trigger={null} collapsible collapsed={collapsed} width={250}>
                               
                                <Menu
                                    theme="light"
                                    mode="vertical"

                                    selectedKeys={[current]}
                                    onClick={(key) => {
                                        handleClick(key);
                                        history.push(key.key);
                                    }}

                                    items={[
                                        {
                                            key: '/',
                                            className: 'ant-menu-item-disabledlogo',
                                            icon: <><center><img src={"/img/loginlogo.png"} alt={" "} style={{
                                                width: "100px",  display: "block", marginTop: "12px", marginBottom: "-10px"
                                            //    height: '30px', margin: '10px 0px 0px 0px'
                                            }}/><span style={{color: "#fff", fontSize:"11px"}}>User Manager Console</span>
                                            
                                            </center></>,
                                            //icon: <UserOutlined />,
                                            //label: label,
                                            //disabled: true,
                                        },
                                        {
                                            key: '1',
                                            type: 'divider',
                                        },


                                        /*
                                        {
                                            key: '/',
                                            icon: <HomeOutlined  style={{fontSize: '18px'}} />, //<img src="/img/home_icon.png" alt={" "}
                                                       //style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: t("Domov"),
                                        },
                                        
                                         */
                                        
                                        {
                                            key: '/groups',
                                            icon:  <TeamOutlined style={{fontSize: '18px'}}/>,//<img src="/img/steering.png" alt={" "}
                                            //style={{float: "left", height: '18px', margin: '10px 1px'}}/>, //<TeamOutlined style={{fontSize: '18px'}}/>,
                                            label: t('Groups'),
                                        },


                                        /*
                                        {
                                            key: '/trucks',
                                            icon: <img src="/img/truck_icon.png" alt={" "}
                                                       style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: t('Vozila'),
                                        },
                                        
                                         */
                                        {
                                            key: '/users',
                                            icon: <IdcardOutlined  style={{fontSize: '18px'}}/>,//<TeamOutlined style={{fontSize: '18px'}}/>,
                                            label: t('Participants'),
                                            disabled: disabledMenu===null ? false : disabledMenu,

                                        },
                                        {
                                            key: '/quotas',
                                            icon: <DashboardOutlined   style={{fontSize: '18px'}} />,//<img src="/img/routes_icon.png" alt={" "}
                                            //     style={{float: "left", height: '16px', margin: '10px 0px'}}/>,
                                            label: t('Quotas'),
                                            disabled: disabledMenu===null ? false : disabledMenu,
                                        },

                                        /*

                                        {
                                            key: '/transactions',
                                            icon: <EuroOutlined  style={{fontSize: '18px'}}/>,// <img src="/img/transactions_icon.png" alt={" "}
                                                       //style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: t('Finances'),
                                        },
                                        
                                         */


                                        /*
                                        {
                                            key: '/cards',
                                            icon: <img src="/img/cards_icon.png" alt={" "}
                                                       style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: 'Карты',
                                        },
                                        
                                         */
                                        
                                        {
                                            key: '/settings',
                                            icon: <SettingOutlined  style={{fontSize: '18px'}} />,// <img src="/img/settings_icon.png" alt={" "}
                                                      // style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: t('Nastavitve'),
                                            disabled: disabledMenu===null ? false : disabledMenu,
                                        },
                                        {
                                            key: '11',
                                            type: 'divider',
                                        },

                                        {
                                            key: '/about',
                                            icon: <InfoCircleOutlined  style={{fontSize: '18px'}}/>, //<img src="/img/docs_icon.png" alt={" "}
                                            //style={{float: "left", height: '20px', margin: '10px 0px'}}/>,
                                            label: t('About'),
                                            disabled: disabledMenu===null ? false : disabledMenu,
                                        },
                                        {
                                            key: '/logout',
                                            onClick :(logOut),
                                            icon: <LogoutOutlined  style={{fontSize: '18px'}}/>, //<img src="/img/logout_icon.png" alt={" "}
                                                      // style={{float: "left", height: '15px', margin: '10px 0px 10px 6px'}}/>,
                                            label: t('Odjava'),
                                            style : {
                                            position: 'absolute',
                                            bottom: 20,
                                            zIndex: 1,
                                            transition: 'all 0.2s',
                                        }
                                        },

                                    ]}
                                />

                            </Sider>
                            {/* 
                            <Button id={'colbtn'}
                                    type="text"
                                    icon={<img src={show ? imgsrc2 : imgsrc} alt={" "}
                                               style={{float: "left", height: '29px'}}/>}
                                    onClick={() => {
                                        setCollapsed(!collapsed);
                                        setShow(!show)
                                    }}
                                    style={{
                                        top: '35px',
                                        left: '-18px',
                                    }}
                            />
                            */}
                            
                            <Layout>
                               
                                <ApiHeader/>

                                <Content
                                    style={{
                                       // margin: '0px 8px 6px 0px',
                                        minHeight: 280,
                                        //background: colorBgContainer,
                                    }}

                                >
                                    <ContentDiv/>
                                </Content>
                      
                             
                            </Layout>
                        </Layout>

                            
                       </NotificationContext.Provider>
                      
                    </ConfigProvider>
                    </div>
                )
}



export default App;
