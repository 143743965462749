import axios from "axios";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import authHeader from "./auth-header";
import tokenHeader from "./token-header";
import {message} from "antd";


///
/// App urls
///
///
//const API_URL = "http://localhost:5000/api/Authenticate/";
//const API_URL = "http://85.215.187.128:5000/api/Authenticate/";
//const API_URL = "https://api.logiex.eu/api/Authenticate/";
//const API_URL = "http://137.116.249.159:5000/api/Authenticate/";
///
///
///
////
//export const DATA_URL="https://api.logiex.eu/api/";

//export const DATA_URL="http://85.215.187.128:5000/api/";
//export const DATA_URL="http://137.116.249.159:5000/api/";


//export const DATA_URL="https://api.logiex.eu/api/";
//export const NOTIFICATION_URL="https://api.logiex.eu/notifications";


//export const DATA_URL="https://api.inbesi.online/api/";
//export const NOTIFICATION_URL="https://api.inbesi.online/notifications";


//export const DATA_URL="https://api.omd.fintim.online/api/";
//export const NOTIFICATION_URL="https://api.omd.fintim.online/notifications";

//export const DATA_URL="https://omdapi.fintim.online/api/";
//export const NOTIFICATION_URL="https://omdapi.fintim.online/notifications";


export const DATA_URL = process.env.NODE_ENV == 'production'? "https://console.onmydisk.net:7000/api/" : "http://localhost:7000/api/";
export const NOTIFICATION_URL= process.env.NODE_ENV == 'production' ? "https://console.onmydisk.net:7000/notifications" : "http://localhost:7000/notifications";

//export const DATA_URL = "https://127.0.0.1:7000/api/";
//export const NOTIFICATION_URL=  "https://127.0.0.1:7000/notifications";



////
export const register = (username: string, email: string, password: string, contractor : string, 
                         isTransporter : boolean,isOperator: boolean, 
                         AcceptOpenOrders: boolean,AcceptOpenReplies: boolean,
                         name? :string |undefined, surname? : string|undefined
) => {
    return axios.post(DATA_URL + "Authenticate/register/", {
        "username" : username,
        "email" : email,
        "password" : password,
        "contractorname": contractor,
        "isTransporter" : isTransporter,
        "isOperator" : isOperator,
        "AcceptOpenOrders" : AcceptOpenOrders,
        "AcceptOpenReplies" : AcceptOpenReplies,
        "Name": name,
        "Surname" : surname
        
    })
        .then(
            (result) => {
                
                console.log("register result: ", result);
                //history.replace("/Dashboard");
               // window.location.reload();
            })
        .catch((error)=>{
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            console.log(resMessage);

        })
        
        ;
};

export const login = (username: string, password: string) => {
    return axios
        .post(DATA_URL + "Authenticate/login", {
            username,
            password,
        }, 
//            {headers: {"Access-Control-Allow-Origin": "*"}}
        )
        .then((response) => {
           // console.log("login localStorage: ", localStorage);
           // console.log("login response: ", response.data);
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));


                //const [conn, setConnection] = useState<HubConnection>();
                
                
            }

            return response.data;
        });
};


export const refreshUser = () => {
    return axios
        .get(DATA_URL + "Authenticate/refresh-my-user", {
            headers: authHeader()
        },)
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};


/*
export  const  joinChatRoom = async (user: any, msg: string) => {
    try{
        console.log("user: ", user)
        //initiate connection
        const conn= new HubConnectionBuilder()
            .withUrl(NOTIFICATION_URL
                , {headers: authHeader()}
            //,{headers: tokenHeader()}
            )
            .build();

        //handler
        await conn.on("ReceiveNotifiction", (msg)=>{
            if(msg.objectType==="hello"){
                console.log("msg:", msg);
            }
            else{
                message.success(msg.message)
            }
        })
        await conn.start();
        const username= user.contractorId
        await conn.invoke("JoinChat", {username, msg})
        //return conn;
    }
    catch (e) {
        console.log("exception: ", e);
        return null;
    }
}

 */


export const logout = () => {
    localStorage.removeItem("user");
};

export const getCurrentUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
};