import CommonListFilter from "../types/CommonListFilter";


export const dateForServer= (date: string|null|undefined) =>{
    
    return date ? (date.trim().substring(6,10)+"-"+date.substring(3,5)+"-"+date.substring(0,2)+"T00:00:00Z") : null
}



export const CommonFilterToQuery=(filter : CommonListFilter)=>{
    
   // if(filter.dateFrom)
}

export const translateFA=(fa_title: string)=>{
    switch ( fa_title ) {
        case "Ввод денег": return "Cas_injection";
        case "Выручка":return "Revenue"
        case "Аренда":return "Rent"
        case "Зарплата": return "Salary";
        case "Оплата услуг":return "Payment_for_services"
        case "Покупка товаров":return "Purchase_of_goods"
        case "Прочие расходы": return "Other_expenses";
        case "Налоги": return "Taxes";
        case "Вывод прибыли": return "Profit_withdrawal";
        default: return fa_title? fa_title : ""

    }
}

export const dateForServerFromDate= (date: any|null|undefined) =>{

    var month = '' + (date.get('month')+1),
        day = '' + date.get('date'),
        year = date.get('year');

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    
    
    return year+"-"+month+"-"+day+"T00:00:00Z";
}

export const ServerdateToPoints= (date: string|null|undefined) =>{

    return date ? date.substring(8,10)+"."+date.substring(5,7)+"."+date.substring(0,4) : ""
}

export const ServerdateToTimeString= (date: string|null|undefined) =>{

    console.log(date)
    return date ? date.substring(11,16) : ""
}

/*
export const hmapMonthNumberToString=(i: number)=>{
    const {t} = useTranslation();

    switch ( i ) {
        case 1: return t("January");
        case 2:return t("February")
        case 3:return t("March")
        case 4: return t("April");
        case 5:return t("May")
        case 6:return t("June")
        case 7: return t("July");
        case 8:return t("August")
        case 9:return t("September")
        case 10: return t("October");
        case 11:return t("November")
        case 12:return t("Декабрь")
        default:return "Месяц"

    }    
}

 */

export const mapDocTypeEnumToString=(i : number)=>{
    
    switch ( i ) {
        case -1:
            return "Dokument"
            break;
        case 0:
            return "Drug_dokument"
            break;
        case 10:
            return "Račun"
            break;
        case 11:
            return "CMR"
            break;
        case 20:
            return "Truck_foto"
            break;
        case 21:
            return "Truck_license"
            break;
        case 22:
            return "Truck_insuarence"
            break;
        case 30:
            return "Driver_foto"
            break;
        case 31:
            return "Driver_license"
            break;
        case 32:
            return "Driver_insuarence"
            break;
        default:
            return "Drug_dokument"
            break;
    }
}


export const getArticleITypeClass=(ArticleIType : number)=>{
    switch(ArticleIType) {
        case 10: {
            return "greendot";
        }
        case 11: {
            return "greendot";
        }
        case 12: {
            return "greendot";
        }
        case 13: {
            return "greendot";
        }
        case 20: {
            return "orangedot";
        }
        case 21: {
            return "greendot";
        }
        case 22: {
            return "greendot";
        }
        case 23: {
            return "greendot";
        }
        case 24: {
            return "greendot";
        }
        case 25: {
            return "greendot";
        }
        default: {
            return "graydot";

        }
    }
}

export const getArticleITypeColor=(ArticleIType : number)=>{
    switch(ArticleIType) {
        case 10: {
            return "rgb(31, 170, 115)";
        }
        case 11: {
            return "greendot";
        }
        case 12: {
            return "greendot";
        }
        case 13: {
            return "greendot";
        }
        case 20: {
            return "#E3601D";
        }
        case 21: {
            return "greendot";
        }
        case 22: {
            return "greendot";
        }
        case 23: {
            return "greendot";
        }
        case 24: {
            return "greendot";
        }
        case 25: {
            return "greendot";
        }
        default: {
            return "graydot";

        }
    }
}


export const MoneyToString=(num : number) =>{
    return num.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
}

export const IntToDate=(num: number) => {
    const date = new Date(num* 1000);
    return dateToPoints(new Date(num*1000))
    //return date.toLocaleDateString('en-US')
    
}



export const mapRequestStatusToStep=(status: number)=>{
    switch ( status ) {
        case 20:
            return 1;
        case 30:
            return 2
        case 40:
            return 3
        case 50:
            return 4
        default:
            return status ? status : 0
    }
}



export const ToMoney=(num : number) =>{
    return num.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")+" €"
}

/*
export const MoneyToString=(num : number) =>{
    return num.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
}

 */

export const MapLogEvent=(event: number)=>{
    switch ( event ) {
        //case 0: return "Created";
        case 1 :return "Created"
        case 2 :return "UserInvited"
        case 3: return "UserJoined";
        case 4:return "UserRejected"
        case 5 :return "UserSuspended"
        case 6 :return "UserDeleted"
        case 7 :return "GroupRenamed"

        default: return event.toString()
    }
}

export const dateToPoints =(today : Date) =>{
    const yyyy = today.getFullYear();
    let m = today.getMonth() + 1; // Months start at 0!
    let d = today.getDate();

    let mm = m.toString();
    let dd = d.toString();
    if (d < 10) dd = '0' + dd;
    if (m < 10) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy;
}



export const MapTarifTitle=(tarif: number)=>{
    switch ( tarif ) {
        case 0: return "Trial";
        case 4999 :return "CRM"
        case 9999 :return "Transport"
        case 14999: return "TransportPro";
        case 24999:return "Operator"
        case 29999:return "OperatorPro"
        default: return tarif.toString()
    }
}




/*
export const getArticleColor=(Article : string)=>{
    switch(Article) {
        case 10: {
            return "rgb(31, 170, 115)";
        }
        case 11: {
            return "greendot";
        }
        case 12: {
            return "greendot";
        }
        case 13: {
            return "greendot";
        }
        case 20: {
            return "#E3601D";
        }
        case 21: {
            return "greendot";
        }
        case 22: {
            return "greendot";
        }
        case 23: {
            return "greendot";
        }
        case 24: {
            return "greendot";
        }
        case 25: {
            return "greendot";
        }
        default: {
            return "graydot";

        }
    }
}


 */
 
