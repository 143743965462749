import React, {ReactNode, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {DATA_URL} from "../../Services/auth-service";
import authHeader from "../../Services/auth-header";
import GroupsUsers from "../../types/GroupsUsers";
import * as AuthService from "../../Services/auth-service";
import {useHistory, useParams} from "react-router-dom";
import {Card, Col, Descriptions, Form, Row, Spin, Timeline} from "antd";
import GroupsUsersDisplayModel from "../../types/GroupUserDisplayMidel";
import {MapLogEvent, ServerdateToPoints, ServerdateToTimeString} from "../../utils/helpers";
import {
    CheckCircleOutlined,
    DeleteOutlined, EditOutlined,
    HourglassOutlined,
    MailOutlined,
    PauseCircleOutlined
} from "@ant-design/icons";
import EventLog from "../../types/EventLog";


interface Params {
    id: string;
}

export default function UserDetailsPage() {
    const {t} = useTranslation();
    const {id} = useParams<Params>();

    const history = useHistory();

    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }
    const [form] = Form.useForm();
    
    const [userDetails, setuserDetails] = useState<GroupsUsersDisplayModel | null>(null)
    const [titems, setTitems] = useState<{ color: string, children: ReactNode}[] >([])

    const [isLoading, setisLoading] = useState(true);

    console.log("id: ", id);
    useEffect(()=>{
        if(userDetails===null){
            axios.get(AuthService.DATA_URL+'GroupsUsers/get-member-history?id='+id, {headers: authHeader()})
                .then((response)=>{
                    console.log(response.data)
                    setisLoading(false)
                    setuserDetails(response.data);
                    setTitems(response.data.userStory.map((log: EventLog)=>({
                        //label : ServerdateToPoints(log.eventDateTime),
                        dot: log.groupEvent===2 ? <MailOutlined /> :
                            log.groupEvent===5 ? <PauseCircleOutlined /> :
                                log.groupEvent===7? <EditOutlined />:
                                    log.groupEvent===6? <DeleteOutlined />:
                                        "",

                        color: log.groupEvent===3? "green" :
                            log.groupEvent===5? "#faad14" :
                                log.groupEvent===6? "#E3601D":
                                    "#1677ff",
                        children: <span>{ServerdateToPoints(log.eventDate)} <span style={{color: "#777", fontSize: "12px"}}>
                                {ServerdateToTimeString(log.eventDate)}
                            </span>  <br/>
                            {log.groupEvent==1 ? t(MapLogEvent(log.groupEvent)): ""}
                            {log.groupEvent==2 ?
                                <>
                                    <b>{(log.groupsUsers?.memberName ? log.groupsUsers?.memberName : "") + " (" +
                                        log.groupsUsers?.invitationEmail + ") "} </b>
                                    {t(MapLogEvent(log.groupEvent))}
                                </>: ""}
                            {log.groupEvent==3 ?
                                <>
                                    <b>{(log.groupsUsers?.memberName ? log.groupsUsers?.memberName : "") + " (" +
                                        log.groupsUsers?.invitationEmail + ") "} </b>
                                    {t(MapLogEvent(log.groupEvent))}
                                </>: ""}
                            {log.groupEvent==4 ?
                                <>
                                    <b>{(log.groupsUsers?.memberName ? log.groupsUsers?.memberName : "") + " (" +
                                        log.groupsUsers?.invitationEmail + ") "} </b>
                                    {t(MapLogEvent(log.groupEvent))}
                                </>: ""}
                            {log.groupEvent==5 ?
                                <>
                                    {t("UserSuspended1")} <b>{(log.groupsUsers?.memberName ? log.groupsUsers?.memberName : "") + " (" +
                                    log.groupsUsers?.invitationEmail + ") "} </b>
                                    {t("UserSuspended2")}
                                </>: ""}
                            {log.groupEvent==6 ?
                                <>
                                    <b>{(log.groupsUsers?.memberName ? log.groupsUsers?.memberName : "") + " (" +
                                        log.groupsUsers?.invitationEmail + ") "} </b>
                                    {t(MapLogEvent(log.groupEvent))}
                                </>: ""}


                            {log.groupEvent==7 ?
                                <>
                                    {t(MapLogEvent(log.groupEvent))}
                                </>: ""}
                                
                                    
                                
                           
                                
                                
                            </span>
                    })))
                   
                })
                .catch(function (error) {
                    if(error.response.status===401){
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                })
        }
    })


    return (<Spin spinning={isLoading}>
        <h1>{t("Participant_info")} </h1>

        <Row>
            <Col span={12}>
                
                <Card>
                    <Descriptions title="User Info"
                                  column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}

                    >
                        <Descriptions.Item label={t("Ime")}>{userDetails?.userData?.memberName}</Descriptions.Item>
                        <Descriptions.Item label={t("E_pošta")}>{userDetails?.userData?.invitationEmail}</Descriptions.Item>
                        <Descriptions.Item label={t("Status")}>
                            {userDetails?.userData?.memeberStatus===2 ?
                                <span style={{fontSize: '14px', color: "#4caf50"}}><CheckCircleOutlined /> {t("Joined_the_group")}</span>: ""}
                            {userDetails?.userData?.memeberStatus===1 ?
                                <span style={{fontSize: '14px', color: "#faad14"}}><HourglassOutlined /> {t("Waiting_for_participant_to_join")}</span>: ""  }

                            {userDetails?.userData?.memeberStatus===3 ?
                                <span style={{fontSize: '14px', color: "#777"}}> {userDetails?.userData?.invitationEmail+" "+t("UserRejected")}</span>: ""}

                            {userDetails?.userData?.memeberStatus===4 ?
                                <span style={{fontSize: '14px', color: "#faad14"}}> <PauseCircleOutlined /> {t("UserSuspended1")+" "+t("UserSuspended2")}</span>: ""}

                            {userDetails?.userData?.memeberStatus===5 ?
                                <span style={{fontSize: '14px', color: "#E3601D"}}> <DeleteOutlined /> {t("UserDeleted")}</span>: ""}






                        </Descriptions.Item>
                        <Descriptions.Item label={t("Invitation_sent")}>{ServerdateToPoints(userDetails?.userData?.invitedAt)}</Descriptions.Item>

                    </Descriptions>
                </Card>

                
            </Col>
            <Col span={12}>
                <Timeline
                    style={{marginLeft: "20px", marginTop: "20px"}}
                    mode={"left"}
                    items={titems}/>
            </Col>
        </Row>

        
        
        
     
    </Spin>)

}