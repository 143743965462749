import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import {Tabs, TabsProps} from "antd";
import {useTranslation} from "react-i18next";
import Quotas from "./Quotas";
import PaymentHistory from "./PaymentHistory";


const QuotasListPage = () => {

    const {t} = useTranslation();

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user) {
        history.push("/login");
    }
    
    if(user.groupId===null || user.groupId==="" || user.groupId===undefined){
        history.push("/groups/0");
    }



    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    //console.log("urlParams: ", urlParams);

    const paramValue = urlParams.get('tab');
    const resultValue = urlParams.get('result');

    //console.log("paramValue: ", paramValue);

    const onChange = (key: string) => {
        //console.log(key);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t('Quota_setting'),
            children: <Quotas/>
        },
      
        {
            key: '2',
            label: t('Payment_history'),
            children: <PaymentHistory/>
        },
          /*
         
        {
            key: '3',
            label: t('Obračunavanje'),
            children: <BillingPage result={resultValue}/>
        },


        */


    ]






    return (
        <div>
            <h1>{t("Quotas")}</h1>
            <Tabs defaultActiveKey={paramValue===null ? "1" : paramValue } items={items} onChange={onChange} />
            <br/>




        </div>
    );
};
export default QuotasListPage;