import React, {useContext, useEffect, useState} from 'react';
import axios from "axios/index";
import {DATA_URL, getCurrentUser, refreshUser} from "../../Services/auth-service";
import authHeader from "../../Services/auth-header";
import * as AuthService from "../../Services/auth-service";
import AppHeader from "../../Components/AppHeader";
import {Anchor, Button, Col, Divider, Row} from "antd";
import {useTranslation} from "react-i18next";


const About =()=>{
    const {t} = useTranslation();

    const Ping=()=>{
        axios.get(DATA_URL+"GroupsUsers/ping", {headers:authHeader()})
            .then((response=>{
                console.log(response.data)
            }))


    }
    const Refresh=()=>{
        refreshUser();
        var user = getCurrentUser();
        console.log(user)
        
    }

    
    // @ts-ignore
    return(   <>
        <h1>{t("About")}</h1>
    <div id={"helpContainer"} style={{maxHeight: "80vh", height: "80vh", overflowY: "scroll", overflowX: "hidden"}}>
        <Row gutter={20}>
            <Col span={16}>
                
                    
                    <div id="part-1" style={{  }} >
                        <h3>{ t("Group_management")}</h3> 
                        <p>
                            {t("text1")}
                        </p>
                        <h4 id={"anchor11"} style={{color: "#888"}}> {t("Group_creation")}</h4>
                        <p>
                            {t("text2")}.
                        </p>
                        <p>
                            {t("text3")}
                        </p>
                        <p>
                            {t("text4")}
                        </p>
                        <img src={"/img/creategroup.png"} style={{width: "700px"}}/>

                        <Divider/>
                        <h4 id={"anchor12"} style={{color: "#888"}}>{t("Inviting_participants")}</h4>
                        <Row>
                            <Col span={12}>
                                <p> {t("text5")}</p>
                                <p> {t("text6")}</p>

                            </Col>
                            <Col span={12}>
                                <img style={{width: "300px"}} src={"/img/Screen11.png"}/>
                            </Col>
                        </Row>
                        <p></p>
                        <Row>
                            <Col span={12}>
                                <img style={{width: "370px"}} src={"/img/screen12.png"}/>
                            </Col>
                            <Col span={12}>
                                <p> {t("text7")}</p>
                                <p> {t("text8")}</p>
                                <p> {t("text9")}</p>
                            </Col>
                        </Row>
          
                        <p>
                            {t("text10")}
                        </p>
                        <p>
                            {t("text11")}
                        </p>
                        
                        
                        
                        <p></p>
                        <Divider/>
                        <h4 id={"anchor13"} style={{color: "#888"}}>{t("Member_statuses")}</h4>
                        <p>
                            {t("text12")}
                            <ol>
                                <li>{t("text13")}</li>
                                <li>{t("text14")}</li>
                                <li>{t("text15")}</li>
                            </ol>
                            
                        </p>
                        <img src={"/img/screen21.png"} style={{width: "700px"}}/>
                        
                        <p>
                            {t("text16")}
                        </p>
                        <p>
                            {t("text17")}
                        </p>
                        <Divider/>
                        <h4 id={"anchor14"} style={{color: "#888"}}>{t("Change_log")}</h4>
                        <Row>
                            <Col span={12}>
                                <p>{t("text18")}</p>
                                <p>{t("text19")}</p>
                                <ul>
                                    <li>{t("text20")}</li>
                                    <li>{t("text21")}</li>
                                    <li>{t("text22")}</li>
                                    <li>{t("text23")}</li>
                                    <li>{t("text24")}</li>
                                    <li>{t("text25")}</li>




                                </ul>
                            </Col>
                            <Col span={12}>
                                <img style={{width: "370px"}} src={"/img/grouplog.png"}/>
                            </Col>
                        </Row>
                        
                    </div>
                    <div id="part-2" style={{ }}>
                        <h3>{t("Participants2")}</h3>
                        <p>
                            {t("text26")}
                        </p>
                        <p>
                            {t("text27")}
                        </p>
                        <Row gutter={10}>
                            <Col span={12}>
                                <p>
                                    {t("text28")} 
                                </p>
                                <p>
                                    {t("text29")}
                                    
                                </p>
                            </Col>
                            <Col span={12}>  
                                <img style={{width: "370px"}} src={"/img/userslist.png"}/>
                            </Col>
                        </Row>
                        <h4 id={"anchor21"} style={{color: "#888"}}>{t("Search_function")}</h4>
                        <p>
                            {t("text30")}
                        </p>
                        <h4 id={"anchor22"} style={{color: "#888"}}>{t("Activity_History")}</h4>
                        <p>
                            {t("text31")}
                        </p>

                        
                        <img src={"/img/userdetails.png"} style={{width: "700px"}}/>
                        
                        <Divider/>

                    </div>
                    <div id="anchor30" style={{ }} >
                    <h3>{t("Usage_and_payments")}</h3>
                        <p>
                            {t("text32")}
                        </p>
                        <p>
                            {t("text33")}
                        </p>
                        <p>
                            {t("text34")}
                        </p>
          
                      
                    <h4 id={"anchor31"} style={{color: "#888"}}>{t("Customising_usage")}</h4>
                        <Row>
                            <Col span={12}>
                                <p>
                                    {t("text35")}
                                </p>
                                <p>
                                    {t("text36")}
                                </p>
                                <p>
                                    {t("text37")}
                                </p>
                                <p>
                                    {t("text38")}
                                </p>

                            </Col>
                            <Col span={12}>
                                <img style={{width: "370px"}} src={"/img/grouplimit.png"}/>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <p>
                                    {t("text39")}
                                </p>
                                <p>
                                    {t("text40")}
                                </p>
                                <p>
                                    {t("text41")}
                                </p>
                                <p>
                                    {t("text42")}
                                </p>

                            </Col>
                            <Col span={12}>
                                <img style={{width: "370px"}} src={"/img/doclimit.png"}/>

                            </Col>

                        </Row>

                    <h4 id={"anchor32"} style={{color: "#888"}}>{t("Cost_calculation")}</h4>
                        <p>
                            {t("text43")}
                        </p>
                        <p>
                            {t("text44")}
                        </p>
                        <p>
                            {t("text45")}
                        </p>
                    <h4 id={"anchor33"} style={{color: "#888"}}>{t("Payment_history2")}</h4>
                        <p>
                            {t("text46")}
                        </p>
                        <p>
                            {t("text47")}
                        </p>
                        <p>
                            {t("text48")}
                        </p>
                        
                        <img src={"/img/payhist.png"} style={{width: "700px"}}/>
                    <Divider/>

                </div>
                    <div id="anchor40" style={{  }}>
                        <h3>{t("Settings")}</h3>
                        <p>
                            {t("text49")}
                        </p>

   
                        
                        
                        <h4 id={"anchor41"} style={{color: "#888"}}>{t("User_settings")}</h4>
                        <p>
                            {t("text50")}
                        </p>
                        <p>
                            {t("text51")}
                        </p>
                        <Row>
                            <Col span={12}>
                                <p>
                                    {t("text52")}
                                </p>
                                <p>
                                    {t("text53")}
                                </p>
                                <p>
                                    {t("text54")}
                                </p>

                            </Col>
                            <Col span={12}>
                                <img style={{width: "370px"}} src={"/img/compsettings.png"}/>

                            </Col>

                        </Row>
                        <h4 id={"anchor42"} style={{color: "#888"}}>{t("Subscription_management")}</h4>
                        <p>
                            {t("text55")}
                        </p>
                        <p>
                            {t("text56")}
                        </p>
                        <Divider/>
                        <p>&#169; 2024 OnMyDisk by Bineon d.o.o.</p>

                    </div>
               
                </Col>
            <Col span={8}>
               
                <Anchor
                    getContainer={() => 
                        document.getElementById('helpContainer')!}
                    

                    items={[
                        {
                            key: '0',
                            href:'',
                            title:  <h4>{t("Table_of_Contents")}</h4>
                        },
                        {
                            key: 'part-1',
                            href: '#part-1',
                            title: t("Group_management"),
                            children: [
                                {
                                    key: '11',
                                    href: '#anchor11',
                                    title: t("Group_creation"),
                                },
                                {
                                    key: '12',
                                    href: '#anchor12',
                                    title: t("Inviting_participants"),
                                },
                                {
                                    key: '13',
                                    href: '#anchor13',
                                    title: t("Member_statuses"),
                                },
                                {
                                    key: '14',
                                    href: '#anchor14',
                                    title:t("Change_log"),
                                },
                            ],
                        },
                        {
                            key: 'part-2',
                            href: '#part-2',
                            title: t("Participants2"),
                            children: [
                                {
                                    key: '21',
                                    href: '#anchor21',
                                    title: t("Search_function"),
                                },
                                {
                                    key: '22',
                                    href: '#anchor22',
                                    title: t("Activity_History"),
                                },
                            ]
                        },
                        {
                            key: 'part-3',
                            href: '#anchor30',
                            title:  t("Usage_and_payments"),
                            children: [
                                {
                                    key: '31',
                                    href: '#anchor31',
                                    title: t("Customising_usage"),
                                },
                                {
                                    key: '32',
                                    href: '#anchor32',
                                    title: t("Cost_calculation"),
                                },
                                {
                                    key: '32',
                                    href: '#anchor33',
                                    title: t("Payment_history2"),
                                },
                            ]
                        },
                        {
                            key: 'part-4',
                            href: '#anchor40',
                            title: t("Settings"),
                            children: [
                                {
                                    key: '41',
                                    href: '#anchor41',
                                    title:  t("User_settings"),
                                },
                                {
                                    key: '42',
                                    href: '#anchor42',
                                    title: t("Subscription_management"),
                                },
                            ]
                        },
                    ]}
                />
            </Col>
        </Row>
        
    </div>
    </>)
    
}

export default About;