import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import {
    Button, Card, Checkbox, Col, DatePicker, Divider, Drawer,
    Form, Image, Input, InputNumber, List, message, Row, Select, Space, Spin, Steps,
    Tabs, Tooltip, ConfigProvider, StepsProps, Popover, SelectProps, Dropdown, MenuProps, Modal,
} from 'antd';
import axios from "axios/index";
import {DATA_URL} from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import OmdGroup from "../../../types/Group";
import GroupsUsers from "../../../types/GroupsUsers";
import {publish} from "../../../Components/Notifications/NotificationContext";





export default function InviteForm(props:any) {
    const { visible, setVisible, onCreate, initialValues } = props;
    const {t} = useTranslation();

    //console.log("initialValues: ", initialValues)


    const [form] = Form.useForm();
    //const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [inviteEmail, setinviteEmail] = useState("")
    const [memName, setmame] = useState("")

    const [shouldSend, setshouldSend] = useState<boolean>(true);


    useEffect(()=>{
        if(initialValues!==null){
            setinviteEmail(initialValues.invitationEmail);
            setmame(initialValues.memberName);
        }
    },[initialValues])
    
    const handleOk = () => {

        form
            .validateFields()
            .then((values) => {
                setConfirmLoading(true);
                
                if(initialValues===null){
                    axios.post(DATA_URL+"GroupsUsers/invite-user?invitationEmail="+inviteEmail+"&memberName="+memName,
                        {},
                        {headers:authHeader()})
                        .then((result)=>{
                            setConfirmLoading(false);
                            publish("groupChanges","");
                            onCreate(inviteEmail);
                        });
                }
                else{
                    console.log("memberName", memName)
                    const editedInvite = {
                        id:initialValues.id,
                        invitationEmail: inviteEmail,
                        memberName: memName,
                        omdGroupId:initialValues.omdGroupId
                    } 
                    axios.put(DATA_URL+"GroupsUsers?id="+initialValues.id,
                        {
                            id:initialValues.id,
                            invitationEmail: inviteEmail,
                            memberName: memName,
                            omdGroupId:initialValues.omdGroupId
                        } ,
                        {headers:authHeader()})
                        .then((result)=>{
                            console.log("result", result)
                            setConfirmLoading(false);
                            publish("groupChanges","");
                            onCreate(inviteEmail);
                        });
                }
                
                
                form.resetFields();
               
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
        
        

    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };
    

    return(
        <>
        <Modal open={visible}
               destroyOnClose={true}

               title={t("Participant_info")}
               centered
               onOk={handleOk}
               okText={t("Shraniti")}
               cancelText={t("Cancel")}
               confirmLoading={confirmLoading}
               onCancel={handleCancel}>
            <Divider/>
            <Form
                preserve={false}
                form={form}
                name="InviteForm"
                //                onFinish={onFinish}
                autoComplete="off"
                layout="horizontal"
                labelCol={{ flex: '150px' }}
                labelAlign="right"
                labelWrap
                //onChange={(e)=>OnChange(e)}
                wrapperCol={{ flex: 1 }}
                colon={false}
                initialValues={
                    {
                        ["email"]: initialValues===null? "": initialValues.invitationEmail,
                        ["uName"] : initialValues===null? "": initialValues.memberName===null? "" : initialValues.memberName
                    }
                }

            >
                <Form.Item name={"email"} label={t("E_pošta")} rules={[{required: true},{ type: 'email' },{warningOnly: true}]}>
                    <Input placeholder="" 
                           
                           onChange={(e)=>{setinviteEmail(e.target.value)}}
                           //value={inviteEmail}  
                           variant={"outlined"} />
                </Form.Item>
                <Form.Item name={"uName"} label={t("Whatisname")} >
                    <Input placeholder=""
                           onChange={(e)=>{ setmame(e.target.value)}}
                           //value={memName}  
                           variant={"outlined"} />
                </Form.Item>
                {/* 
                <Form.Item label=" " name="sendInvite">
                    <Checkbox 
                        checked={initialValues===null? true : false}
                        onChange={(e) => {
                            setshouldSend(e.target.checked)
                        }}
                        disabled={initialValues===null? true : false}
                    > Отправить сообщение</Checkbox>
                </Form.Item>
                */}
                
                
  

            </Form>
            <Divider/>
        </Modal>

        </>
    )
}