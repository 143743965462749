import React, {useEffect, useState} from 'react';
import {DownOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Form,
    Input,
    Card,
    Row,
    Col,
    SelectProps,
    MenuProps,
    Dropdown,
    Space,
    Divider,
    Spin,
    message,
    ConfigProvider
} from 'antd';
import {login} from "../Services/auth-service";
import {useHistory} from "react-router-dom";
import i18n from "i18next";
import * as AuthService from "../Services/auth-service";
import {useTranslation} from "react-i18next";
import axios from "axios/index";
import authHeader from "../Services/auth-header";


const LoginPage = () => {

    const {t} = useTranslation();
    const [userslang, setuserslang] = useState("English");
    const [langIcon, setlangIcon] = useState("en");
    const locationString = window.location;
    const[imgwidth, setimgwidth] = useState(200)
   // const[img, setImg] = useState("https://logiex.eu/wp-content/uploads/2024/01/logiexeu_transparent-1.png") //

    useEffect(()=>{
        if(locationString.toString().indexOf("localhost")>0){
            
            setimgwidth(100)
            //setImg("/img/login_logo.png")
        }
        if(locationString.toString().indexOf("inbesi")>0){
            
            setimgwidth(100)
            //setImg("/img/login_logo.png")
        }

    })


    /*
    const options: SelectProps['options'] = [
        { value: 'en', emoji: '🇬🇧', label: 'English' },
        { value: 'si', emoji: '🇸🇮', label: 'Slovenščina' },
        { value: 'fr', emoji: '🇫🇷', label: 'Français' },
        { value: 'de', emoji: '🇩🇪', label: 'Deutsch' },
        { value: 'ru', emoji: '🇷🇺', label: 'Русский' },
        { value: 'it', emoji: '🇮🇹', label: 'Italiano' },
        { value: 'ukr', emoji: '🇺🇦', label: 'Українська' },
        ]
        
     */

    const items: MenuProps['items'] = [
        { key: 'en', icon: <img src="/img/lang/en.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; English</span> },
        { key: 'si', icon: <img src="/img/lang/si.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Slovenščina</span> },
        { key: 'fr', icon: <img src="/img/lang/fr.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Français</span> },
        { key: 'de', icon: <img src="/img/lang/de.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Deutsch</span>},
        { key: 'ru', icon: <img src="/img/lang/ru.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Русский</span> },
        { key: 'it', icon: <img src="/img/lang/it.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Italiano</span> },
        { key: 'uk', icon: <img src="/img/lang/uk.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Українська</span> },
    ]
    
    const handleLanguageClick: MenuProps['onClick'] = (e) => {
        i18n.changeLanguage(e.key);
        switch(e.key) {
            case "en": {
                setuserslang("English");
                setlangIcon("en")
                break;
            }
            case "si": {
                setuserslang("Slovenščina");
                setlangIcon("si")
                break;
            }
            case "fr": {
                setuserslang("Français");
                setlangIcon("fr")
                break;
            }
            case "de": {
                setuserslang("Deutsch");
                setlangIcon("de")
                break;
            }
            case "ru": {
                setuserslang("Русский");
                setlangIcon("ru")
                break;
            }
            case "it": {
                setuserslang("Italiano");
                setlangIcon("it")
                break;
            }
            case "uk": {
                setuserslang("Українська");
                setlangIcon("uk")
                break;
            }


            default: {
                setuserslang("English");
                setlangIcon("en")
                break;
            }
        }

    };

    const menuProps = {
        items,
        onClick: handleLanguageClick,
    };


    const[username, setusername] = useState<string>("");
    const[password, setpassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false)
    

    const history= useHistory();

    const onFinish=()=>{
        setLoading(true)
        console.log("On finish login")
        
        /*
        axios.post("https://onmydisk.net/signin", {action: "signin", username: username, password: password})
            .then((response: any)=>{
                console.log(response)
            })
            
         */
        
        
        
        login(username, password).then(
            () => {
                console.log("localStorage: ", localStorage);
                history.replace("/");
                window.location.reload();
            },
            (error) => {
                setLoading(false)
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                message.error("Ошибка!");
                console.log(resMessage);
                history.push("/");
                //setLoading(false);
                //setMessage(resMessage);
            })
    }
    
    return (
        <Spin spinning={loading}>
<div style={{backgroundColor: "white",
    height: '100vh',
    backgroundImage: "url('/img/loginbackground.jpeg')",
    backgroundSize: "cover",
    backgroundPosition: "center center"}}>
    

        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#0e2640',
                    colorLink: '#0d2640',
                    colorWarning: "#fc7207",
                },
            }}>
  
        <Row style={{height: "80vh", justifyContent: "center"}}>
            <Col style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                width: "400px"
                
            }}>
                <div>

                    <img style={{display: "block", margin:"0 auto", width: "200px", cursor: "pointer"}}
                         alt="example"
                         src={"/img/loginlogo.png"}
                         onClick={()=> {
                             history.push("/");
                             window.location.reload();
                         }}
                    />

                    <Card style={{width: "330px", display: "block",
                        marginTop: "20px",
                        backgroundColor: "#fafafa40"}}
                          //title={t("Log_in")}
                        /*

                          extra={<Dropdown menu={menuProps}>
                              <Button>
                                  <Space>
                                      <img src={"/img/lang/"+langIcon+".svg"}  style={{width:"12px", height: "12px"}}/>
                                      {userslang}
                                      <DownOutlined />
                                  </Space>
                              </Button>
                          </Dropdown>
                          }
                          
                         */
                    >



                        <Form
                            name="normal_login"
                            className="login-form"


                            initialValues={{ remember: true,
                                username:username,
                                password : password

                            }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="username"
                                rules={[{ required: true, message: t('Please_input_your_Username') }]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t("Login")}
                                       onChange={(e)=>{setusername(e.target.value)}}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: t('Please_input_your_Password') }]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder={t("Password")}
                                    onChange={(e)=>{setpassword(e.target.value)}}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>{t('Remember_me')}</Checkbox>
                                </Form.Item>
                                {/* 

                                <a className="login-form-forgot" href="" style={{float: "right"}}>
                                    {t("Forgot_password")}
                                </a>
                                */}
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" style={{width: "100%"}}>
                                    {t("Log_in")}
                                </Button>
                                
                            </Form.Item>
                            {/* 
                            <Form.Item>
                                {t("Or")} <a href="/register">{t("register_now")}</a>
                            </Form.Item>
                            */}
                        </Form>


                        {/* 
                        <Divider/>
                        <Row>
                            <Col span={12} style={{textAlign: "center"}}>
                                <a href="tel:(123)%20456%207890"  style={{color: "#999", fontSize: "10px"}}>+491623067782</a>
                            </Col>
                            <Col span={12}  style={{textAlign: "center"}} >
                                <a href="mailto:info@it-assistent.eu"  style={{color: "#999", fontSize: "10px"}}>info@it-assistent.eu</a>
                            </Col>

                        </Row>
                        */}

                    </Card>
                </div>
                
            </Col>
            
        </Row>
       
        </ConfigProvider>

</div>
        </Spin>
        
        
        
    )
}

export default LoginPage;