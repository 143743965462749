import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../Services/auth-service";
import axios from "axios/index";
import authHeader from "../Services/auth-header";
import {Spin} from "antd/lib";
//import contractor from "../types/contractor";
import {useTranslation} from "react-i18next";

interface RacunProps{
    ContractorId?: string;
    RequestId?: string;
    documentDate? : string;
    payementDate? : string;
    documentNumber? : string;
    forceReload? : boolean;
    isLoaded? :  (data: any) => void;
    positions? : any[]


}



const RacunPage = ({ ContractorId, RequestId, documentNumber, documentDate, payementDate, forceReload, isLoaded, positions} : RacunProps) => {
    
    const history= useHistory();

    const user = AuthService.getCurrentUser();
    if(!user) {
        history.push("/login");
    }

    const {t} = useTranslation();


    const[logoPic, setlogoPic] = useState<string>();
    const[zigPic, setzigPic] = useState<string>();
    const[podpisPic, setpodpisPic] = useState<string>();
    const[myCompany, setmyCompany]= useState<any>({
        id:""
    });
    const[contractorTitle, setcontractorTitle]= useState<any>();

    const[contractor, setcontractor]= useState<any>({
        id: ""
    });
    const[request, setrequest]= useState<any>();


    const [docPositions, setDocPositions] = useState<any[]>([]);
    const[_documentNumber, set_documentNumber ]=useState("");


   // console.log("response.data12121212121212: ")
    useEffect(()=>{
        if(myCompany.id==="")
            axios.get(AuthService.DATA_URL+'Contractor/get-ownCompany', {headers: authHeader()})
                .then((response) => {
   //                 console.log("response.data12121212121212: ", response.data)
                    setmyCompany(response.data);
                    //подставим логотип
                    if(response.data.logoId){
                        fetch(AuthService.DATA_URL+`Docs/get-doc?id=`+ response.data.logoId, {headers: authHeader()})
                            .then((response) => response.blob())
                            .then((blob) => {
                                const imageUrl = URL.createObjectURL(blob);
                                setlogoPic(imageUrl);
                            });
                    }
                    //подставим подпись
                    if(response.data.podpisId){
                        fetch(AuthService.DATA_URL+`Docs/get-doc?id=`+ response.data.podpisId, {headers: authHeader()})
                            .then((response) => response.blob())
                            .then((blob) => {
                                const imageUrl = URL.createObjectURL(blob);
                                setpodpisPic(imageUrl);
                            });
                    }
                    //подставим печать
                    if(response.data.zigId){
                        fetch(AuthService.DATA_URL+`Docs/get-doc?id=`+ response.data.zigId, {headers: authHeader()})
                            .then((response) => response.blob())
                            .then((blob) => {
                                const imageUrl = URL.createObjectURL(blob);
                                setzigPic(imageUrl);
                            });
                    }
                })
                
                .catch(()=>{
                    setmyCompany({id:"sdfsdf"});
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();
                })
        
        if(ContractorId){
            axios.get(AuthService.DATA_URL+'Contractor/'+ContractorId, {headers: authHeader()})
                .then((response) => {
                    console.log("getting racun contractor:",response);
                    setcontractorTitle(response.data.Title);
                    setcontractor(response.data)
                    
                })
                .catch((e)=>{
                    //setcontractorTitle("-");
                }) 
        }
        if(RequestId){
            axios.get(AuthService.DATA_URL+'Request/'+RequestId, {headers: authHeader()})
                .then((response) => {
               //     console.log("Request: ", response);
                    //setcontractorTitle(response.data.Title);
                    setrequest(response.data)


                })
                .catch((e)=>{
                    //setcontractorTitle("-");
                })
        }
        if(positions){
            setDocPositions(positions);
            
           // console.log("Racun positions: ", docPositions);
        }


        if(documentNumber!==undefined && documentNumber!==null){
            set_documentNumber(documentNumber);
        }
    }, [forceReload, ContractorId, RequestId, documentNumber, positions])


    const ctitle = contractor ? contractor.title : undefined;
//    if(ContractorId)
    const _contractor = ContractorId ? contractor ? contractor : myCompany : myCompany
        
        
   //     contractor ? contractor : ContractorId;







//Отключение warning в консоли
    console.warn  = () => {};

    //console.log("docPositions: ", docPositions)


    if(myCompany.id===""){
        return <Spin/>
    }else{
        if(isLoaded){
            isLoaded(true);
        }
    }


    return (


            
            <div className="Racun_main" id={"racunMain"} style={{width: "978px", margin: "0 auto", fontSize: "17px",
            backgroundColor: "#fff", maxWidth: "900px"}}>
                <table width="100%" style={{fontFamily: "Arial"}}>
                    <tbody>
                    <tr>
                        <td rowSpan={2} style={{ verticalAlign: "top", padding: "30px 0",}}>
                            <div style={{
                                //background: "url('http://localhost:3000/img/logo_temp.png') no-repeat",
                                backgroundImage: "url('" + logoPic + "')",
                                backgroundRepeat:"no-repeat",
                                backgroundSize : "cover",
                                width: "100px", height: "100px"}}>
                            </div>
                        </td>
                        <td style={{width: "100mm", padding: "20px 0", borderTop: "2px solid #000"}}>
                            <div style={{textAlign: "justify", fontSize: "10pt", }}>
                                <div style={{marginBottom: "5px", fontWeight: "bold"}}>{myCompany.id==="" ? "" : myCompany.title}</div>
                                <div style={{marginBottom: "5px"}}>{myCompany.id==="" ? "" : myCompany.adress1}</div>
                                <div style={{marginBottom: "5px"}}>{myCompany.id==="" ? "" : myCompany.adress2}</div>
                                
                                <div style={{marginBottom: "5px"}}>{myCompany.id==="" ? "" : myCompany.davcnaStevilka}</div>
                                <div style={{marginBottom: "20px"}}>{myCompany.id==="" ? "" : myCompany.iban ? <span>IBAN: {myCompany.iban}</span> : ""}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "100mm", padding: "20px 0", borderTop: "2px solid #000"}}>
                            <div style={{textAlign: "justify", fontSize: "10pt", }}>
                                <div style={{marginBottom: "5px", fontWeight: "bold"}}>{contractor?.title}</div>
                                <div style={{marginBottom: "5px"}}>{contractor?.adress1}</div>
                                <div style={{marginBottom: "5px"}}>{contractor.adress2}</div>
                               
                                <div style={{marginBottom: "5px"}}>{contractor.davcnaStevilka}</div>
                                <div style={{marginBottom: "5px"}}>{contractor.iban ? <span>IBAN: {contractor.iban}</span> : ""}</div>
                            </div>
                        </td>
                        
                    </tr>
                    </tbody>
                </table>
                
                <table width="100%" style={{fontFamily: "Arial", borderTop: "2px solid #000", fontSize: "11pt",
                    borderBottom: "2px solid #000", marginBottom: "80px"}}>
                    <tbody>
                    <tr>
                        <td style={{ padding:"30px 5px", marginTop: "30px", fontFamily: "Arial", }}>
                            <div style={{fontWeight: "bold", fontSize: "25pt", display: "block"}}>
                                {/*RAČUN*/} {t('Račun')}
                            </div>
                            <div style={{fontWeight: "bold", fontSize: "16pt", display: "block"}}>
                                {_documentNumber}
                            </div>                        
                        </td>
                        <td style={{width: "100mm", padding: "20px 0", fontSize: "10pt" }}>
                            <div style={{marginBottom: "5px"}}>{t("Datum_dokumenta")}: <b>{documentDate}</b></div>
                            {/*  <div style={{marginBottom: "5px"}}>Datum storitve</div>*/}
                            <div style={{marginBottom: "5px"}}>{t("Datum_plačila")}:  <b>{payementDate}</b></div>
    
                        </td>
                    </tr>
                    </tbody>
                </table>
    
    
                <table border={0} width="100%" cellPadding="0" cellSpacing="0"
                       style={{borderCollapse: "collapse", width: "100%", fontFamily: "Arial"}}>
                    <thead style={{borderBottom: "1px solid #ddd"}}>
                    <tr>
                        <th style={{width:"13mm", textAlign: "left", paddingBottom:"15px"}}>#</th>
    
                        <th style={{ textAlign: "left", paddingBottom:"15px"}}>{t("Naziv_storitva_blaga")}</th>
                        <th style={{width:"20mm", textAlign: "left", paddingBottom:"15px"}}>{t("Količina")}</th>
                        <th style={{width:"20mm", textAlign: "left", paddingBottom:"15px"}}>{t("Davek")}</th>
                        <th style={{width:"30mm", textAlign: "left", paddingBottom:"15px"}}>{t("Cena")} (€)</th>
                        <th style={{width:"30mm", textAlign: "right", paddingBottom:"15px"}}>{t("Skupaj")} (€)</th>
                    </tr>
                    </thead>
                    <tbody style={{borderBottom: "1px solid #ddd"}}>
                    {
                        docPositions.length > 0 ?
                                
                                    docPositions.map((row: any, i)=>(

                                        <tr>
                                            <td style={{width:"13mm", paddingTop:"15px 0"}}>{i+1}</td>
                                            <td style={{ padding:"15px 0"}}>
                                                {row.naziv}<br/>
                                            </td>
                                            <td style={{width:"20mm", padding:"15px 0"}}>{row.kolicina}</td>
                                            <td style={{width:"20mm", padding:"15px 0"}}>{row.davek===0? "22%" : "9.5%"}</td>
                                            <td style={{width:"30mm", textAlign: "left", padding:"15px 0"}}>{!row.cena ? 0: row.cena.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}</td>
                                            <td style={{width:"30mm", textAlign: "right", padding:"15px 0"}}>{!row.cena ? 0: row.cena.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}</td>
                                            {/*
                                                <td style={{width:"30mm", textAlign: "left", padding:"15px 0"}}>{request ? request.price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") : ""}</td>
                                                <td style={{width:"30mm", textAlign: "right", padding:"15px 0"}}>{request ? request.price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") : ""}</td>
                                            */}
                                            </tr>

                           
                                    ))
                            
                             : (<tr><td></td></tr>)
                    }
                    

                    
                    
                    
                    
                    </tbody>
                </table>
    
                <table style={{fontFamily: "Arial"}} border={0} width="100%" cellPadding="0" cellSpacing="0">
                   <tbody>
                    <tr>
                        <td style={{padding:"10px 0 0 0", color: "#aaa", verticalAlign: "top", fontSize: "10pt"}} rowSpan={4}>
                            {t("Znesek_prosimo_plačajte_na")} {myCompany.iban} <br/>
                            BIC: {myCompany.swift}<br/><br/>
                            {t("Ob_plačilu_navedite_referenco")} 2019-00001. <br/><br/>
                            {t("Hvala_za_zaupanje")}
                            
                        </td>
                    </tr>
                    <tr>
                        <td style={{width:"70mm", fontWeight:"normal",  textAlign:"left", padding:"10px 0 0 0"}}>{t("Skupaj")} (€):</td>
                        <td style={{width:"30mm", fontWeight:"normal",  textAlign: "right", padding:"10px 0 0 0"}}>{docPositions.reduce((sum, current) => sum + current.cena, 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}</td>
                    </tr>
                    <tr>
                        <td style={{width:"70mm", fontWeight:"normal",  textAlign:"left", padding:"10px 0"}}>{t("Davek_za_DDV")} 22% / {docPositions.reduce((sum, current) => sum + current.cena, 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") } (€):</td>
                        <td style={{width:"30mm", fontWeight:"normal",  textAlign: "right", padding:"10px 0"}}>{ ((docPositions.reduce((sum, current) => sum + current.cena, 0)/122)*22).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") }</td>
                    </tr>
                    <tr>
                        <td style={{width:"70mm", fontWeight:"bold",  textAlign:"left", padding:"15px 0", borderTop: "2px solid #000"}}>{t("Skupaj")} (€):</td>
                        <td style={{width:"30mm", fontWeight:"bold",  textAlign:"right", borderTop: "2px solid #000"}}>{docPositions.reduce((sum, current) => sum + current.cena, 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") }</td>
                    </tr>
                    <tr>
    
                        <td colSpan={5} style={{ verticalAlign: "bottom", fontWeight:"bold",  textAlign:"right", padding:"15px 0", borderTop: "2px solid #000"}}>
                            <div style={{textAlign: "right", marginTop: "20mm", marginBottom: "10px", verticalAlign: "bottom"}}>
                                {myCompany?.title}
                            </div>
    
                            <div style={{
                                //background: "url('http://localhost:3000/img/zig.png')",
                                backgroundPosition: "right",
                                backgroundImage: "url('" + zigPic + "')",
                                backgroundRepeat: "no-repeat", padding: "20px 10px", width: "200px", 
                                verticalAlign: "bottom",
                                textAlign: "center",
                                float: "right",
                                height: "160px"}}>
                                <div style={{
                                    //background: "url('http://localhost:3000/img/signature.png') no-repeat",
                                    backgroundImage: "url('" + podpisPic + "')",
                                    backgroundRepeat:"no-repeat",
                                    backgroundSize : "contain", marginLeft: "-70px",
                                    width: "160px", height: "180px"}}>
    
                                </div>
                            </div>
    
                        </td>
                    </tr>
                   </tbody>
                </table>
    
    
    
    
    
    
    
    
    
    
                <br/>
                <br/><br/><br/><br/><br/>
                <div style={{backgroundColor:"#000000", width:"100%", fontSize:"1px", height:"1px"}}>&nbsp,</div>
                <br/>
                <div style={{textAlign: "center", fontSize: "10pt", }}>
                    {myCompany.title} {myCompany.adress1} {myCompany.adress2} {t("ID_za_DDV")}: {myCompany.davcnaStevilka} IBAN: {myCompany.iban}
                </div>
    
    
                <br/>
            


    
        </div>
)
}

export default RacunPage;