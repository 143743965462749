import React from 'react';
import { BellFilled } from '@ant-design/icons';
import { Badge, Popover, Button } from 'antd';

interface Props {
    notificationsCount: number;
    content?: React.ReactNode;
}

const NotificationsButton: React.FC<Props> = (props) => {
    const { notificationsCount, content } = props;

    return (
        <div >
        <Badge color={"#e3601e"}
               dot={false}//{notificationsCount > 0}
            //count={12}//{notificationsCount}
            size={"small"}>
            <Popover
                content={content}
                placement={"bottomLeft"}
                trigger={"click"}
                arrowPointAtCenter={false}>
                <BellFilled style={{color: "transparent", fontSize: "18px", maxHeight: "15px"}}/>
            </Popover>
        </Badge>
        </div>
    );
}

export default NotificationsButton;
